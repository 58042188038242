import { Combobox, Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import debounce from "lodash.debounce";
import { IoSearchOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { GoPackage } from "react-icons/go";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { IOrder } from "../../interfaces";
import { handleError } from "../../utils/_helper";
import Spinner from "./Spinner";
import {
  resetSearchOrders,
  selectOrders,
} from "../../redux/slices/ordersSlice";
import { searchOrdersAction } from "../../redux/actions/orders.actions";

interface Props {
  isOpen: boolean;
  closeModal: (close: boolean) => void;
  filter: {
    title: string;
    value: string;
  };
}

function OrdersSearchCombo({ isOpen, closeModal, filter }: Props) {
  const {
    orders,
    searchLoaded: ordersLoaded,
    searchLoading: ordersLoading,
  } = useAppSelector(selectOrders);
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");
  const dispatch = useAppDispatch();

  const data = useMemo(() => {
    if (query) {
      return orders?.filter((order) =>
        order[filter.value as keyof IOrder]!.toString()
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    }

    return [];
  }, [filter.value, orders, query]);

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(resetSearchOrders());

      setQuery(event.target.value);
    },
    [dispatch]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [changeHandler]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          searchOrdersAction({
            key: filter.value,
            value: query,
          })
        ).unwrap();
      } catch (error) {
        toast.error(handleError(error));
      }
    };

    if (!ordersLoaded && query && !data.length) {
      fetchData();
    }
  }, [data.length, dispatch, filter.value, ordersLoaded, query]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-xl" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl divide-y divide-foreground/30 transform overflow-hidden rounded-md bg-backgroundTint p-6 text-left align-middle shadow-xl transition-all">
                <Combobox value={selected} onChange={setSelected}>
                  <Dialog.Title as="div" className="flex items-center">
                    <IoSearchOutline size={20} />
                    <Combobox.Input
                      className="w-full border-none py-2 px-3 flex-1 text-sm leading-5 bg-backgroundTint focus:ring-0"
                      onChange={debouncedChangeHandler}
                      disabled={ordersLoading}
                      placeholder={`Search Orders by ${filter.title}`}
                    />
                  </Dialog.Title>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery("")}
                  >
                    <Combobox.Options className="divide-y divide-foreground/10 p-2 text-sm">
                      {data.map((value) => (
                        <Combobox.Option key={value.id} value={value}>
                          {() => (
                            <Link
                              to={`/orders/${value.id}`}
                              className="p-2 flex items-center opacity-80 hover:opacity-100"
                            >
                              <div className="p-2 h-full w-fit flex items-center justify-center rounded-md bg-tertiary/20">
                                <GoPackage
                                  size={30}
                                  className="text-tertiary"
                                />
                              </div>
                              <div className="text-xs ml-3">
                                <p>{value.orderId}</p>
                                <span className="opacity-80 font-extralight">
                                  {dayjs(value.createdAt.toDate()).format(
                                    "ddd, MMM DD YYYY"
                                  )}
                                </span>
                              </div>
                            </Link>
                          )}
                        </Combobox.Option>
                      ))}
                      {ordersLoading ? (
                        <div>
                          <Spinner />
                        </div>
                      ) : null}
                      {ordersLoaded && !data.length && query ? (
                        <p className="text-xs text-error">
                          Cannot find orders with {filter.title} of {query}
                        </p>
                      ) : null}
                    </Combobox.Options>
                  </Transition>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default OrdersSearchCombo;
