import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWithdrawalRequest } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  riders: IWithdrawalRequest[];
  companies: IWithdrawalRequest[];
} = {
  riders: [],
  companies: [],
};

export const withdrawalRequestSlice = createSlice({
  name: "withdrawalRequests",
  initialState,
  reducers: {
    updateWithdrawalRequests: (
      state,
      action: PayloadAction<{
        type: "riders" | "companies";
        data: IWithdrawalRequest[];
      }>
    ) => {
      state[action.payload.type] = action.payload.data;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.companies = [];
      state.riders = [];
    }),
});

export const { updateWithdrawalRequests } = withdrawalRequestSlice.actions;
export const selectWithdrawalRequests = (state: RootState) =>
  state.withdrawalRequests;

export default withdrawalRequestSlice.reducer;
