import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { selectAuth } from "../redux/slices/authSlice";
import authService from "../services/auth.service";
import { handleError } from "../utils/_helper";
import { useAppSelector } from "./useRedux";

const useLogin = () => {
  const { user, loaded } = useAppSelector(selectAuth);
  const [isPasswordVisible, setIspasswordVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location as any;

  const from = state?.from?.pathname || "/";

  useEffect(() => {
    if (loaded && user) {
      navigate(from, { replace: true });
    }
  }, [from, loaded, navigate, user]);

  const formikLogin = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .lowercase()
        .email("Invalid Email Address")
        .required("Email is Required")
        .test("Email", "Invalid Email Address", (val) => {
          if (val && val.endsWith("@cuzooapp.com")) {
            return true;
          }

          return false;
        }),
      password: yup
        .string()
        .trim()
        .min(8, "Minimum of 8 characters")
        .required("Password is Required"),
    }),

    onSubmit: async ({ email, password }) => {
      try {
        await authService.login(email.trim().toLowerCase(), password);
      } catch (err: any) {
        toast.error(handleError(err));
      }
    },
  });

  return {
    isPasswordVisible,
    setIspasswordVisible,
    formikLogin,
  };
};

export default useLogin;
