import { useMemo, useState } from "react";
import { MdPeople } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { Timestamp } from "firebase/firestore";
import { Country, State } from "country-state-city";
import dayjs from "dayjs";
import Action from "../components/shared/Action";
import Button from "../components/shared/Button";
import Header from "../components/shared/Header";
import Spinner from "../components/shared/Spinner";
import useRiders from "../hooks/useRiders";
import SearchCombo from "../components/shared/SearchCombo";
import SearchFilter from "../components/shared/SearchFilter";
import useSearchFilter from "../hooks/useSearchFilter";
import { AdminPositions, CollectionNames } from "../enums";
import { useAppSelector } from "../hooks/useRedux";
import { selectAuth } from "../redux/slices/authSlice";
import useCount from "../hooks/useCount";
import RidersList from "../components/shared/RidersList";
import { selectMetadata } from "../redux/slices/metadataSlice";

function Riders() {
  const { riders } = useAppSelector(selectMetadata);
  const { user } = useAppSelector(selectAuth);
  const { ridersActions, getActions } = useRiders();
  const [showSearchCombo, setShowSearchCombo] = useState(false);
  const { selectedFilter, setSelectedFilter, filter } = useSearchFilter();

  const [value, setValue] = useState<DateValueType>({
    startDate: dayjs().subtract(7, "day").startOf("day").toDate(),
    endDate: dayjs().toDate(),
  });
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const options = useMemo(() => {
    const opts: Parameters<typeof useCount>["0"]["options"] = [];
    if (user?.position === AdminPositions.INVESTOR) {
      opts.push(
        {
          key: "approved",
          cmp: "==",
          value: true,
        },
        {
          key: "country",
          cmp: "==",
          value: user.country,
        },
        {
          key: "state",
          cmp: "==",
          value: user.state,
        }
      );
    } else {
      if (country) {
        opts.push({
          key: "country",
          cmp: "==",
          value: country,
        });
      }

      if (state) {
        opts.push({
          key: "state",
          cmp: "==",
          value: state,
        });
      }

      opts.push({
        key: "approved",
        cmp: "==",
        value: true,
      });
    }

    if (value?.startDate && value.endDate) {
      opts.push(
        {
          key: "createdAt",
          cmp: ">=",
          value: Timestamp.fromDate(value.startDate as Date),
        },
        {
          key: "createdAt",
          cmp: "<=",
          value: Timestamp.fromDate(value.endDate as Date),
        }
      );
    }

    return opts;
  }, [
    country,
    state,
    user?.country,
    user?.position,
    user?.state,
    value?.endDate,
    value?.startDate,
  ]);

  const { data: count } = useCount({
    collectionName: CollectionNames.RIDERS,
    options,
    enabled: !!user,
  });

  return (
    <>
      <div className="h-full bg-background">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <Header>
              <p className="text-xl">Riders</p>
            </Header>
          </div>

          <div className="w-fit flex items-center gap-4 px-2">
            <div className="flex-1">
              <Datepicker
                primaryColor="blue"
                showShortcuts
                // inputClassName="text-sm"
                value={value}
                onChange={(newValue) =>
                  setValue({
                    startDate: dayjs(newValue?.startDate)
                      .startOf("day")
                      .toDate(),
                    endDate: dayjs(newValue?.endDate).endOf("day").toDate(),
                  })
                }
              />
            </div>

            {user?.position !== AdminPositions.INVESTOR ? (
              <>
                <div className="flex-1">
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full"
                  >
                    <option value="">Country</option>
                    {Country.getAllCountries().map((ctry) => (
                      <option key={ctry.isoCode} value={ctry.isoCode}>
                        {ctry.flag} {ctry.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex-1">
                  <select
                    value={state || ""}
                    onChange={(e) => setState(e.target.value)}
                    className="bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full"
                  >
                    <option value="">State</option>
                    {(country ? State.getStatesOfCountry(country) : []).map(
                      (s) => (
                        <option
                          key={`${s.isoCode} ${s.name}`}
                          value={s.isoCode}
                        >
                          {s.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="md:grid md:grid-cols-3  h-full">
          <section className="md:col-span-2 overflow-y-scroll md:h-full">
            {riders ? (
              <div className="p-3">
                <div className="flex items-center justify-between md:hidden mb-3">
                  <button
                    type="button"
                    onClick={() => setShowSearchCombo(true)}
                    className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
                  >
                    <IoSearchOutline size={20} />
                    <p className="text-xs font-extralight ml-2">
                      :{filter.find((v) => v.value === selectedFilter)?.title}
                    </p>
                  </button>
                  <SearchFilter
                    filter={filter}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                </div>
                <div className="flex">
                  <div className="p-2 w-fit h-fit bg-secondary/10 rounded-md">
                    <MdPeople size={20} className="text-secondary" />
                  </div>
                  <div className="ml-3">
                    <span className="text-xs font-extralight opacity-40">
                      Total Riders
                    </span>
                    <p className="text-2xl font-bold">{count || 0}</p>
                  </div>
                </div>
              </div>
            ) : null}
            <RidersList range={value} country={country} state={state} />
          </section>
          <section className="p-3 overflow-y-scroll ml-2 hidden md:block">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => setShowSearchCombo(true)}
                className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
              >
                <IoSearchOutline size={20} />
                <p className="text-xs font-extralight ml-2">
                  :{filter.find((v) => v.value === selectedFilter)?.title}
                </p>
              </button>
              <SearchFilter
                filter={filter}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </div>
            {user?.position !== AdminPositions.INVESTOR ? (
              <div>
                <h2 className="p-2">Riders Actions</h2>
                {ridersActions.actions.map((action) => (
                  <Action action={action} key={action.id} />
                ))}
                {ridersActions.loading ? <Spinner /> : null}

                {ridersActions.loaded &&
                ridersActions.more &&
                !ridersActions.loading ? (
                  <Button
                    onClick={() => getActions(ridersActions.lastVisible)}
                    text="Load More"
                    className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                  />
                ) : null}
              </div>
            ) : null}
          </section>
        </div>
      </div>
      <SearchCombo
        isOpen={showSearchCombo}
        closeModal={setShowSearchCombo}
        type="Rider"
        filter={filter.find((v) => v.value === selectedFilter)!}
      />
    </>
  );
}

export default Riders;
