import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Spinner from "./shared/Spinner";

interface Props {
  isOpen: boolean;
  text: string;
  // eslint-disable-next-line react/require-default-props
  closeModal?: any;
}

function ActionLoading({ isOpen, closeModal, text }: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (closeModal) {
            closeModal(false);
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-xl" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-md bg-backgroundTint p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="p"
                  className="flex items-center justify-between"
                >
                  {text}
                </Dialog.Title>

                <Spinner />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ActionLoading;
