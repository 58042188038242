import { createSlice } from "@reduxjs/toolkit";
import { IAdmin } from "../../interfaces";
import {
  getAdminsAction,
  releaseAdminAction,
  searchAdminsAction,
  suspendAdminAction,
} from "../actions/admins.actions";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  admins: IAdmin[];
  loaded: boolean;
  lastVisible: any;
  more: boolean;
  loading: boolean;
  searchLoading: boolean;
  searchLoaded: boolean;
} = {
  admins: [],
  loaded: false,
  lastVisible: null,
  more: true,
  loading: false,
  searchLoading: false,
  searchLoaded: false,
};

export const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    reset: (state) => {
      state.admins = [];
      state.loaded = false;
      state.lastVisible = null;
      state.more = true;
      state.loading = false;
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    resetSearchAdmins: (state) => {
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    updateAdmins: (state, action) => {
      state.admins = [...state.admins, action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction.fulfilled, (state) => {
        state.admins = [];
        state.loaded = false;
        state.lastVisible = null;
        state.searchLoading = false;
        state.searchLoaded = false;
      })
      .addCase(getAdminsAction.pending, (state) => {
        state.loading = false;
      })
      .addCase(getAdminsAction.fulfilled, (state, action) => {
        state.loaded = true;
        state.lastVisible = action.payload.lv;
        state.more = action.payload.more;
        state.admins = [
          ...state.admins.filter(
            (admin) => !action.payload.data.find((d) => d.id === admin.id)
          ),
          ...action.payload.data,
        ];
      })
      .addCase(getAdminsAction.rejected, (state) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(searchAdminsAction.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchAdminsAction.rejected, (state) => {
        state.searchLoading = false;
        state.searchLoaded = true;
      })
      .addCase(searchAdminsAction.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchLoaded = true;
        state.admins = [
          ...state.admins.filter(
            (admin) => !action.payload.data.find((d) => d.id === admin.id)
          ),
          ...action.payload.data,
        ];
      })
      .addCase(suspendAdminAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(suspendAdminAction.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = state.admins.map((admin) => {
          if (admin.id === action.payload.id) {
            return {
              ...admin,
              ...action.payload.update,
            };
          }

          return admin;
        });
      })
      .addCase(suspendAdminAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(releaseAdminAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(releaseAdminAction.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = state.admins.map((admin) => {
          if (admin.id === action.payload.id) {
            return {
              ...admin,
              ...action.payload.update,
            };
          }

          return admin;
        });
      })
      .addCase(releaseAdminAction.rejected, (state) => {
        state.loading = false;
      }),
});

export const { reset, resetSearchAdmins, updateAdmins } = adminsSlice.actions;
export const selectAdmins = (state: RootState) => state.admins;

export default adminsSlice.reducer;
