import { createSlice } from "@reduxjs/toolkit";
import { IMetadata } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: IMetadata & { loaded: boolean } = {
  admins: null,
  users: null,
  riders: null,
  orders: null,
  companies: null,
  loaded: false,
};

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    updateMetadata: (state, action) => {
      state.loaded = true;
      state.admins = action.payload?.admins
        ? {
            ...action.payload.admins,
          }
        : null;

      state.users = action.payload?.users
        ? {
            ...action.payload.users,
          }
        : null;

      state.riders = action.payload?.riders
        ? {
            ...action.payload.riders,
          }
        : null;

      state.companies = action.payload?.companies
        ? {
            ...action.payload.companies,
          }
        : null;

      state.orders = action.payload?.orders
        ? {
            ...action.payload.orders,
          }
        : null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.admins = null;
      state.users = null;
      state.riders = null;
      state.orders = null;
      state.loaded = false;
      state.companies = null;
    });
  },
});

export const { updateMetadata } = metadataSlice.actions;
export const selectMetadata = (state: RootState) => state.metadata;

export default metadataSlice.reducer;
