import { IconType } from "react-icons";
import Error from "./Error";
import SummaryCard from "./SummaryCard";

interface Props {
  count: number;
  loading: boolean;
  iconBgColor: string;
  iconColor: string;
  label: string;
  Icon: IconType;
  emptyMessage: string;
}

function CountCard({
  count,
  emptyMessage,
  loading,
  iconBgColor,
  iconColor,
  label,
  Icon,
}: Props) {
  return (
    <div>
      {!loading && !count ? (
        <Error message={emptyMessage} />
      ) : (
        <SummaryCard
          label={label}
          Icon={Icon}
          loading={loading}
          count={count}
          iconBgColor={iconBgColor}
          iconColor={iconColor}
        />
      )}
    </div>
  );
}

export default CountCard;
