/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { getAdminsAction } from "../../redux/actions/admins.actions";
import { selectAdmins } from "../../redux/slices/adminsSlice";
import { handleError } from "../../utils/_helper";
import AdminItem from "./AdminItem";
import Button from "./Button";
import Spinner from "./Spinner";

function AdminsList() {
  const { lastVisible, loading, admins, loaded, more } =
    useAppSelector(selectAdmins);
  const dispatch = useAppDispatch();

  const fetchAdmins = useCallback(
    async (lv: any) => {
      try {
        await dispatch(getAdminsAction({ lastVisible: lv })).unwrap();
      } catch (error) {
        toast.error(handleError(error));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!loaded) {
      fetchAdmins(lastVisible);
    }
  }, [admins.length, fetchAdmins, lastVisible, loaded]);

  return (
    <div className="divide divide-y divide-foreground/10">
      {admins.map((admin) => (
        <AdminItem key={admin.id} admin={admin} />
      ))}
      {loading ? <Spinner /> : null}

      {loaded && more && !loading ? (
        <Button
          onClick={() => fetchAdmins(lastVisible)}
          text="Load More"
          className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
        />
      ) : null}
    </div>
  );
}

export default AdminsList;
