/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { getCompanyAction } from "../../redux/actions/courierCompany.actions";
import { selectCourierCompanies } from "../../redux/slices/courierCompanySlice";
import { handleError } from "../../utils/_helper";
import Button from "./Button";
import CourierCompanyItem from "./CourierCompanyItem";
import Spinner from "./Spinner";

function CourierCompanyList() {
  const { loaded, loading, more, courierCompanies, lastVisible } =
    useAppSelector(selectCourierCompanies);
  const dispatch = useAppDispatch();

  const fetchCompanies = useCallback(
    async (lv: any) => {
      try {
        await dispatch(getCompanyAction({ lastVisible: lv }));
      } catch (error) {
        toast.error(handleError(error));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!loaded) {
      fetchCompanies(lastVisible);
    }
  }, [fetchCompanies, lastVisible, loaded]);

  return (
    <div className="divide divide-y divide-foreground/10">
      {courierCompanies.map((company) => (
        <CourierCompanyItem key={company.id} company={company} />
      ))}
      {loading ? <Spinner /> : null}

      {loaded && more && !loading ? (
        <Button
          onClick={() => fetchCompanies(lastVisible)}
          text="Load More"
          className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
        />
      ) : null}
    </div>
  );
}

export default CourierCompanyList;
