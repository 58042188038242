import React, { useMemo } from "react";
import NumberFormat from "react-number-format";
import { IoWalletOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { GiRoad } from "react-icons/gi";
import { IIndividualUserAnalytics, IWallet } from "../../interfaces";
import { moneyToNaira } from "../../utils/_helper";

interface Props {
  wallet: IWallet | null;
  userAnalytics: IIndividualUserAnalytics | null;
}

function DetailInfoCards({ wallet, userAnalytics }: Props) {
  const pendingOrders = useMemo(() => {
    if (userAnalytics) {
      return (
        userAnalytics.total -
        (userAnalytics.cancelled + userAnalytics.completed)
      );
    }

    return 0;
  }, [userAnalytics]);

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
      {wallet ? (
        <div className="flex items-center">
          <div className="p-2 h-full flex items-center justify-center rounded-md bg-sky-500/20">
            <IoWalletOutline size={30} className="text-sky-500" />
          </div>
          <div className="flex-1 ml-3 h-full">
            <p className="text-xs font-extralight opacity-60">Wallet Balance</p>
            <h2
              className={`text-lg font-bold ${
                wallet?.suspended ? "text-error" : "text-foreground"
              }`}
            >
              <NumberFormat
                value={moneyToNaira(wallet?.amount || 0)}
                displayType="text"
                decimalScale={2}
                thousandSeparator
                prefix="₦"
              />
            </h2>
          </div>
        </div>
      ) : null}

      {/* total Orders */}
      <div className="flex items-center">
        <div className="p-2 h-full flex items-center justify-center rounded-md bg-tertiary/20">
          <GoPackage size={30} className="text-tertiary" />
        </div>
        <div className="flex-1 ml-3 h-full">
          <p className="text-xs font-extralight opacity-60">Total Orders</p>
          <h2 className="text-lg font-bold">{userAnalytics?.total || 0}</h2>
        </div>
      </div>

      {/* completed Orders */}
      <div className="flex items-center">
        <div className="p-2 h-full flex items-center justify-center rounded-md bg-emerald-500/20">
          <IoCheckmarkDoneOutline size={30} className="text-emerald-500" />
        </div>
        <div className="flex-1 ml-3 h-full">
          <p className="text-xs font-extralight opacity-60">Completed Orders</p>
          <h2 className="text-lg font-bold">{userAnalytics?.completed || 0}</h2>
        </div>
      </div>

      {/* failed Orders */}
      <div className="flex items-center">
        <div className="p-2 h-full flex items-center justify-center rounded-md bg-rose-500/20">
          <MdOutlineCancelPresentation size={30} className="text-rose-500" />
        </div>
        <div className="flex-1 ml-3 h-full">
          <p className="text-xs font-extralight opacity-60">Cancelled Orders</p>
          <h2 className="text-lg font-bold">{userAnalytics?.cancelled || 0}</h2>
        </div>
      </div>

      {/* pending Orders */}
      <div className="flex items-center">
        <div className="p-2 h-full flex items-center justify-center rounded-md bg-amber-500/20">
          <GiRoad size={30} className="text-amber-500" />
        </div>
        <div className="flex-1 ml-3 h-full">
          <p className="text-xs font-extralight opacity-60">Pending Orders</p>
          <h2 className="text-lg font-bold">{pendingOrders}</h2>
        </div>
      </div>
    </div>
  );
}

export default DetailInfoCards;
