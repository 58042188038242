import React, { PureComponent } from "react";

type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

class TextArea extends PureComponent<Props> {
  render() {
    const { className, ...rest } = this.props;
    return (
      <textarea
        {...rest}
        style={{
          boxShadow: "none",
        }}
        className={`border-2 border-gray-300 rounded-md form-input focus:border-primary text-sm p-2 w-full ${className}`}
      />
    );
  }
}

export default TextArea;
