import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface MobileNavState {
  isOpen: boolean;
}

// Define the initial state using that type
const initialState: MobileNavState = {
  isOpen: false,
};

export const mobileSlice = createSlice({
  name: "mobile",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggle } = mobileSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMobileNavState = (state: RootState) => state.mobile.isOpen;

export default mobileSlice.reducer;
