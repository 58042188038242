import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  IActions,
  IIndividualUserAnalytics,
  ITransaction,
  IWallet,
} from "../interfaces";
import userService from "../services/user.service";
import { handleError } from "../utils/_helper";

const useUserDetails = (userId: string) => {
  const [wallet, setWallet] = useState<IWallet | null>(null);
  const [userAnalytics, setUserAnalytics] =
    useState<IIndividualUserAnalytics | null>(null);
  const [userTransactions, setUserTransactions] = useState<{
    transactions: ITransaction[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    transactions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const [userActions, setUserActions] = useState<{
    actions: IActions[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    actions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  useEffect(() => {
    const sub = userService.watchWallet(userId, setWallet);
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [userId]);

  useEffect(() => {
    const sub = userService.fetchAnalytics(userId, setUserAnalytics);
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [userId]);

  const getTransactions = useCallback(
    async (lv: any) => {
      setUserTransactions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await userService.fetchTransactions(userId, lv);
        setUserTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          transactions: [
            ...prevState.transactions.filter(
              (transaction) => !res.data.find((d) => d.id === transaction.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        setUserTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [userId]
  );

  useEffect(() => {
    if (!userTransactions.loaded && !userTransactions.transactions.length) {
      getTransactions(userTransactions.lastVisible);
    }
  }, [
    getTransactions,
    userId,
    userTransactions.transactions.length,
    userTransactions.lastVisible,
    userTransactions.loaded,
  ]);

  const getActions = useCallback(
    async (lv: any) => {
      setUserActions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await userService.fetchActions(userId, lv);
        setUserActions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          actions: [
            ...prevState.actions.filter(
              (action) => !res.data.find((d) => d.id === action.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        console.log(error);
        setUserActions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [userId]
  );

  useEffect(() => {
    if (!userActions.loaded && !userActions.actions.length) {
      getActions(userActions.lastVisible);
    }
  }, [
    getActions,
    userActions.actions.length,
    userActions.lastVisible,
    userActions.loaded,
  ]);

  return {
    wallet,
    userAnalytics,
    userTransactions,
    getTransactions,
    userActions,
    getActions,
  };
};

export default useUserDetails;
