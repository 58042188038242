/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FirestoreError } from "firebase/firestore";
import { DateValueType } from "react-tailwindcss-datepicker";
import { IOrder } from "../../interfaces";
import ordersService from "../../services/orders.service";
// import { RootState } from "../store";

export const getOrdersAction = createAsyncThunk<
  {
    data: IOrder[];
    lv: any;
    more: boolean;
    range: { startDate: number; endDate: number } | null;
    country: string;
    state: string;
  },
  { lastVisible: any; range: DateValueType; country: string; st: string }
>(
  "orders/getOrdersAction",
  async (
    { lastVisible, range, country, st },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as any;

      const response = await ordersService.fetchOrders(
        state.auth.user!,
        lastVisible,
        range,
        country,
        st
      );
      return {
        ...response,
        range:
          range?.startDate && range.endDate
            ? {
                startDate: +dayjs(range.startDate),
                endDate: +dayjs(range.endDate),
              }
            : null,
        country,
        state: st,
      };
    } catch (error: any) {
      const err: FirestoreError = error;
      console.log(error);
      return rejectWithValue(err);
    }
  }
);

export const searchOrdersAction = createAsyncThunk<
  { data: IOrder[] },
  { key: string; value: string }
>("orders/searchOrdersAction", async ({ key, value }, { rejectWithValue }) => {
  try {
    const response = await ordersService.searchOrders(key, value);
    return response;
  } catch (error: any) {
    const err: FirestoreError = error;
    return rejectWithValue(err);
  }
});
