import React, { Fragment, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline, IoAttachOutline } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { toast } from "react-toastify";
import GoogleMapReact from "google-map-react";
import { Menu, Transition } from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import Header from "../components/shared/Header";
import DetailInfoCards from "../components/shared/DetailInfoCards";
import Spinner from "../components/shared/Spinner";
import Button from "../components/shared/Button";
import Transaction from "../components/shared/Transaction";
import Action from "../components/shared/Action";
import BarChart from "../components/shared/BarChart";
import { selectRiders, updateRiders } from "../redux/slices/ridersSlice";
import useRiderDetails from "../hooks/useRiderDetails";
import RiderLocationMarker from "../components/shared/RiderLocationMarker";
import ActionLoading from "../components/ActionLoading";
import riderService from "../services/rider.service";
import RiderRating from "../components/shared/RiderRating";

function RiderDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { riders } = useAppSelector(selectRiders);

  const rider = useMemo(
    () => riders.find((v) => v.id === params.id),
    [params.id, riders]
  );

  useEffect(() => {
    const getSingleRider = async () => {
      try {
        const res = await riderService.getSingleRider(params.id!);
        if (!res) {
          return navigate("/riders", { replace: true });
        }
        return dispatch(updateRiders(res));
      } catch (error) {
        return navigate("/riders", { replace: true });
      }
    };

    if (!rider) {
      getSingleRider();
    }
  }, [dispatch, navigate, params.id, rider]);

  const {
    wallet,
    riderLocation,
    ridersAnalytics,
    riderTransactions,
    getTransactions,
    riderActions,
    getActions,
    loading,
    setLoading,
    loadingText,
    takeActionAgainstWallet,
  } = useRiderDetails(params.id!);

  const [geocoder, setGeocoder] = useState<any>(null);

  const [formattedAddress, setFormattedAddress] = useState({
    address: "",
    placeId: "",
  });

  const handleApiLoaded = ({ maps }: { map: any; maps: any }) => {
    const gc = new maps.Geocoder();
    gc.geocode(
      {
        location: {
          lat: riderLocation?.coordinates.latitude,
          lng: riderLocation?.coordinates.longitude,
        },
      },
      (results: any, status: any) => {
        if (status === "OK") {
          setFormattedAddress({
            address: results[0].formatted_address,
            placeId: results[0].place_id,
          });
          setGeocoder(gc);
        } else {
          toast.error(`Unable to get rider's current location: ${status}`);
        }
      }
    );
  };

  useEffect(() => {
    if (geocoder && formattedAddress.address !== "") {
      geocoder.geocode(
        {
          location: {
            lat: riderLocation?.coordinates.latitude,
            lng: riderLocation?.coordinates.longitude,
          },
        },
        (results: any, status: any) => {
          if (status === "OK") {
            setFormattedAddress({
              address: results[0].formatted_address,
              placeId: results[0].place_id,
            });
          } else {
            toast.error(`Unable to get rider's current location: ${status}`);
          }
        }
      );
    }
  }, [
    formattedAddress.address,
    geocoder,
    riderLocation?.coordinates.latitude,
    riderLocation?.coordinates.longitude,
  ]);

  if (!rider) {
    return (
      <div className="h-full grid place-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="h-full">
        <Header>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <button
                type="button"
                className="p-2"
                onClick={() => navigate("/riders", { replace: true })}
              >
                <IoArrowBackOutline size={20} />
              </button>
              <div className="ml-3">
                <p className="text-lg leading-none">
                  {rider?.firstName} {rider?.lastName}
                </p>
                <span className="text-xs opacity-60 font-bold">
                  Rider Profile
                </span>
                {rider?.companyId ? (
                  <span className="text-xs block underline hover:text-tertiary">
                    <Link to={`/courier-company/${rider.companyId}`}>
                      Company - {rider.companyName}
                    </Link>
                  </span>
                ) : null}
                {wallet?.suspended ? (
                  <span className="text-xs text-error block">
                    Ristricted - Wallet is currently suspended
                  </span>
                ) : null}
                {wallet?.lastCashOrder &&
                dayjs().diff(wallet?.lastCashOrder, "hours") > 24 ? (
                  <span className="text-xs text-error block">
                    Ristricted - Pending Arreas
                  </span>
                ) : null}
              </div>
            </div>

            <Menu
              as="div"
              className={`relative inline-block ${
                !wallet ? "hidden" : "block"
              }`}
            >
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-backgroundTint bg-opacity-20 px-4 py-2 text-sm font-medium text-foreground/90 hover:text-foreground">
                  <span className="hidden md:inline-block">Actions</span>
                  <HiOutlineDotsVertical size={20} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-6 mt-2 w-32 origin-top-right divide-y divide-foreground/30 rounded-md bg-backgroundTint shadow-lg focus:outline-none">
                  <div className="py-1 ">
                    {!rider.companyId ? (
                      <Menu.Item
                        as="button"
                        role="button"
                        className={`text-xs p-2 w-full hover:bg-background ${
                          wallet?.suspended ? "text-success" : "text-error"
                        }`}
                        onClick={() =>
                          takeActionAgainstWallet(
                            wallet?.suspended ? "release" : "suspend"
                          )
                        }
                      >
                        {wallet?.suspended
                          ? "Release Wallet"
                          : "Suspend Wallet"}
                      </Menu.Item>
                    ) : null}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </Header>
        {rider ? (
          <div className="p-2 grid grid-cols-1 md:grid-cols-3 gap-2">
            <div className="md:col-span-2 order-last md:order-first">
              <DetailInfoCards
                wallet={!rider.companyId ? wallet : null}
                userAnalytics={ridersAnalytics}
              />
              <div className="p2">
                <BarChart
                  data={{
                    labels: ["Completed", "Pending", "Cancelled"],
                    datasets: [
                      {
                        label: "Orders",
                        data: [
                          ridersAnalytics?.completed || 0,
                          ridersAnalytics
                            ? ridersAnalytics.total -
                              (ridersAnalytics.cancelled +
                                ridersAnalytics.completed)
                            : 0,
                          ridersAnalytics?.cancelled || 0,
                        ],
                        backgroundColor: [
                          "rgb(16 185 129 / 0.2)",
                          "rgb(245 158 11 / 0.2)",
                          "rgb(244 63 94 / 0.2)",
                        ],
                        borderColor: ["#10b981", "#f59e0b", "#f43f5e"],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
              {!rider.companyId ? (
                <>
                  <div className="p-2">
                    <h2 className="py-2 text-lg font-bold">Gaurantor</h2>
                    <div className="p-2 divide-y divide-foreground/30 bg-backgroundTint rounded-md">
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          First name
                        </span>
                        <p className="text-xs">{rider.gaurantor.firstName}</p>
                      </div>
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          Last name
                        </span>
                        <p className="text-xs">{rider.gaurantor.lastName}</p>
                      </div>
                      {rider.gaurantor.email ? (
                        <div className="py-2">
                          <span className="text-xs font-extralight opacity-60">
                            Email
                          </span>
                          <a
                            href={`mailto:${rider.gaurantor.email}`}
                            className="block hover:text-tertiary text-xs my-1"
                            rel="noreferrer"
                          >
                            {rider.gaurantor.email}
                          </a>
                        </div>
                      ) : null}
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          Phone number
                        </span>
                        <a
                          href={`tel:${rider.gaurantor.phoneNumber.number}`}
                          className="block hover:text-tertiary text-xs my-1"
                          rel="noreferrer"
                        >
                          {rider.gaurantor.phoneNumber.nationalFormat}
                        </a>
                      </div>
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          Address
                        </span>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                            rider.gaurantor.address.formatted_address
                          )}`}
                          target="_blank"
                          className="block hover:text-tertiary text-xs my-1"
                          rel="noreferrer"
                        >
                          {rider.gaurantor.address.formatted_address}
                        </a>
                      </div>
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          Gender
                        </span>
                        <p className="text-xs">{rider.gaurantor.gender}</p>
                      </div>
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          Occupation
                        </span>
                        <p className="text-xs">{rider.gaurantor.occupation}</p>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <h2 className="py-2 text-lg font-bold">Documents</h2>
                    <div className="grid gap-2 md:grid-cols-2 overflow-hidden mb-5">
                      <a
                        href={rider.gaurantorForm?.url}
                        target="_blank"
                        className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                        rel="noreferrer"
                      >
                        <IoAttachOutline size={25} />

                        <p className="pl-2 text-sm">Guarantor Form</p>
                      </a>
                      {rider.kycForm ? (
                        <a
                          href={rider.kycForm?.url}
                          target="_blank"
                          className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                          rel="noreferrer"
                        >
                          <IoAttachOutline size={25} />
                          <p className="pl-2 text-sm">KYC Form</p>
                        </a>
                      ) : null}
                      {rider.partnershipForm ? (
                        <a
                          href={rider.partnershipForm?.url}
                          target="_blank"
                          className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                          rel="noreferrer"
                        >
                          <IoAttachOutline size={25} />
                          <p className="pl-2 text-sm">Partnership Form</p>
                        </a>
                      ) : null}
                    </div>

                    <div className="grid gap-2 md:grid-cols-2 overflow-hidden">
                      {rider.nepaBill ? (
                        <a
                          href={rider.nepaBill?.url}
                          target="_blank"
                          className="bg-backgroundTint block rounded-md shadow-sm"
                          rel="noreferrer"
                        >
                          <img
                            src={rider.nepaBill?.url}
                            alt="Nepa Bill"
                            className="w-full h-44 object-cover"
                          />

                          <p className="p-2 text-xs">Nepa Bill</p>
                        </a>
                      ) : null}

                      <a
                        href={rider.nationalId?.url}
                        target="_blank"
                        className="bg-backgroundTint block rounded-md shadow-sm"
                        rel="noreferrer"
                      >
                        <img
                          src={rider.nationalId?.url}
                          alt="Valid ID"
                          className="w-full h-44 object-cover"
                        />

                        <p className="p-2 text-xs">Valid ID</p>
                      </a>
                      <a
                        href={rider.riderCard.url}
                        target="_blank"
                        className="bg-backgroundTint block rounded-md shadow-sm"
                        rel="noreferrer"
                      >
                        <img
                          src={rider.riderCard.url}
                          alt="Rider Card"
                          className="w-full h-44 object-cover"
                        />

                        <p className="p-2 text-xs">Rider Card</p>
                      </a>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="p-2">
                <h2 className="py-2 text-lg font-bold">Transactions</h2>
                {riderTransactions.transactions.length ? (
                  <div className="divide-y divide-foreground/30">
                    {riderTransactions.transactions.map((transaction) => (
                      <Transaction
                        transaction={transaction}
                        key={transaction.id}
                      />
                    ))}
                  </div>
                ) : null}
                {riderTransactions.loaded &&
                !riderTransactions.transactions.length ? (
                  <p className="text-center p-2 text-xs">
                    This rider has no transactions yet.
                  </p>
                ) : null}
                {riderTransactions.loading ? <Spinner /> : null}
                {riderTransactions.loaded &&
                riderTransactions.more &&
                !riderTransactions.loading ? (
                  <Button
                    onClick={() =>
                      getTransactions(riderTransactions.lastVisible)
                    }
                    text="Load More"
                    className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                  />
                ) : null}
              </div>
            </div>

            <div className="p-2 bg-background">
              <div className="text-center p-2">
                {rider.passport ? (
                  <img
                    src={rider.passport.url}
                    alt="Rider Profile"
                    className="w-20 h-20 object-cover rounded-full mx-auto"
                  />
                ) : (
                  <div className="text-sm mx-auto font-semibold w-20 h-20 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
                    <p>
                      {rider.firstName[0].toUpperCase() +
                        rider.lastName[0].toUpperCase()}
                    </p>
                  </div>
                )}
                <h2>
                  {rider.firstName} {rider.lastName}
                </h2>
                <p className="text-xs my-1">
                  {rider.vehicle.vehicles.join(",")}
                </p>
                <a
                  href={`mailto:${rider.email}`}
                  className="block hover:text-tertiary text-xs my-1"
                >
                  {rider.email}
                </a>
                <a
                  href={`tel:${rider.phoneNumber.number}`}
                  className="block hover:text-tertiary text-xs my-1"
                >
                  {rider.phoneNumber.nationalFormat}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                    rider.address.formatted_address
                  )}`}
                  target="_blank"
                  className="block hover:text-tertiary text-xs my-1"
                  rel="noreferrer"
                >
                  {rider.address.formatted_address}
                </a>
                <p className="text-xs opacity-50">
                  Joined -{" "}
                  {dayjs(rider.createdAt.toDate()).format("DD/MM/YYYY")}
                </p>
              </div>
              {riderLocation ? (
                <div>
                  <h2 className="p-2 font-bold">Ratings and Reviews</h2>
                  <RiderRating riderLocation={riderLocation} />
                </div>
              ) : null}
              {riderLocation ? (
                <div>
                  <h2 className="p-2 font-bold">Last Seen</h2>
                  <div className="w-full h-40">
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_MAP_KEY!,
                      }}
                      defaultCenter={{
                        lat: riderLocation.coordinates.latitude,
                        lng: riderLocation.coordinates.longitude,
                      }}
                      defaultZoom={15}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={handleApiLoaded}
                    >
                      <RiderLocationMarker
                        lat={riderLocation.coordinates.latitude}
                        lng={riderLocation.coordinates.longitude}
                        location={riderLocation}
                      />
                    </GoogleMapReact>
                  </div>
                  <div className="p-2 divide-y divide-foreground/30 bg-backgroundTint rounded-md">
                    <div className="py-2">
                      <span className="text-xs font-extralight opacity-60">
                        Last Seen
                      </span>
                      <p className="text-xs">
                        {dayjs(riderLocation.updatedAt.toDate()).format(
                          "DD/MM/YYYY@HH:mm:ssa"
                        )}
                      </p>
                    </div>
                    {formattedAddress?.address ? (
                      <div className="py-2">
                        <span className="text-xs font-extralight opacity-60">
                          Address
                        </span>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                            formattedAddress.address
                          )}`}
                          target="_blank"
                          className="block hover:text-tertiary text-xs my-1"
                          rel="noreferrer"
                        >
                          {formattedAddress.address}
                        </a>
                      </div>
                    ) : null}
                    <div className="py-2">
                      <span className="text-xs font-extralight opacity-60">
                        Transport Means
                      </span>
                      <p className="text-xs">{riderLocation.transportType}</p>
                    </div>
                    <div className="py-2">
                      <span className="text-xs font-extralight opacity-60">
                        Status
                      </span>
                      <p className="text-xs">
                        {riderLocation.online ? "Online" : "Offline"} -
                        {riderLocation.dispatching ? "Dispatching" : "Free"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="hidden md:block">
                <h2 className="p-2 font-bold">Rider Actions</h2>
                {riderActions.actions.map((action) => (
                  <Action action={action} key={action.id} />
                ))}
                {riderActions.loaded && !riderActions.actions.length ? (
                  <p className="text-center p-2 text-xs">
                    This rider has no action yet.
                  </p>
                ) : null}
                {riderActions.loading ? <Spinner /> : null}

                {riderActions.loaded &&
                riderActions.more &&
                !riderActions.loading ? (
                  <Button
                    onClick={() => getActions(riderActions.lastVisible)}
                    text="Load More"
                    className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
            Not Found
          </div>
        )}
      </div>
      <ActionLoading
        isOpen={loading}
        closeModal={setLoading}
        text={loadingText}
      />
    </>
  );
}

export default RiderDetails;
