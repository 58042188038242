import React, { Fragment, useCallback, useMemo, useState } from "react";
import {
  IoCardOutline,
  IoQrCodeOutline,
  IoWalletOutline,
  IoCashOutline,
  IoEyeOutline,
} from "react-icons/io5";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdPhonePortrait } from "react-icons/io";
import { RiBankLine } from "react-icons/ri";
import { ITransaction } from "../../interfaces";
import { moneyToNaira } from "../../utils/_helper";

interface Props {
  transaction: ITransaction;
  // eslint-disable-next-line react/require-default-props
  order?: boolean;
}
function Transaction({ transaction, order }: Props) {
  const Icon = useMemo(() => {
    switch (transaction.channel) {
      case "card":
        return IoCardOutline;
      case "ussd":
        return IoMdPhonePortrait;
      case "bank":
        return RiBankLine;
      case "qr":
        return IoQrCodeOutline;
      case "mobile_money":
        return IoMdPhonePortrait;
      case "wallet":
        return IoWalletOutline;
      default:
        return IoCashOutline;
    }
  }, [transaction.channel]);

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  return (
    <>
      <div className="py-2 grid grid-cols-3 lg:grid-cols-5 items-center">
        <div
          className={`p-2 rounded-md w-fit ${
            transaction.metadata.type === "Credit"
              ? "bg-success/20"
              : "bg-error/20"
          }`}
        >
          <Icon
            size={20}
            className={`${
              transaction.metadata.type === "Credit"
                ? "text-success"
                : "text-error"
            }`}
          />
        </div>
        <p className="text-xs font-bold">
          <NumberFormat
            value={moneyToNaira(transaction.amount + transaction.fees || 0)}
            displayType="text"
            decimalScale={2}
            thousandSeparator
            prefix="₦"
          />
        </p>
        <p className="text-xs hidden lg:block">
          {dayjs(transaction.updatedAt.toDate()).format("DD-MM-YYYY")}
        </p>

        <p className="text-xs hidden lg:block">
          {transaction.metadata.narative}
        </p>

        <button
          type="button"
          onClick={openModal}
          className="w-fit mx-auto py-2 px-4 hover:bg-backgroundTint hover:shadow-md rounded-md"
        >
          <IoEyeOutline size={20} />
        </button>
      </div>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-xl" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-backgroundTint p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <div
                        className={`p-2 rounded-md w-fit ${
                          transaction.metadata.type === "Credit"
                            ? "bg-success/20"
                            : "bg-error/20"
                        }`}
                      >
                        <Icon
                          size={20}
                          className={`${
                            transaction.metadata.type === "Credit"
                              ? "text-success"
                              : "text-error"
                          }`}
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-lg font-medium leading-none text-foreground">
                          <NumberFormat
                            value={moneyToNaira(
                              transaction.amount + transaction.fees || 0
                            )}
                            displayType="text"
                            decimalScale={2}
                            thousandSeparator
                            prefix="₦"
                          />
                        </h3>
                        <span className="text-xs">
                          {transaction.metadata.type}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="text-sm">
                        {dayjs(transaction.updatedAt.toDate()).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                      <p className="text-xs opacity-70 my-1">
                        {dayjs(transaction.updatedAt.toDate()).format(
                          "HH:mm:ssa"
                        )}
                      </p>
                    </div>
                  </Dialog.Title>
                  <div className="mt-2 divide-y divide-foreground/20">
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Narrative
                      </span>
                      <p className="text-sm">{transaction.metadata.narative}</p>
                    </div>
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Net Amount
                      </span>
                      <p className="text-sm">
                        {" "}
                        <NumberFormat
                          value={moneyToNaira(transaction?.amount || 0)}
                          displayType="text"
                          decimalScale={2}
                          thousandSeparator
                          prefix="₦"
                        />
                      </p>
                    </div>
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Charge
                      </span>
                      <p className="text-sm">
                        <NumberFormat
                          value={moneyToNaira(
                            transaction?.fees || transaction.metadata.charge
                          )}
                          displayType="text"
                          decimalScale={2}
                          thousandSeparator
                          prefix="₦"
                        />
                      </p>
                    </div>
                    {order ? (
                      <>
                        <div className="py-1">
                          <span className="text-xs font-extralight opacity-60">
                            Rider
                          </span>
                          <p className="text-xs text-tertiary underline">
                            <Link to={`/riders/${transaction.riderId}`}>
                              View
                            </Link>
                          </p>
                        </div>
                        <div className="py-1">
                          <span className="text-xs font-extralight opacity-60">
                            Order
                          </span>
                          <p className="text-xs text-tertiary underline">
                            <Link
                              to={{
                                pathname: `/orders/${transaction.reference}`,
                              }}
                            >
                              View
                            </Link>
                          </p>
                        </div>
                      </>
                    ) : null}
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Currency
                      </span>
                      <p className="text-sm">{transaction.currency}</p>
                    </div>
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Type
                      </span>
                      <p className="text-sm">
                        {transaction.metadata.chargeType}
                      </p>
                    </div>
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Reference
                      </span>
                      <p className="text-sm">{transaction.reference}</p>
                    </div>
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Date
                      </span>
                      <p className="text-sm">
                        {dayjs(transaction.updatedAt.toDate()).format(
                          "DD/MM/YYYY@HH:mm:ssa"
                        )}
                      </p>
                    </div>
                    <div className="py-1">
                      <span className="text-xs font-extralight opacity-60">
                        Status
                      </span>
                      <p className="text-sm">{transaction.status}</p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Transaction;
