import { FirebaseApp, getApp, getApps, initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {
  getFirestore,
  serverTimestamp,
  getDoc,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  onSnapshot,
  collection,
  query,
  writeBatch,
  deleteDoc,
  limit,
  orderBy,
  startAfter,
  getDocs,
  where,
  documentId,
  or,
  getCountFromServer,
  getAggregateFromServer,
  sum,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
let app: FirebaseApp;

// console.log(firebaseConfig);

if (getApps().length) {
  app = getApp();
} else {
  app = initializeApp(firebaseConfig);
}

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const timestamp = serverTimestamp;
setPersistence(auth, browserSessionPersistence);

export {
  auth,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  setPersistence,
  db,
  doc,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  timestamp,
  onSnapshot,
  collection,
  query,
  writeBatch,
  deleteDoc,
  limit,
  orderBy,
  startAfter,
  getDocs,
  where,
  documentId,
  or,
  getCountFromServer,
  getAggregateFromServer,
  sum,
  storage,
  ref,
  deleteObject,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
};
