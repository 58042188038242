import React, { PureComponent } from "react";
import { ImSpinner9 } from "react-icons/im";

export class Spinner extends PureComponent {
  render() {
    return (
      <ImSpinner9 className="w-5 h-5 animate-spin text-foreground mx-auto my-2" />
    );
  }
}

export default Spinner;
