import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  IActions,
  IIndividualUserAnalytics,
  IRiderLocation,
  ITransaction,
  IWallet,
} from "../interfaces";
import adminService from "../services/admin.service";
import riderService from "../services/rider.service";
import { handleError } from "../utils/_helper";

const useRiderDetails = (riderId: string) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [wallet, setWallet] = useState<IWallet | null>(null);
  const [riderLocation, setRiderLocation] = useState<IRiderLocation | null>(
    null
  );
  const [ridersAnalytics, setRiderAnalytics] =
    useState<IIndividualUserAnalytics | null>(null);
  const [riderTransactions, setRiderTransactions] = useState<{
    transactions: ITransaction[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    transactions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const [riderActions, setRiderActions] = useState<{
    actions: IActions[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    actions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  useEffect(() => {
    const sub = riderService.watchWallet(riderId, setWallet);
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [riderId]);

  useEffect(() => {
    const sub = riderService.watchRiderLocation(riderId, setRiderLocation);
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [riderId]);

  useEffect(() => {
    const sub = riderService.fetchAnalytics(riderId, setRiderAnalytics);
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [riderId]);

  const getTransactions = useCallback(
    async (lv: any) => {
      setRiderTransactions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await riderService.fetchTransactions(riderId, lv);
        setRiderTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          transactions: [
            ...prevState.transactions.filter(
              (transaction) => !res.data.find((d) => d.id === transaction.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        console.log(error);
        setRiderTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [riderId]
  );

  useEffect(() => {
    if (!riderTransactions.loaded && !riderTransactions.transactions.length) {
      getTransactions(riderTransactions.lastVisible);
    }
  }, [
    getTransactions,
    riderId,
    riderTransactions.transactions.length,
    riderTransactions.lastVisible,
    riderTransactions.loaded,
  ]);

  const getActions = useCallback(
    async (lv: any) => {
      setRiderActions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await riderService.fetchActions(riderId, lv);
        setRiderActions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          actions: [
            ...prevState.actions.filter(
              (action) => !res.data.find((d) => d.id === action.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        console.log(error);
        setRiderActions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [riderId]
  );

  useEffect(() => {
    if (!riderActions.loaded && !riderActions.actions.length) {
      getActions(riderActions.lastVisible);
    }
  }, [
    getActions,
    riderActions.actions.length,
    riderActions.lastVisible,
    riderActions.loaded,
  ]);

  const takeActionAgainstWallet = useCallback(
    async (action: "suspend" | "release") => {
      setLoading(true);
      setLoadingText(
        `Please wait - ${
          action === "release" ? "Releasing" : "Suspending"
        } rider wallet.`
      );
      try {
        const res = await adminService.actionAgainstRiderWallet(
          riderId,
          action
        );
        setLoading(false);
        setLoadingText("");
        toast.success(res.message);
      } catch (error) {
        setLoading(false);
        setLoadingText("");
        toast.error(handleError(error));
      }
    },
    [riderId]
  );

  return {
    wallet,
    riderLocation,
    ridersAnalytics,
    riderTransactions,
    getTransactions,
    riderActions,
    getActions,
    loading,
    setLoading,
    loadingText,
    takeActionAgainstWallet,
  };
};

export default useRiderDetails;
