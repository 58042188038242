/* eslint-disable no-underscore-dangle */
import React from "react";
import { Link } from "react-router-dom";
import { IRider } from "../../interfaces";

interface Props {
  rider: IRider;
}

function RiderItem({ rider }: Props) {
  return (
    <div className="bg-background p-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 items-center justify-between">
      <div className="flex items-center">
        {rider.passport ? (
          <img
            src={rider.passport.url}
            alt="User Profile"
            className="w-10 h-10 object-cover rounded-full"
          />
        ) : (
          <div className="text-xs font-semibold w-10 h-10 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
            <p>
              {rider.firstName[0].toUpperCase() +
                rider.lastName[0].toUpperCase()}
            </p>
          </div>
        )}

        <p className="text-xs ml-3">
          {rider.firstName} {rider.lastName}
        </p>
      </div>
      <a
        href={`mailto:${rider.email}`}
        className="text-xs hover:text-tertiary text-left hidden xl:block w-fit mx-auto"
      >
        {rider.email}
      </a>
      <a
        href={`tel:${rider.phoneNumber.number}`}
        className="text-xs hover:text-tertiary text-left hidden md:block w-fit mx-auto"
      >
        {rider.phoneNumber.nationalFormat}
      </a>
      <p className="text-xs hidden lg:block w-fit mx-auto">{rider.gender}</p>
      <Link
        to={`/riders/${rider.id}`}
        className="bg-primary text-xs w-fit py-2 px-4 rounded-sm hover:shadow-md mx-auto"
      >
        View
      </Link>
    </div>
  );
}

export default RiderItem;
