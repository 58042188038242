import { IconType } from "react-icons";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import Text from "./Text";

interface MobileNavLinkProps extends LinkProps {
  text: string;
  Icon: IconType;
  // eslint-disable-next-line react/require-default-props
  badge?: number | null;
}

function MobileNavLink({
  Icon,
  text,
  to,
  badge,
  ...props
}: MobileNavLinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div
      className={`my-2 px-5 py-2 rounded-sm ${
        match ? "bg-white" : "hover:bg-gray-200"
      }`}
      title={text}
    >
      <Link
        className={`flex items-center justify-between ${
          match ? "text-primary" : ""
        }`}
        to={to}
        {...props}
      >
        <Icon size={20} />

        <div className="flex items-center flex-1">
          <Text content={text} className="text-xs ml-2 transition-all flex-1" />
          {badge ? (
            <div className="w-6 h-6 rounded-full bg-error flex items-center justify-center ml-2">
              <p className="text-foreground text-xs font-bold">
                {badge > 99 ? 99 : badge}
              </p>
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  );
}

export default MobileNavLink;
