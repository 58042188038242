import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { IconType } from "react-icons";
import Text from "./Text";

interface Props {
  count: number;
  Icon: IconType;
  label: string;
  iconColor: string;
  iconBgColor: string;
  loading: boolean;
}

function SummaryCard({
  count,
  Icon,
  label,
  iconBgColor,
  iconColor,
  loading,
}: Props) {
  return (
    <div className="rounded-md shadow-md p-3 bg-backgroundTint">
      <div className="flex items-center justify-between mb-3">
        {loading ? (
          <Skeleton width={50} />
        ) : (
          <Text content={`${count}`} className="text-2xl font-bold" />
        )}

        <div className={`p-1 rounded-full ${iconBgColor}`}>
          {loading ? (
            <Skeleton count={1} width={20} height={20} />
          ) : (
            <Icon size={20} className={`${iconColor}`} />
          )}
        </div>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <Text content={label} className="font-medium text-xs" />
      )}
    </div>
  );
}

export default SummaryCard;
