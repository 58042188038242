import React, { useCallback } from "react";
import { MdMenu } from "react-icons/md";
import { useAppDispatch } from "../../hooks/useRedux";
import { toggle } from "../../redux/slices/mobileSlice";

function Header({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();

  const toggleMobileNav = useCallback(() => {
    dispatch(toggle());
  }, [dispatch]);

  return (
    <div className="bg-backgroundTint py-5 px-2 shadow-sm flex items-center font-medium">
      <MdMenu
        size={20}
        className="block sm:block md:hidden mr-2"
        onClick={() => toggleMobileNav()}
      />
      {children}
    </div>
  );
}

export default Header;
