import { configureStore } from "@reduxjs/toolkit";
import mobileReducer from "./slices/mobileSlice";
import authReducer from "./slices/authSlice";
import metadataReducer from "./slices/metadataSlice";
import adminsReducer from "./slices/adminsSlice";
import riderRegistrationReducer from "./slices/riderRegistrationSlice";
import companiesRegistrationReducer from "./slices/companiesRegistrationSlice";
import settingsReducer from "./slices/settingsSlice";
import usersReducer from "./slices/usersSlice";
import ridersReducer from "./slices/ridersSlice";
import ordersReducer from "./slices/ordersSlice";
import courierCompanyReducer from "./slices/courierCompanySlice";
import withdrawalRequestsReducer from "./slices/withdrawalRequestSlice";

// review
import activitiesReducer from "./slices/activitiesSlice";

export const store = configureStore({
  reducer: {
    mobile: mobileReducer,
    auth: authReducer,
    metadata: metadataReducer,
    admins: adminsReducer,
    riderRegistration: riderRegistrationReducer,
    companiesRegistration: companiesRegistrationReducer,
    settings: settingsReducer,
    users: usersReducer,
    riders: ridersReducer,
    orders: ordersReducer,
    courierCompany: courierCompanyReducer,
    withdrawalRequests: withdrawalRequestsReducer,

    // Review
    activities: activitiesReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
