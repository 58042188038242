/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AdminService from "../../services/Admin";
import { ActivitiesType } from "../../types";

export const getActivitiesAction = createAsyncThunk<
  ActivitiesType,
  { page: number },
  { rejectValue: { message: string } }
>("activity/getActivitiesAction", async ({ page }, { rejectWithValue }) => {
  try {
    const response = await AdminService.fetchActivities(page);
    return response;
  } catch (error: any) {
    const err: AxiosError<{ message: string }> = error;
    return rejectWithValue(
      err.response?.data || {
        message: "Opps something went wrong, please try again",
      }
    );
  }
});
