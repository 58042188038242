import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "./Button";
import Input from "./Input";
import Text from "./Text";
import { handleError } from "../../utils/_helper";
import Modal from "./Modal";
import TextArea from "./TextArea";
import httpClient from "../../config/HTTPClient";
import authService from "../../services/auth.service";

interface Props {
  url: string;
  fundWalletModal: boolean;
  setFundWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function FundWalletForm({ url, fundWalletModal, setFundWalletModal }: Props) {
  const form = useFormik({
    initialValues: {
      amount: 0,
      narative: "",
    },
    validationSchema: yup.object({
      amount: yup.number().min(50, "Minimum of NGN 50"),
      narative: yup.string().required("Required"),
    }),

    onSubmit: async (values) => {
      try {
        const idToken = await authService.getIdToken();
        const { data } = await httpClient.post(url, values, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        toast.success(data.message);
        setFundWalletModal(false);
      } catch (err: any) {
        toast.error(handleError(err));
      }
    },
  });

  return (
    <Modal
      isOpen={fundWalletModal}
      closeModal={setFundWalletModal}
      closeBtnDissabled={form.isSubmitting}
      title="Fund Wallet"
    >
      <form className="transition-all" onSubmit={form.handleSubmit}>
        <div className="mb-5">
          <Text content="Amount" className="text-sm mb-1" />
          <Input
            type="number"
            name="amount"
            onChange={form.handleChange}
            value={form.values.amount}
            className={`${
              form.errors.amount && form.touched.amount ? "!border-error" : ""
            }`}
          />
          {form.errors.amount && form.touched.amount ? (
            <Text
              content={form.errors.amount}
              className="text-xs mt-1 text-error font-thin"
            />
          ) : null}
        </div>
        <div className="mb-5">
          <div>
            <Text content="Narative" className="text-sm mb-1" />
            <TextArea
              name="narative"
              placeholder="Narative"
              onChange={form.handleChange}
              value={form.values.narative}
              className={`bg-backgroundShade ${
                form.errors.narative ? "!border-error" : ""
              }`}
            />
            {form.errors.narative ? (
              <Text
                content={form.errors.narative}
                className="text-xs mt-1 text-error font-thin"
              />
            ) : null}
          </div>
        </div>

        <Button
          text={form.isSubmitting ? "Please wait..." : "Fund"}
          className="w-full text-foreground disabled:opacity-50 "
          disabled={form.isSubmitting}
        />
      </form>
    </Modal>
  );
}

export default FundWalletForm;
