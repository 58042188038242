import { createSlice } from "@reduxjs/toolkit";
import { IAuth } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define a type for the slice state
export interface IAuthInitialState {
  loaded: boolean;
  user: IAuth | null;
}

// Define the initial state using that type
const initialState: IAuthInitialState = {
  loaded: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
      state.loaded = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.user = null;
    });
  },
});

export const { updateUser } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
