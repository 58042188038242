import React from "react";
import { Link } from "react-router-dom";
import Card from "../components/shared/Card";
import Text from "../components/shared/Text";
import ForgotPasswordForm from "../components/shared/ForgotPasswordForm";
import CuzooLogo from "../components/shared/CuzooLogo";

function ForgotPassword() {
  return (
    <div className="h-screen w-full py-5 overflow-hidden px-3">
      <div className="flex items-center justify-center">
        <Link to="/">
          <CuzooLogo />
        </Link>
      </div>
      <div className="flex mt-20 md:mt-0 md:items-center justify-center h-full">
        <Card className="w-full md:w-1/3 px-5 py-10">
          <header className="text-center">
            <Text content="Forgot Password" className="text-lg mg:text-xl" />
          </header>
          <ForgotPasswordForm />
        </Card>
      </div>
    </div>
  );
}

export default ForgotPassword;
