import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import Card from "../components/shared/Card";
import Header from "../components/shared/Header";
import Text from "../components/shared/Text";
import { useAppSelector } from "../hooks/useRedux";
import { selectRiderRegistration } from "../redux/slices/riderRegistrationSlice";

function RidersRegistration() {
  const { pendingRegistration } = useAppSelector(selectRiderRegistration);

  const [completed, setCompleted] = useState(false);

  const [value, setValue] = useState<DateValueType>({
    startDate: dayjs().subtract(7, "day").startOf("day").toDate(),
    endDate: dayjs().toDate(),
  });

  const registrations = useMemo(() => {
    let regs = pendingRegistration;

    if (value?.startDate && value.endDate) {
      regs = regs.filter((reg) => {
        const createdAt = dayjs(reg.createdAt, "DD MMM YYYY @ hh:mm:ssa");
        if (
          (createdAt.isSame(value.startDate) ||
            createdAt.isAfter(value.startDate)) &&
          (createdAt.isSame(value.endDate) || createdAt.isBefore(value.endDate))
        ) {
          return true;
        }

        return false;
      });
    }

    if (completed) {
      regs = regs.filter((reg) => reg.regComplete);
    }

    return {
      count: regs.length,
      regs,
    };
  }, [completed, pendingRegistration, value?.endDate, value?.startDate]);

  return (
    <div className="h-full">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <Header>
            <div className="flex items-center gap-2">
              <Text content="Pending Riders Registration" className="text-lg" />
              <Text content={`${registrations.count}`} className="text-lg" />
            </div>
          </Header>
        </div>

        <div className="w-fit flex items-center gap-4 px-2">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              onChange={(e) => setCompleted(e.target.checked)}
              checked={completed}
            />
            <p className="text-sm">Awaiting Approval</p>
          </div>
          <div className="flex-1">
            <Datepicker
              primaryColor="blue"
              showShortcuts
              // inputClassName="text-sm"
              value={value}
              onChange={(newValue) =>
                setValue({
                  startDate: dayjs(newValue?.startDate).startOf("day").toDate(),
                  endDate: dayjs(newValue?.endDate).endOf("day").toDate(),
                })
              }
            />
          </div>
        </div>
      </div>
      {registrations.regs.length ? (
        <div className="p-3 grid md:grid-cols-2 lg:grid-cols-4 gap-2">
          {registrations.regs.map((rider) => (
            <Link to={`/riders-registration/${rider.id}`} key={rider.id}>
              <Card key={rider.id}>
                <div className="flex items-center">
                  <div className="p-3 bg-backgroundShade">
                    <GiFullMotorcycleHelmet
                      size={30}
                      className="text-foreground"
                    />
                  </div>
                  <div className="px-4">
                    <p className="text-sm capitalize font-regular flex-1">
                      {rider.firstName} {rider.lastName}
                    </p>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </div>
      ) : (
        <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
          No Pending Rider Registration
        </div>
      )}
    </div>
  );
}

export default RidersRegistration;
