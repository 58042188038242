import { DocumentData, Query } from "firebase/firestore";
import {
  onSnapshot,
  db,
  doc,
  query,
  collection,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getDoc,
  where,
} from "../config/fb";
import { CollectionNames } from "../enums";
import {
  IActions,
  IIndividualRiderAnalytics,
  IRating,
  IRider,
  IRiderLocation,
  ITransaction,
  IWallet,
} from "../interfaces";

const riderService = {
  watchWallet: (riderId: string, updateWallet: (d: IWallet | null) => void) => {
    if (!riderId) {
      updateWallet(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.RIDERS_WALLETS, riderId),
      (walletDoc) => {
        const data = {
          id: walletDoc.id,
          ...walletDoc.data(),
        };
        updateWallet(data as IWallet);
      }
    );
  },

  getSingleRider: async (riderId: string): Promise<IRider | null> => {
    if (!riderId) {
      return null;
    }

    const snapshot = await getDoc(doc(db, CollectionNames.RIDERS, riderId));

    if (!snapshot.exists()) {
      return null;
    }

    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as IRider;
  },

  watchRiderLocation: (
    riderId: string,
    updateRiderLocation: (d: IRiderLocation | null) => void
  ) => {
    if (!riderId) {
      updateRiderLocation(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.RIDER_LOCATION, riderId),
      (locationDoc) => {
        if (locationDoc.exists()) {
          const data = {
            id: locationDoc.id,
            ...locationDoc.data(),
          };
          updateRiderLocation(data as IRiderLocation);
        } else {
          updateRiderLocation(null);
        }
      }
    );
  },

  fetchAnalytics: (
    riderId: string,
    updateAnalytics: (d: IIndividualRiderAnalytics | null) => void
  ) => {
    if (!riderId) {
      updateAnalytics(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.INDIVIDUAL_RIDER_ANALYTICS, riderId),
      (analyticsDoc) => {
        if (!analyticsDoc.exists()) {
          updateAnalytics(null);
        } else {
          const data = {
            id: analyticsDoc.id,
            ...analyticsDoc.data(),
          };
          updateAnalytics(data as IIndividualRiderAnalytics);
        }
      }
    );
  },

  fetchTransactions: async (
    riderId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: ITransaction[]; more: boolean }> => {
    if (!riderId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.RIDERS_TRANSACTIONS),
        where("riderId", "==", riderId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.RIDERS_TRANSACTIONS),
        where("riderId", "==", riderId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: ITransaction[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  fetchRatings: async (
    riderId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: IRating[]; more: boolean }> => {
    if (!riderId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.RIDERS_RATINGS),
        where("riderId", "==", riderId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.RIDERS_RATINGS),
        where("riderId", "==", riderId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IRating[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  fetchActions: async (
    riderId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: IActions[]; more: boolean }> => {
    if (!riderId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.RIDERS_ACTIONS),
        where("userId", "==", riderId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.RIDERS_ACTIONS),
        where("userId", "==", riderId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IActions[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },
};

export default riderService;
