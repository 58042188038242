import { Unsubscribe, User } from "firebase/auth";
import {
  onAuthStateChanged,
  auth,
  doc,
  onSnapshot,
  db,
  query,
  collection,
  signInWithEmailAndPassword,
  signOut,
  orderBy,
  where,
} from "../config/fb";
import httpClient from "../config/HTTPClient";
import { AdminPositions, CollectionNames, ExtUrls } from "../enums";
import { formatDate } from "../utils/_helper";
import { IAuth } from "../interfaces";

const authService = {
  authenticate: (
    setFirebaseAuth: React.Dispatch<React.SetStateAction<User | null>>,
    updateUser: (auth: any) => void
  ): void => {
    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          setFirebaseAuth(user);
        } else {
          setFirebaseAuth(null);
          updateUser(null);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  },

  getUser: (userId: string, updateUser: (auth: any) => void): Unsubscribe =>
    onSnapshot(doc(db, CollectionNames.ADMINS, userId), (userDoc) => {
      const data = {
        id: userDoc.id,
        ...userDoc.data(),
      };
      formatDate(data);
      updateUser(data);
    }),

  getMetadata: (updateMetadata: (metadata: any) => void): Unsubscribe =>
    onSnapshot(query(collection(db, CollectionNames.METADATA)), (snapshots) => {
      const meta: Record<string, any> = {};

      snapshots.forEach((document) => {
        meta[document.id] = {
          id: document.id,
          ...document.data(),
        };

        formatDate(meta[document.id]);
      });

      updateMetadata(meta);
    }),

  getRiderRegistration: (
    user: IAuth,
    updateRiderRegistration: (reg: any) => void
  ): Unsubscribe =>
    onSnapshot(
      query(
        collection(db, CollectionNames.RIDERS),
        where("approved", "==", false),
        // where("regComplete", "==", true),
        ...[
          ...(user.position === AdminPositions.INVESTOR
            ? [
                where("country", "==", user.country),
                where("state", "==", user.state),
              ]
            : []),
        ],
        orderBy("createdAt", "desc")
      ),
      (snapshots) => {
        const riderData: any[] = [];

        snapshots.forEach((document) => {
          const data: any = {
            id: document.id,
            ...document.data(),
          };

          formatDate(data);
          riderData.push(data);
        });
        // console.log(riderData);
        updateRiderRegistration(riderData);
      },
      (error) => console.log(error)
    ),

  getSettings: (updateCuzooSettings: (settings: any) => void): Unsubscribe =>
    onSnapshot(query(collection(db, CollectionNames.SETTINGS)), (snapshots) => {
      const data: Record<string, any> = {};

      snapshots.forEach((document) => {
        data[document.id] = {
          id: document.id,
          ...document.data(),
        };
      });
      updateCuzooSettings(data);
    }),

  getCompaniesRegistration: (
    updateRegistration: (reg: any) => void
  ): Unsubscribe =>
    onSnapshot(
      query(
        collection(db, CollectionNames.COMPANY_REGISTRATION),
        orderBy("createdAt", "desc")
      ),
      (snapshots) => {
        const companiesData: any[] = [];

        snapshots.forEach((document) => {
          const data: any = {
            id: document.id,
            ...document.data(),
          };

          companiesData.push(data);
        });

        updateRegistration(companiesData);
      }
    ),

  login: async (email: string, password: string) => {
    if (!email.endsWith("@cuzooapp.com")) {
      throw new Error("Invalid Email Address");
    }
    await signInWithEmailAndPassword(auth, email, password);
  },

  requestPasswordReset: async (email: string): Promise<{ message: string }> => {
    if (!email.endsWith("@cuzooapp.com")) {
      throw new Error("Invalid Email Address");
    }
    const { data } = await httpClient.post(ExtUrls.REQUEST_PASSWORD_RESET, {
      email,
      from: "admin",
    });

    return data;
  },

  logout: async (): Promise<void> => {
    await signOut(auth);
  },

  getIdToken: async () => {
    const idToken = await auth.currentUser?.getIdToken(true);
    return idToken;
  },

  watchWithdrawals: <T>(
    collectionName: string,
    updateFunc: (data: T[]) => void
  ): Unsubscribe =>
    onSnapshot(
      query(collection(db, collectionName), where("resolved", "==", false)),
      (snapshots) => {
        const data: any[] = [];

        if (!snapshots.empty) {
          snapshots.forEach((dataDoc) =>
            data.push({ id: dataDoc.id, ...dataDoc.data() })
          );
        }

        updateFunc(data);
      }
    ),
};

export default authService;
