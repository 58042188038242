import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useRedux";
import { selectAuth } from "../redux/slices/authSlice";
import logo from "../assets/images/logo-full.png";
import Text from "../components/shared/Text";
import authSvg from "../assets/svg/auth.svg";

function Suspended() {
  const auth = useAppSelector(selectAuth);

  if (!auth.user?.suspended) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="h-screen w-screen py-5 overflow-hidden px-3">
      <div className="flex items-center justify-center">
        <img src={logo} alt="Cuzoo Logo" className="w-32" />
      </div>
      <div className="flex flex-col md:mt-0 items-center justify-center h-full">
        <img src={authSvg} alt="Cuzoo Logo" className="w-40 mb-5" />
        <Text
          content="Oops!!! Your Account has been Suspended. Please contact a Super Admin Imediately."
          className="text-center"
        />
      </div>
    </div>
  );
}

export default Suspended;
