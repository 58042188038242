import { createSlice } from "@reduxjs/toolkit";
import { IRider } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import { getRidersAction, searchRidersAction } from "../actions/riders.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  riders: IRider[];
  loaded: boolean;
  lastVisible: any;
  more: boolean;
  loading: boolean;
  searchLoading: boolean;
  searchLoaded: boolean;
  range: { startDate: number; endDate: number } | null;
  country: string;
  state: string;
} = {
  riders: [],
  loaded: false,
  lastVisible: null,
  more: true,
  loading: false,
  searchLoading: false,
  searchLoaded: false,
  range: null,
  country: "",
  state: "",
};

export const ridersSlice = createSlice({
  name: "riders",
  initialState,
  reducers: {
    reset: (state) => {
      state.riders = [];
      state.loaded = false;
      state.lastVisible = null;
      state.more = true;
      state.loading = false;
      state.searchLoading = false;
      state.searchLoaded = false;
      state.range = null;
      state.country = "";
      state.state = "";
    },
    resetSearchRiders: (state) => {
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    updateRiders: (state, action) => {
      state.riders = [...state.riders, action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction.fulfilled, (state) => {
        state.riders = [];
        state.loaded = false;
        state.lastVisible = null;
        state.loading = false;
        state.searchLoading = false;
        state.searchLoaded = false;
        state.range = null;
        state.country = "";
        state.state = "";
      })
      .addCase(getRidersAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRidersAction.rejected, (state) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getRidersAction.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.lastVisible = action.payload.lv;
        state.more = action.payload.more;
        state.range = action.payload.range;
        state.country = action.payload.country;
        state.state = action.payload.state;
        state.riders = [
          ...state.riders.filter(
            (rider) => !action.payload.data.find((d) => d.id === rider.id)
          ),
          ...action.payload.data,
        ];
      })
      .addCase(searchRidersAction.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchRidersAction.rejected, (state) => {
        state.searchLoading = false;
        state.searchLoaded = true;
      })
      .addCase(searchRidersAction.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchLoaded = true;
        state.riders = [
          ...state.riders.filter(
            (rider) => !action.payload.data.find((d) => d.id === rider.id)
          ),
          ...action.payload.data,
        ];
      }),
});

export const { reset, resetSearchRiders, updateRiders } = ridersSlice.actions;
export const selectRiders = (state: RootState) => state.riders;

export default ridersSlice.reducer;
