import { Disclosure } from "@headlessui/react";
import { GiPayMoney } from "react-icons/gi";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Country, State } from "country-state-city";
import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Timestamp } from "firebase/firestore";
import { MdMoney } from "react-icons/md";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import Header from "../components/shared/Header";
import Text from "../components/shared/Text";
import WithdrawlRequest from "../components/shared/WithdrawlRequest";
import { AdminPositions, CollectionNames } from "../enums";
import { useAppSelector } from "../hooks/useRedux";
import { selectWithdrawalRequests } from "../redux/slices/withdrawalRequestSlice";
import { selectAuth } from "../redux/slices/authSlice";
import { handleError, moneyToNaira } from "../utils/_helper";
import {
  collection,
  db,
  query,
  where,
  getAggregateFromServer,
  sum,
} from "../config/fb";
import CountCard from "../components/shared/CountCard";

function Financials() {
  const { riders, companies } = useAppSelector(selectWithdrawalRequests);
  const { user } = useAppSelector(selectAuth);
  const [value, setValue] = useState<DateValueType>({
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().toDate(),
  });

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["Financial", value, country, state],
    queryFn: async () => {
      try {
        let q = query(
          collection(db, CollectionNames.ORDERS),
          where("completed", "==", true),
          where("resolved", "==", true)
        );

        if (value) {
          q = query(
            q,
            where(
              "createdAt",
              ">=",
              Timestamp.fromDate(dayjs(value.startDate).startOf("day").toDate())
            ),
            where(
              "createdAt",
              "<=",
              Timestamp.fromDate(dayjs(value.endDate).endOf("day").toDate())
            )
          );
        }

        if (user?.position === AdminPositions.INVESTOR) {
          q = query(
            q,
            where("country", "==", user.country),
            where("state", "==", user.state)
          );
        } else {
          if (country) {
            q = query(q, where("country", "==", country));
          }

          if (state) {
            q = query(q, where("state", "==", state));
          }
        }

        const d = await getAggregateFromServer(q, {
          commission: sum("cuzooCommission"),
        });

        return d.data().commission;
      } catch (error) {
        toast.error(handleError(error));
        return 0;
      }
    },
  });

  return (
    <div className="h-full">
      <Header>
        <Text content="Financials" className="text-lg" />
      </Header>
      <div className="h-full overflow-hidden grid grid-cols-1 md:grid-cols-3 gap-3 p-3">
        {user?.position !== AdminPositions.INVESTOR ? (
          <section className="md:order-3">
            <h2 className="font-semibold text-sm">Withdrawal Requests</h2>
            {riders?.length ? (
              <div className="w-full mt-2">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center justify-between p-2 w-full bg-backgroundTint rounded-md">
                        <div className="flex items-center">
                          <GiPayMoney size={20} className="text-foreground" />
                          <p className="ml-3 text-sm font-semibold">
                            Riders - Individual
                          </p>
                        </div>
                        <IoChevronForwardSharp
                          size={15}
                          className={`${
                            open ? "rotate-90" : ""
                          } h-5 w-5 text-foreground transition`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="divide-y divide-foreground/20 rounded-md border-b border-x border-foreground/20">
                        {riders?.map((request) => (
                          <WithdrawlRequest
                            request={request}
                            key={request.id}
                            type={CollectionNames.RIDERS}
                          />
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ) : null}
            {companies?.length ? (
              <div className="w-full mt-2">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center justify-between p-2 w-full bg-backgroundTint rounded-md">
                        <div className="flex items-center">
                          <GiPayMoney size={20} className="text-foreground" />
                          <p className="ml-3 text-sm font-semibold">
                            Companies
                          </p>
                        </div>
                        <IoChevronForwardSharp
                          size={15}
                          className={`${
                            open ? "rotate-90" : ""
                          } h-5 w-5 text-foreground transition`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="divide-y divide-foreground/20 rounded-md border-b border-x border-foreground/20">
                        {companies?.map((request) => (
                          <WithdrawlRequest
                            request={request}
                            key={request.id}
                            type={CollectionNames.COMPANIES}
                          />
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ) : null}

            {!riders.length && !companies.length ? (
              <div className="p-2 text-center">
                <p className="text-xs">No request at the moment</p>
              </div>
            ) : null}
          </section>
        ) : null}
        <section className="md:col-span-2">
          <div className="flex items-center justify-between">
            <div className="w-fit flex items-center gap-4 px-2">
              <div className="flex-1">
                <Datepicker
                  primaryColor="blue"
                  showShortcuts
                  // inputClassName="text-sm"
                  value={value}
                  onChange={(newValue) =>
                    setValue({
                      startDate: dayjs(newValue?.startDate)
                        .startOf("day")
                        .toDate(),
                      endDate: dayjs(newValue?.endDate).endOf("day").toDate(),
                    })
                  }
                />
              </div>

              {user?.position !== AdminPositions.INVESTOR ? (
                <>
                  <div className="flex-1">
                    <select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full"
                    >
                      <option value="">Country</option>
                      {Country.getAllCountries().map((ctry) => (
                        <option key={ctry.isoCode} value={ctry.isoCode}>
                          {ctry.flag} {ctry.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex-1">
                    <select
                      value={state || ""}
                      onChange={(e) => setState(e.target.value)}
                      className="bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full"
                    >
                      <option value="">State</option>
                      {(country ? State.getStatesOfCountry(country) : []).map(
                        (s) => (
                          <option
                            key={`${s.isoCode} ${s.name}`}
                            value={s.isoCode}
                          >
                            {s.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="w-fit my-5">
            <CountCard
              label={`Total Commission (${dayjs(value?.startDate).format(
                "DD/MM/YYYY"
              )} - (${dayjs(value?.endDate).format("DD/MM/YYYY")})`}
              Icon={MdMoney}
              loading={isLoading}
              count={data ? moneyToNaira(data) : 0}
              iconBgColor="bg-cyan-100"
              iconColor="text-cyan-700"
              emptyMessage="No Commission Made"
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Financials;
