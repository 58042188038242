import React, { useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import StarRatings from "react-star-ratings";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link } from "react-router-dom";
import { IRating, IRiderLocation } from "../../interfaces";
import riderService from "../../services/rider.service";
import { handleError } from "../../utils/_helper";
import Spinner from "./Spinner";
import Button from "./Button";

interface Props {
  riderLocation: IRiderLocation;
}

function RiderRating({ riderLocation }: Props) {
  const total = useMemo(() => {
    if (riderLocation.ratings) {
      const totalRatings = Object.values(riderLocation.ratings).reduce(
        (a, b) => a + b,
        0
      );
      const totalAverage =
        Object.keys(riderLocation.ratings)
          .map((key) => Number(key) * riderLocation.ratings[key as any])
          .reduce((a, b) => a + b, 0) / totalRatings;

      return {
        totalAverage,
        totalRatings,
      };
    }

    return {
      totalAverage: 0,
      totalRatings: 0,
    };
  }, [riderLocation.ratings]);

  const [riderRatings, setRiderRatings] = useState<{
    ratings: IRating[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    ratings: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const getRatings = useCallback(
    async (lv: any) => {
      setRiderRatings((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await riderService.fetchRatings(riderLocation.id, lv);
        setRiderRatings((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          ratings: [
            ...prevState.ratings.filter(
              (rating) => !res.data.find((d) => d.id === rating.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        setRiderRatings((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [riderLocation.id]
  );

  useEffect(() => {
    if (!riderRatings.loaded && !riderRatings.ratings.length) {
      getRatings(riderRatings.lastVisible);
    }
  }, [
    riderRatings.lastVisible,
    riderRatings.loaded,
    riderRatings.ratings.length,
    getRatings,
  ]);

  return (
    <div className="p-2">
      <div className="flex items-center">
        <div>
          <h1 className="text-4xl">
            <NumberFormat
              value={total.totalAverage}
              displayType="text"
              decimalScale={1}
              fixedDecimalScale
            />
          </h1>
          <div>
            <StarRatings
              rating={total.totalAverage}
              starRatedColor="#f59e0b"
              starDimension="10px"
              starSpacing="2px"
              numberOfStars={5}
              name="rating"
            />
          </div>
          <span className="text-xs opacity-80 text-extralight">
            {total.totalRatings}
          </span>
        </div>
        <div className="flex-1 ml-2">
          <div className="flex items-center w-full">
            <span className="text-xs">5</span>
            <ProgressBar
              completed={
                riderLocation.ratings && riderLocation.ratings["5"] > 0
                  ? (riderLocation.ratings["5"] / total.totalRatings) * 100
                  : 0
              }
              className="w-full ml-1"
              height="6px"
              bgColor="#22c55e"
              baseBgColor="#6b7280"
              isLabelVisible={false}
            />
          </div>
          <div className="flex items-center w-full">
            <span className="text-xs">4</span>
            <ProgressBar
              completed={
                riderLocation.ratings && riderLocation.ratings["4"] > 0
                  ? (riderLocation.ratings["4"] / total.totalRatings) * 100
                  : 0
              }
              className="w-full ml-1"
              height="6px"
              bgColor="#22c55e"
              baseBgColor="#6b7280"
              isLabelVisible={false}
            />
          </div>

          <div className="flex items-center w-full">
            <span className="text-xs">3</span>
            <ProgressBar
              completed={
                riderLocation.ratings && riderLocation.ratings["3"] > 0
                  ? (riderLocation.ratings["3"] / total.totalRatings) * 100
                  : 0
              }
              className="w-full ml-1"
              height="6px"
              bgColor="#22c55e"
              baseBgColor="#6b7280"
              isLabelVisible={false}
            />
          </div>

          <div className="flex items-center w-full">
            <span className="text-xs">2</span>
            <ProgressBar
              completed={
                riderLocation.ratings && riderLocation.ratings["2"] > 0
                  ? (riderLocation.ratings["2"] / total.totalRatings) * 100
                  : 0
              }
              className="w-full ml-1"
              height="6px"
              bgColor="#22c55e"
              baseBgColor="#6b7280"
              isLabelVisible={false}
            />
          </div>

          <div className="flex items-center w-full">
            <span className="text-xs">1</span>
            <ProgressBar
              completed={
                riderLocation.ratings && riderLocation.ratings["1"] > 0
                  ? (riderLocation.ratings["1"] / total.totalRatings) * 100
                  : 0
              }
              className="w-full ml-1"
              height="6px"
              bgColor="#22c55e"
              baseBgColor="#6b7280"
              isLabelVisible={false}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        {riderRatings.ratings.map((rating) => (
          <div
            className="bg-backgroundTint rounded-md shadow-md my-1 p-2"
            key={rating.id}
          >
            <div className="flex items-center">
              <div className="text-xs font-semibold w-10 h-10 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
                <p>
                  {rating.userDetails.firstName[0].toUpperCase() +
                    rating.userDetails.lastName[0].toUpperCase()}
                </p>
              </div>
              <div className="ml-3">
                <Link
                  to={`/users/${rating.userDetails.userId}`}
                  className="text-xs hover:text-tertiary"
                >
                  {rating.userDetails.firstName} {rating.userDetails.lastName}
                </Link>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <StarRatings
                  rating={rating.star}
                  starRatedColor="#f59e0b"
                  starDimension="10px"
                  starSpacing="2px"
                  numberOfStars={5}
                  name="rating"
                />
              </div>
              <span className="text-xs opacity-50 ml-1">
                {dayjs(rating.createdAt?.toDate()).format("DD/MM/YY")}
              </span>
            </div>
            {rating.review ? <p className="text-xs">{rating.review}</p> : null}
          </div>
        ))}
        {riderRatings.loaded && !riderRatings.ratings.length ? (
          <p className="text-center p-2 text-xs">
            This rider has no rating yet.
          </p>
        ) : null}
        {riderRatings.loading ? <Spinner /> : null}
        {riderRatings.loaded && riderRatings.more && !riderRatings.loading ? (
          <Button
            onClick={() => getRatings(riderRatings.lastVisible)}
            text="Load More"
            className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
          />
        ) : null}
      </div>
    </div>
  );
}

export default RiderRating;
