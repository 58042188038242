import { createSlice } from "@reduxjs/toolkit";
import { IRiderRegistration } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define a type for the slice state
export interface IInitialState {
  pendingRegistration: IRiderRegistration[];
}

// Define the initial state using that type
const initialState: IInitialState = {
  pendingRegistration: [],
};

export const riderRegistrationSlice = createSlice({
  name: "ridersRegistration",
  initialState,
  reducers: {
    updateRiderRegistration: (state, action) => {
      state.pendingRegistration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.pendingRegistration = [];
    });
  },
});

export const { updateRiderRegistration } = riderRegistrationSlice.actions;
export const selectRiderRegistration = (state: RootState) =>
  state.riderRegistration;

export default riderRegistrationSlice.reducer;
