/* eslint-disable import/prefer-default-export */
import { DateValueType } from "react-tailwindcss-datepicker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IRider } from "../../interfaces";
import ridersService from "../../services/riders.service";

export const getRidersAction = createAsyncThunk<
  {
    data: IRider[];
    lv: any;
    more: boolean;
    range: { startDate: number; endDate: number } | null;
    country: string;
    state: string;
  },
  { lastVisible: any; range: DateValueType; country: string; st: string }
>(
  "riders/getRidersAction",
  async (
    { lastVisible, range, country, st },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as any;
      const response = await ridersService.fetchRiders(
        state.auth.user!,
        lastVisible,
        range,
        country,
        st
      );
      return {
        ...response,
        range:
          range?.startDate && range.endDate
            ? {
                startDate: +dayjs(range.startDate),
                endDate: +dayjs(range.endDate),
              }
            : null,
        country,
        state: st,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const searchRidersAction = createAsyncThunk<
  { data: IRider[] },
  { key: string; value: string }
>(
  "riders/searchRidersAction",
  async ({ key, value }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;
      const response = await ridersService.searchRiders(
        state.auth.user!,
        key,
        value
      );
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
