import React, { Fragment, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import Header from "../components/shared/Header";
import { selectUsers, updateUsers } from "../redux/slices/usersSlice";
import useUserDetails from "../hooks/useUserDetails";
import DetailInfoCards from "../components/shared/DetailInfoCards";
import Spinner from "../components/shared/Spinner";
import Button from "../components/shared/Button";
import Transaction from "../components/shared/Transaction";
import Action from "../components/shared/Action";
import BarChart from "../components/shared/BarChart";
import userService from "../services/user.service";
import FundWalletForm from "../components/shared/FundWalletForm";

function UserDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { users } = useAppSelector(selectUsers);

  const user = useMemo(
    () => users?.find((v) => v.id === params.id),
    [params.id, users]
  );

  useEffect(() => {
    const getSingleUser = async () => {
      try {
        const res = await userService.getSingleUser(params.id!);
        if (!res) {
          return navigate("/users", { replace: true });
        }
        return dispatch(updateUsers(res));
      } catch (error) {
        return navigate("/users", { replace: true });
      }
    };

    if (!user) {
      getSingleUser();
    }
  }, [dispatch, navigate, params.id, user]);

  const {
    wallet,
    userAnalytics,
    userTransactions,
    getTransactions,
    userActions,
    getActions,
  } = useUserDetails(params.id!);

  const [fundWalletModal, setFundwalletModal] = useState(false);

  if (!user) {
    return (
      <div className="h-full grid place-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="h-full">
      <Header>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <button
              type="button"
              className="p-2"
              onClick={() => navigate("/users", { replace: true })}
            >
              <IoArrowBackOutline size={20} />
            </button>
            <div className="ml-3">
              <p className="text-lg leading-none">
                {user?.firstName} {user?.lastName}
              </p>
              <span className="text-xs opacity-60 font-bold">User Profile</span>
            </div>
          </div>

          <Menu
            as="div"
            className={`relative inline-block ${!wallet ? "hidden" : "block"}`}
          >
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md bg-backgroundTint bg-opacity-20 px-4 py-2 text-sm font-medium text-foreground/90 hover:text-foreground">
                <span className="hidden md:inline-block">Actions</span>
                <HiOutlineDotsVertical size={20} />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-6 mt-2 w-32 origin-top-right divide-y divide-foreground/30 rounded-md bg-backgroundTint shadow-lg focus:outline-none">
                <div className="py-1 ">
                  <Menu.Item
                    as="button"
                    role="button"
                    className="text-xs p-2 w-full hover:bg-background"
                    onClick={() => setFundwalletModal(true)}
                  >
                    Fund Wallet
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </Header>
      {user ? (
        <div className="p-2 grid grid-cols-1 md:grid-cols-3 gap-2">
          <div className="md:col-span-2 order-last md:order-first">
            <DetailInfoCards wallet={wallet} userAnalytics={userAnalytics} />
            <div className="p2">
              <BarChart
                data={{
                  labels: ["Completed", "Pending", "Cancelled"],
                  datasets: [
                    {
                      label: "Orders",
                      data: [
                        userAnalytics?.completed || 0,
                        userAnalytics
                          ? userAnalytics.total -
                            (userAnalytics.cancelled + userAnalytics.completed)
                          : 0,
                        userAnalytics?.cancelled || 0,
                      ],
                      backgroundColor: [
                        "rgb(16 185 129 / 0.2)",
                        "rgb(245 158 11 / 0.2)",
                        "rgb(244 63 94 / 0.2)",
                      ],
                      borderColor: ["#10b981", "#f59e0b", "#f43f5e"],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
            <div className="p-2">
              <h2 className="py-2 text-lg font-bold">Transactions</h2>
              {userTransactions.transactions.length ? (
                <div className="divide-y divide-foreground/30">
                  {userTransactions.transactions.map((transaction) => (
                    <Transaction
                      transaction={transaction}
                      key={transaction.id}
                    />
                  ))}
                </div>
              ) : null}
              {userTransactions.loaded &&
              !userTransactions.transactions.length ? (
                <p className="text-center p-2 text-xs">
                  This user has no transactions yet.
                </p>
              ) : null}
              {userTransactions.loading ? <Spinner /> : null}
              {userTransactions.loaded &&
              userTransactions.more &&
              !userTransactions.loading ? (
                <Button
                  onClick={() => getTransactions(userTransactions.lastVisible)}
                  text="Load More"
                  className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                />
              ) : null}
            </div>
          </div>

          <div className="p-2 bg-background">
            <div className="text-center p-2">
              {user.profilePicture ? (
                <img
                  src={user.profilePicture.url}
                  alt="User Profile"
                  className="w-20 h-20 object-cover rounded-full mx-auto"
                />
              ) : (
                <div className="text-sm mx-auto font-semibold w-20 h-20 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
                  <p>
                    {user.firstName[0].toUpperCase() +
                      user.lastName[0].toUpperCase()}
                  </p>
                </div>
              )}
              <h2>
                {user.firstName} {user.lastName}
              </h2>
              <a
                href={`mailto:${user.email}`}
                className="block hover:text-tertiary text-xs my-1"
              >
                {user.email}
              </a>
              <a
                href={`mailto:${user.phoneNumber.number}`}
                className="block hover:text-tertiary text-xs my-1"
              >
                {user.phoneNumber.nationalFormat}
              </a>
              <p className="text-xs opacity-50">
                Joined - {dayjs(user.createdAt.toDate()).format("DD/MM/YYYY")}
              </p>
            </div>

            <div className="hidden md:block">
              <h2 className="p-2 font-bold">User Actions</h2>
              {userActions.actions.map((action) => (
                <Action action={action} key={action.id} />
              ))}
              {userActions.loaded && !userActions.actions.length ? (
                <p className="text-center p-2 text-xs">
                  This user has no action yet.
                </p>
              ) : null}
              {userActions.loading ? <Spinner /> : null}
              {userActions.loaded &&
              userActions.more &&
              !userActions.loading ? (
                <Button
                  onClick={() => getActions(userActions.lastVisible)}
                  text="Load More"
                  className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
          Not Found
        </div>
      )}

      <FundWalletForm
        url={`/v2/users/wallets/${wallet?.id}/fund`}
        setFundWalletModal={setFundwalletModal}
        fundWalletModal={fundWalletModal}
      />
    </div>
  );
}

export default UserDetails;
