import React from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

function Card({ children, className, ...rest }: Props) {
  return (
    <div
      className={`bg-backgroundTint shadow-md rounded-md ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
