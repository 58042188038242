import React, { PureComponent } from "react";
import CuzooLogo from "./shared/CuzooLogo";

class Loading extends PureComponent {
  render() {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div>
          <CuzooLogo />
        </div>
      </div>
    );
  }
}

export default Loading;
