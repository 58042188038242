/* eslint-disable no-underscore-dangle */
import dayjs from "dayjs";
import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { DateValueType } from "react-tailwindcss-datepicker";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { getRidersAction } from "../../redux/actions/riders.actions";
import { reset, selectRiders } from "../../redux/slices/ridersSlice";
import { handleError } from "../../utils/_helper";
import Button from "./Button";
import RiderItem from "./RiderItem";
import Spinner from "./Spinner";

interface Props {
  range: DateValueType;
  country: string;
  state: string;
}

function RidersList({ range, country, state }: Props) {
  const {
    loaded,
    loading,
    more,
    riders,
    lastVisible,
    range: stateRange,
    country: stateCountry,
    state: stateState,
  } = useAppSelector(selectRiders);
  const dispatch = useAppDispatch();

  const fetchRider = useCallback(
    async (lv: any) => {
      try {
        await dispatch(
          getRidersAction({ lastVisible: lv, range, country, st: state })
        );
      } catch (error) {
        toast.error(handleError(error));
      }
    },
    [country, dispatch, range, state]
  );

  useEffect(() => {
    if (
      !loaded ||
      (range &&
        (dayjs(range.startDate).format("DD/MM/YYYY") !==
          dayjs(stateRange?.startDate).format("DD/MM/YYYY") ||
          dayjs(range.endDate).format("DD/MM/YYYY") !==
            dayjs(stateRange?.endDate).format("DD/MM/YYYY"))) ||
      (country && country !== stateCountry) ||
      (state && state !== stateState)
    ) {
      dispatch(reset());
      fetchRider(lastVisible);
    }
  }, [country, dispatch, fetchRider, lastVisible, loaded, range, state]);

  useEffect(() => {
    if (!loaded) {
      fetchRider(lastVisible);
    }
  }, [fetchRider, lastVisible, loaded]);

  return (
    <div className="divide divide-y divide-foreground/10">
      {riders.map((rider) => (
        <RiderItem key={rider.id} rider={rider} />
      ))}
      {loading ? <Spinner /> : null}

      {loaded && more && !loading ? (
        <Button
          onClick={() => fetchRider(lastVisible)}
          text="Load More"
          className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
        />
      ) : null}
    </div>
  );
}

export default RidersList;
