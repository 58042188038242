import React, { PureComponent } from "react";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { text: JSX.Element | string };

interface State {}

class Button extends PureComponent<Props, State> {
  render() {
    const { children, text, className, type, ...rest } = this.props;
    return (
      <button
        type={type ? "button" : "submit"}
        {...rest}
        className={`py-2 px-5 rounded-sm bg-primary text-sm text-white hover:shadow-md ${className}`}
      >
        {text}
      </button>
    );
  }
}

export default Button;
