import { useState } from "react";
import { MdPeople } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import Header from "../components/shared/Header";
import { useAppSelector } from "../hooks/useRedux";
import { selectMetadata } from "../redux/slices/metadataSlice";
import SearchCombo from "../components/shared/SearchCombo";
import SearchFilter from "../components/shared/SearchFilter";
import useSearchFilter from "../hooks/useSearchFilter";
import CourierCompanyList from "../components/shared/CourierCompanyList";

function CourierCompany() {
  const { companies } = useAppSelector(selectMetadata);
  const [showSearchCombo, setShowSearchCombo] = useState(false);
  const { selectedFilter, setSelectedFilter, filter } = useSearchFilter();

  return (
    <>
      <div className="h-full bg-background">
        <Header>
          <p className="text-xl">Riders</p>
        </Header>
        <section className="md:col-span-2 overflow-y-scroll md:h-full">
          {companies ? (
            <div className="p-3">
              <div className="flex items-center justify-between md:hidden mb-3">
                <button
                  type="button"
                  onClick={() => setShowSearchCombo(true)}
                  className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
                >
                  <IoSearchOutline size={20} />
                  <p className="text-xs font-extralight ml-2">
                    :{filter.find((v) => v.value === selectedFilter)?.title}
                  </p>
                </button>
                <SearchFilter
                  filter={filter}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              </div>
              <div className="flex">
                <div className="p-2 w-fit h-fit bg-secondary/10 rounded-md">
                  <MdPeople size={20} className="text-secondary" />
                </div>
                <div className="ml-3">
                  <span className="text-xs font-extralight opacity-40">
                    Total Logistics Partners
                  </span>
                  <p className="text-2xl font-bold">{companies.total}</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="p-2 mx-auto">
            <div className="flex items-center justify-between max-w-sm mx-auto mb-5">
              <button
                type="button"
                onClick={() => setShowSearchCombo(true)}
                className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
              >
                <IoSearchOutline size={20} />
                <p className="text-xs font-extralight ml-2">
                  :{filter.find((v) => v.value === selectedFilter)?.title}
                </p>
              </button>
              <SearchFilter
                filter={filter}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </div>
            <CourierCompanyList />
          </div>
        </section>
      </div>
      <SearchCombo
        isOpen={showSearchCombo}
        closeModal={setShowSearchCombo}
        type="Company"
        filter={filter.find((v) => v.value === selectedFilter)!}
      />
    </>
  );
}

export default CourierCompany;
