import React, { PureComponent } from "react";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

class Input extends PureComponent<Props> {
  render() {
    const { className, ...rest } = this.props;
    return (
      <input
        {...rest}
        style={{
          boxShadow: "none",
        }}
        className={`bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full ${className}`}
      />
    );
  }
}

export default Input;
