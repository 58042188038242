/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react";
import { IoCheckmarkDoneOutline, IoSearchOutline } from "react-icons/io5";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { Country, State } from "country-state-city";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { GiRoad } from "react-icons/gi";
import Header from "../components/shared/Header";
import { useAppSelector } from "../hooks/useRedux";
import { selectMetadata } from "../redux/slices/metadataSlice";
import BarChart from "../components/shared/BarChart";
import OrdersList from "../components/shared/OrdersList";
import SearchFilter from "../components/shared/SearchFilter";
import OrdersSearchCombo from "../components/shared/OrdersSearchCombo";
import { selectAuth } from "../redux/slices/authSlice";
import useCount from "../hooks/useCount";
import { AdminPositions, CollectionNames } from "../enums";

const filter = [
  {
    title: "Order ID",
    value: "orderId",
  },
];

function Orders() {
  const { user } = useAppSelector(selectAuth);
  const { orders } = useAppSelector(selectMetadata);
  const [value, setValue] = useState<DateValueType>({
    startDate: dayjs().subtract(7, "day").startOf("day").toDate(),
    endDate: dayjs().toDate(),
  });

  const [selectedFilter, setSelectedFilter] = useState(filter[0].value);
  const [showSearchCombo, setShowSearchCombo] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const options = useMemo(() => {
    const opts: Parameters<typeof useCount>["0"]["options"] = [];
    if (user?.position === AdminPositions.INVESTOR) {
      opts.push(
        {
          key: "country",
          cmp: "==",
          value: user.country,
        },
        {
          key: "state",
          cmp: "==",
          value: user.state,
        }
      );
    } else {
      if (country) {
        opts.push({
          key: "country",
          cmp: "==",
          value: country,
        });
      }

      if (state) {
        opts.push({
          key: "state",
          cmp: "==",
          value: state,
        });
      }
    }

    if (value?.startDate && value.endDate) {
      opts.push(
        {
          key: "createdAt",
          cmp: ">=",
          value: Timestamp.fromDate(value.startDate as Date),
        },
        {
          key: "createdAt",
          cmp: "<=",
          value: Timestamp.fromDate(value.endDate as Date),
        }
      );
    }

    return opts;
  }, [
    country,
    state,
    user?.country,
    user?.position,
    user?.state,
    value?.endDate,
    value?.startDate,
  ]);

  const { data: totalOrdersCount } = useCount({
    collectionName: CollectionNames.ORDERS,
    options,
    enabled: !!user,
  });

  const { data: completedOrdersCount } = useCount({
    collectionName: CollectionNames.ORDERS,
    options: [
      {
        key: "completed",
        cmp: "==",
        value: true,
      },
      ...options,
    ],
    enabled: !!user,
  });

  const { data: cancelledOrdersCount } = useCount({
    collectionName: CollectionNames.ORDERS,
    options: [
      {
        key: "cancelled",
        cmp: "==",
        value: true,
      },
      ...options,
    ],
    enabled: !!user,
  });

  const { data: pendingOrdersCount } = useCount({
    collectionName: CollectionNames.ORDERS,
    options: [
      {
        key: "pending",
        cmp: "==",
        value: true,
      },
      ...options,
    ],
    enabled: !!user,
  });

  return (
    <>
      <div className="h-full bg-background">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <Header>
              <p className="text xl">Orders</p>
            </Header>
          </div>
          <div className="w-fit flex items-center gap-4 px-2">
            <div className="flex-1">
              <Datepicker
                primaryColor="blue"
                showShortcuts
                // inputClassName="text-sm"
                value={value}
                onChange={(newValue) =>
                  setValue({
                    startDate: dayjs(newValue?.startDate)
                      .startOf("day")
                      .toDate(),
                    endDate: dayjs(newValue?.endDate).endOf("day").toDate(),
                  })
                }
              />
            </div>

            {user?.position !== AdminPositions.INVESTOR ? (
              <>
                <div className="flex-1">
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full"
                  >
                    <option value="">Country</option>
                    {Country.getAllCountries().map((ctry) => (
                      <option key={ctry.isoCode} value={ctry.isoCode}>
                        {ctry.flag} {ctry.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex-1">
                  <select
                    value={state || ""}
                    onChange={(e) => setState(e.target.value)}
                    className="bg-backgroundShade border-none focus:outline-0 outline-0 rounded-sm form-input focus:ring-1 focus:ring-primary text-xs p-3 w-full"
                  >
                    <option value="">State</option>
                    {(country ? State.getStatesOfCountry(country) : []).map(
                      (s) => (
                        <option
                          key={`${s.isoCode} ${s.name}`}
                          value={s.isoCode}
                        >
                          {s.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <section className="p-2">
          {orders ? (
            <>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
                {/* total Orders */}
                <div className="flex items-center">
                  <div className="p-2 h-full flex items-center justify-center rounded-md bg-tertiary/20">
                    <GoPackage size={30} className="text-tertiary" />
                  </div>
                  <div className="flex-1 ml-3 h-full">
                    <p className="text-xs font-extralight opacity-60">
                      Total Orders
                    </p>
                    <h2 className="text-lg font-bold">
                      {totalOrdersCount || 0}
                    </h2>
                  </div>
                </div>

                {/* completed Orders */}
                <div className="flex items-center">
                  <div className="p-2 h-full flex items-center justify-center rounded-md bg-emerald-500/20">
                    <IoCheckmarkDoneOutline
                      size={30}
                      className="text-emerald-500"
                    />
                  </div>
                  <div className="flex-1 ml-3 h-full">
                    <p className="text-xs font-extralight opacity-60">
                      Completed Orders
                    </p>
                    <h2 className="text-lg font-bold">
                      {completedOrdersCount || 0}
                    </h2>
                  </div>
                </div>

                {/* failed Orders */}
                <div className="flex items-center">
                  <div className="p-2 h-full flex items-center justify-center rounded-md bg-rose-500/20">
                    <MdOutlineCancelPresentation
                      size={30}
                      className="text-rose-500"
                    />
                  </div>
                  <div className="flex-1 ml-3 h-full">
                    <p className="text-xs font-extralight opacity-60">
                      Cancelled Orders
                    </p>
                    <h2 className="text-lg font-bold">
                      {cancelledOrdersCount || 0}
                    </h2>
                  </div>
                </div>

                {/* pending Orders */}
                <div className="flex items-center">
                  <div className="p-2 h-full flex items-center justify-center rounded-md bg-amber-500/20">
                    <GiRoad size={30} className="text-amber-500" />
                  </div>
                  <div className="flex-1 ml-3 h-full">
                    <p className="text-xs font-extralight opacity-60">
                      Pending Orders
                    </p>
                    <h2 className="text-lg font-bold">
                      {pendingOrdersCount || 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="p-2 grid md:grid-cols-2 gap-2 my-5">
                <div>
                  <BarChart
                    data={{
                      labels: ["Completed", "Pending", "Cancelled"],
                      datasets: [
                        {
                          label: "Orders",
                          data: [
                            completedOrdersCount || 0,
                            pendingOrdersCount || 0,
                            cancelledOrdersCount || 0,
                          ],
                          backgroundColor: [
                            "rgb(16 185 129 / 0.2)",
                            "rgb(245 158 11 / 0.2)",
                            "rgb(244 63 94 / 0.2)",
                          ],
                          borderColor: ["#10b981", "#f59e0b", "#f43f5e"],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </section>
        <section className="py-2">
          <div className="flex items-center justify-between max-w-md mx-2 md:mx-auto">
            <button
              type="button"
              onClick={() => setShowSearchCombo(true)}
              className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
            >
              <IoSearchOutline size={20} />
              <p className="text-xs font-extralight ml-2">
                :{filter.find((v) => v.value === selectedFilter)?.title}
              </p>
            </button>
            <SearchFilter
              filter={filter}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          </div>
          <OrdersList range={value} country={country} state={state} />
        </section>
      </div>
      <OrdersSearchCombo
        isOpen={showSearchCombo}
        closeModal={setShowSearchCombo}
        filter={filter.find((v) => v.value === selectedFilter)!}
      />
    </>
  );
}

export default Orders;
