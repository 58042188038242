/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useMemo } from "react";
import { FaCarSide, FaBicycle, FaShuttleVan } from "react-icons/fa";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { IRiderLocation } from "../../interfaces";

interface Props {
  location: IRiderLocation;
  lat?: number;
  lng?: number;
}
function RiderLocationMarker({ location }: Props) {
  const Icon = useMemo(() => {
    switch (location.transportType) {
      case "car":
        return FaCarSide;
      case "bicycle":
        return FaBicycle;
      case "bike":
        return MdOutlineDeliveryDining;
      case "van":
        return FaShuttleVan;
      default:
        return TbTruckDelivery;
    }
  }, []);
  return (
    <div
      className={`p-2 rounded-full border-2 bg-background flex items-center justify-center w-fit ${
        location.online ? "border-success" : "border-error"
      }`}
    >
      <Icon size={20} />
    </div>
  );
}

export default RiderLocationMarker;
