import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IActions } from "../interfaces";
import {
  releaseAdminAction,
  suspendAdminAction,
} from "../redux/actions/admins.actions";
import adminService from "../services/admin.service";
import { handleError } from "../utils/_helper";
import { useAppDispatch } from "./useRedux";

const useAdminDetails = (adminId: string) => {
  const dispatch = useAppDispatch();
  const [adminActions, setAdminActions] = useState<{
    actions: IActions[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    actions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const suspendAdmin = useCallback(async () => {
    try {
      const res = await dispatch(suspendAdminAction(adminId)).unwrap();
      toast.success(res.message);
    } catch (error) {
      console.log(error);
      toast.error(handleError(error));
    }
  }, [adminId, dispatch]);

  const releaseAdmin = useCallback(async () => {
    try {
      const res = await dispatch(releaseAdminAction(adminId)).unwrap();
      toast.success(res.message);
    } catch (error) {
      toast.error(handleError(error));
    }
  }, [adminId, dispatch]);

  const getActions = useCallback(
    async (lv: any) => {
      setAdminActions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await adminService.fetchAdminActions(adminId, lv);
        setAdminActions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          actions: [
            ...prevState.actions.filter(
              (action) => !res.data.find((d) => d.id === action.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        console.log(error);
        setAdminActions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [adminId]
  );

  useEffect(() => {
    if (!adminActions.loaded && !adminActions.actions.length) {
      getActions(adminActions.lastVisible);
    }
  }, [
    getActions,
    adminActions.actions.length,
    adminActions.lastVisible,
    adminActions.loaded,
  ]);

  return {
    adminActions,
    getActions,
    suspendAdmin,
    releaseAdmin,
  };
};

export default useAdminDetails;
