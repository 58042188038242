import React, { PureComponent } from "react";

class Text extends PureComponent<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > & { content: string | JSX.Element }
> {
  render() {
    const { content, className, style, ...rest } = this.props;
    return (
      <p className={`${className}`} style={{ ...style }} {...rest}>
        {content}
      </p>
    );
  }
}

export default Text;
