import axios from "axios";
import httpClient from "../config/HTTPClient";
import { ICreateAdmin } from "../interfaces";
import { ActivitiesType, AdminsType, UserType } from "../types";

class AdminService {
  static async login(loginDetails: {
    email: string;
    password: string;
  }): Promise<
    UserType & { tokens: { refreshToken: string; accessToken: string } }
  > {
    const { data } = await httpClient.post("/auth/login", loginDetails);

    return data;
  }

  static async authenticate(): Promise<UserType> {
    const { data } = await httpClient.get("/auth");
    return data;
  }

  static async logout(): Promise<{ message: string }> {
    const { data } = await httpClient.get("/auth/logout");
    return data;
  }

  static async fetchAdmins(page: number): Promise<AdminsType> {
    const { data } = await httpClient.get(`/api/v1/admins/${page}`);
    return data;
  }

  static async createAdmin(formInput: ICreateAdmin): Promise<UserType> {
    const { data } = await httpClient.post("/api/v1/admin", formInput);
    return data;
  }

  static async fetchActivities(page: number): Promise<ActivitiesType> {
    const { data } = await httpClient.get(`/api/v1/activity/${page}`);
    return data;
  }

  static async suspendAdmin(id: string): Promise<UserType> {
    const { data } = await httpClient.get(`/api/v1/admin/suspend/${id}`);
    return data;
  }

  static async releaseAdmin(id: string): Promise<UserType> {
    const { data } = await httpClient.get(`/api/v1/admin/release/${id}`);
    return data;
  }

  static async requestPasswordChange(): Promise<{
    id: string;
    message: string;
  }> {
    const { data } = await httpClient.get("/api/v1/admin/password");
    return data;
  }

  static async changePassword(
    password: string,
    link: string
  ): Promise<{
    message: string;
  }> {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/password`,
      {
        password,
        link: link || "",
      }
    );
    return data;
  }

  static async forgotPassword(email: string): Promise<{
    message: string;
  }> {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/forgot-password`,
      {
        email,
      }
    );
    return data;
  }
}

export default AdminService;
