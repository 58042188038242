import { Transition, Dialog, Menu } from "@headlessui/react";
import { Fragment, useState } from "react";
import { MdPeople } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import AdminsList from "../components/shared/AdminsList";
import Card from "../components/shared/Card";
import CreateAdminForm from "../components/shared/CreateAdminForm";
import Header from "../components/shared/Header";
import SearchCombo from "../components/shared/SearchCombo";
import SearchFilter from "../components/shared/SearchFilter";
import Text from "../components/shared/Text";
import useAdmins from "../hooks/useAdmins";
import { useAppSelector } from "../hooks/useRedux";
import useSearchFilter from "../hooks/useSearchFilter";
import { selectMetadata } from "../redux/slices/metadataSlice";
import Action from "../components/shared/Action";
import Button from "../components/shared/Button";
import Spinner from "../components/shared/Spinner";
import useCount from "../hooks/useCount";
import { CollectionNames } from "../enums";

function Admins() {
  const { admins } = useAppSelector(selectMetadata);
  const { adminsActions, getActions } = useAdmins();
  const [isOpen, setIsOpen] = useState(false);
  const [showSearchCombo, setShowSearchCombo] = useState(false);
  const { selectedFilter, setSelectedFilter, filter } = useSearchFilter();

  const { data: count } = useCount({
    collectionName: CollectionNames.ADMINS,
    options: [],
    enabled: true,
  });

  return (
    <>
      <div className="h-ful bg-backgroundl">
        <Header>
          <header className="flex items-center justify-between w-full">
            <Text content="Admins" className="text-lg" />

            <Menu as="div" className="relative inline-block">
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-backgroundTint bg-opacity-20 px-4 py-2 text-sm font-medium text-foreground/90 hover:text-foreground">
                  <span className="hidden md:inline-block">Actions</span>
                  <HiOutlineDotsVertical size={20} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-6 mt-2 w-32 origin-top-right divide-y divide-foreground/30 rounded-md bg-backgroundTint shadow-lg focus:outline-none">
                  <div className="py-1 ">
                    <Menu.Item
                      as="button"
                      role="button"
                      className="text-xs p-2 w-full hover:bg-background"
                      onClick={() => setIsOpen(true)}
                    >
                      Create Admin
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </header>
        </Header>
        <div className="md:grid md:grid-cols-3 h-full">
          <section className="overflow-y-scroll md:h-full md:col-span-2">
            {admins ? (
              <div className="p-3">
                <div className="flex items-center justify-between md:hidden mb-3">
                  <button
                    type="button"
                    onClick={() => setShowSearchCombo(true)}
                    className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
                  >
                    <IoSearchOutline size={20} />
                    <p className="text-xs font-extralight ml-2">
                      :{filter.find((v) => v.value === selectedFilter)?.title}
                    </p>
                  </button>
                  <SearchFilter
                    filter={filter}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                </div>
                <div className="flex">
                  <div className="p-2 w-fit h-fit bg-secondary/10 rounded-md">
                    <MdPeople size={20} className="text-secondary" />
                  </div>
                  <div className="ml-3">
                    <span className="text-xs font-extralight opacity-40">
                      Total Admins
                    </span>
                    <p className="text-2xl font-bold">{count || 0}</p>
                  </div>
                </div>
              </div>
            ) : null}
            <AdminsList />
          </section>
          <section className="p-3 overflow-y-scroll ml-2 hidden md:block">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => setShowSearchCombo(true)}
                className="flex flex-1 items-center px-2 py-3 bg-backgroundShade text-foreground/50 rounded-sm cursor-pointer w-full"
              >
                <IoSearchOutline size={20} />
                <p className="text-xs font-extralight ml-2">
                  :{filter.find((v) => v.value === selectedFilter)?.title}
                </p>
              </button>
              <SearchFilter
                filter={filter}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </div>
            <div>
              <h2 className="p-2">Admins Actions</h2>
              {adminsActions.actions.map((action) => (
                <Action action={action} key={action.id} />
              ))}
              {adminsActions.loading ? <Spinner /> : null}

              {adminsActions.loaded &&
              adminsActions.more &&
              !adminsActions.loading ? (
                <Button
                  onClick={() => getActions(adminsActions.lastVisible)}
                  text="Load More"
                  className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                />
              ) : null}
            </div>
          </section>
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIsOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 backdrop-blur-xl" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-xl divide-y divide-foreground/30 transform overflow-hidden rounded-md bg-backgroundTint p-4 text-left align-middle shadow-xl transition-all">
                    <Card className="p-5">
                      <header className="text-center">
                        <Text
                          content="Create an Admin Account"
                          className="text-lg mg:text-xl"
                        />
                      </header>
                      <CreateAdminForm closeModal={setIsOpen} />
                    </Card>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <SearchCombo
        isOpen={showSearchCombo}
        closeModal={setShowSearchCombo}
        type="Admin"
        filter={filter.find((v) => v.value === selectedFilter)!}
      />
    </>
  );
}

export default Admins;
