import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { IoCheckmarkOutline, IoFilterOutline } from "react-icons/io5";

interface Props {
  filter: {
    title: string;
    value: string;
  }[];
  selectedFilter: string;
  setSelectedFilter: any;
}

function SearchFilter({ filter, selectedFilter, setSelectedFilter }: Props) {
  return (
    <Listbox value={selectedFilter} onChange={setSelectedFilter}>
      <div className="relative mt-1">
        <Listbox.Button className="relative cursor-default rounded-lg bg-backgroundShade p-2">
          <IoFilterOutline size={20} />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 divide-y divide-foreground/30 max-h-60 w-56 right-0 rounded-sm bg-backgroundShade p-2 shadow-lg">
            {filter.map(({ title, value }) => (
              <Listbox.Option
                key={title}
                className={({ active }) =>
                  `w-full flex items-center justify-between p-2 text-xs cursor-default select-none ${
                    active ? "bg-background" : "text-foreground"
                  }`
                }
                value={value}
              >
                {({ selected }) => (
                  <>
                    <span className="block flex-1">{title}</span>
                    {selected ? (
                      <span className="text-success">
                        <IoCheckmarkOutline size={20} />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default SearchFilter;
