import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Card from "./Card";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  isOpen: boolean;
  closeModal: (state: boolean) => void;
  closeBtnDissabled: boolean;
  // eslint-disable-next-line react/require-default-props
  title?: string;
};

function Modal({
  children,
  isOpen,
  closeModal,
  closeBtnDissabled,
  title = "",
}: Props) {
  return isOpen ? (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-xl" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl divide-y divide-foreground/30 transform overflow-hidden rounded-md bg-backgroundTint p-4 text-left align-middle shadow-xl transition-all">
                <Card className="p-5">
                  <Dialog.Title
                    as="div"
                    className="flex items-center justify-between mb-3"
                  >
                    <h2 className="text-sm">{title}</h2>
                    <IoCloseOutline
                      size={20}
                      className={`text-foreground ${
                        closeBtnDissabled ? "pointer-events-none" : ""
                      }`}
                      onClick={() => closeModal(false)}
                    />
                  </Dialog.Title>
                  {children}
                </Card>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  ) : null;
}

export default Modal;
