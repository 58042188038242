import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import { getUsersAction, searcUsersAction } from "../actions/users.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  users: IUser[];
  loaded: boolean;
  lastVisible: any;
  more: boolean;
  loading: boolean;
  searchLoading: boolean;
  searchLoaded: boolean;
  range: { startDate: number; endDate: number } | null;
  country: string;
  state: string;
} = {
  users: [],
  loaded: false,
  lastVisible: null,
  more: true,
  loading: false,
  searchLoading: false,
  searchLoaded: false,
  range: null,
  country: "",
  state: "",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => {
      state.users = [];
      state.loaded = false;
      state.lastVisible = null;
      state.more = true;
      state.loading = false;
      state.searchLoading = false;
      state.searchLoaded = false;
      state.range = null;
      state.country = "";
      state.state = "";
    },
    resetSearchUsers: (state) => {
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    updateUsers: (state, action) => {
      state.users = [...state.users, action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction.fulfilled, (state) => {
        state.users = [];
        state.loaded = false;
        state.loading = false;
        state.lastVisible = null;
        state.searchLoading = false;
        state.searchLoaded = false;
        state.range = null;
        state.country = "";
        state.state = "";
      })
      .addCase(getUsersAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersAction.rejected, (state) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getUsersAction.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.lastVisible = action.payload.lv;
        state.more = action.payload.more;
        state.range = action.payload.range;
        state.country = action.payload.country;
        state.state = action.payload.state;
        state.users = [
          ...state.users.filter(
            (user) => !action.payload.data.find((d) => d.id === user.id)
          ),
          ...action.payload.data,
        ];
      })
      .addCase(searcUsersAction.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searcUsersAction.rejected, (state) => {
        state.searchLoading = false;
        state.searchLoaded = true;
      })
      .addCase(searcUsersAction.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchLoaded = true;
        state.users = [
          ...state.users.filter(
            (user) => !action.payload.data.find((d) => d.id === user.id)
          ),
          ...action.payload.data,
        ];
      }),
});

export const { reset, resetSearchUsers, updateUsers } = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
