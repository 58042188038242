import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Country, State } from "country-state-city";
import Button from "./Button";
import Input from "./Input";
import Text from "./Text";
import useCreateAdmin from "../../hooks/useCreateAdmin";
import { AdminPositions } from "../../enums";

interface Props {
  closeModal: (state: boolean) => void;
}

function CreateAdminForm({ closeModal }: Props) {
  const { formikCreateAdmin, setPhoneNumber } = useCreateAdmin(closeModal);
  return (
    <form
      className="mt-10 transition-all"
      onSubmit={formikCreateAdmin.handleSubmit}
    >
      <div className="mb-5">
        <Text content="Firstname" className="text-sm mb-1" />
        <Input
          type="text"
          name="firstName"
          placeholder="Firstname"
          onChange={formikCreateAdmin.handleChange}
          value={formikCreateAdmin.values.firstName}
          className={`${
            formikCreateAdmin.errors.firstName &&
            formikCreateAdmin.touched.firstName
              ? "!border-error"
              : ""
          }`}
        />
        {formikCreateAdmin.errors.firstName &&
        formikCreateAdmin.touched.firstName ? (
          <Text
            content={formikCreateAdmin.errors.firstName}
            className="text-xs mt-1 text-error font-thin"
          />
        ) : null}
      </div>

      <div className="mb-5">
        <Text content="Lastname" className="text-sm mb-1" />
        <Input
          type="text"
          name="lastName"
          placeholder="Lastname"
          onChange={formikCreateAdmin.handleChange}
          value={formikCreateAdmin.values.lastName}
          className={`${
            formikCreateAdmin.errors.lastName &&
            formikCreateAdmin.touched.lastName
              ? "!border-error"
              : ""
          }`}
        />
        {formikCreateAdmin.errors.lastName &&
        formikCreateAdmin.touched.lastName ? (
          <Text
            content={formikCreateAdmin.errors.lastName}
            className="text-xs mt-1 text-error font-thin"
          />
        ) : null}
      </div>

      <div className="mb-5">
        <Text content="Email" className="text-sm mb-1" />
        <Input
          type="email"
          name="email"
          placeholder="Enter your Cuzoo Admin Email Address"
          onChange={formikCreateAdmin.handleChange}
          value={formikCreateAdmin.values.email}
          className={`${
            formikCreateAdmin.errors.email && formikCreateAdmin.touched.email
              ? "!border-error"
              : ""
          }`}
        />
        {formikCreateAdmin.errors.email && formikCreateAdmin.touched.email ? (
          <Text
            content={formikCreateAdmin.errors.email}
            className="text-xs mt-1 text-error font-thin"
          />
        ) : null}
      </div>

      <div className="mb-5">
        <Text content="Phone Number" className="text-sm mb-1" />
        <PhoneInput
          placeholder="8134234567"
          defaultCountry="NG"
          value={formikCreateAdmin.values.phoneNumber}
          onChange={(val: any) => setPhoneNumber(val)}
          numberInputProps={{
            className: `disabled:opacity-50 border-1 border-foreground/30 bg-backgroundShade rounded-sm form-input focus:border-primary text-sm p-3 placeholder-foreground/30 w-full ${
              formikCreateAdmin.errors.phoneNumber &&
              formikCreateAdmin.touched.phoneNumber
                ? "!border-error"
                : ""
            }`,
            style: {
              boxShadow: "none",
            },
          }}
        />
        {formikCreateAdmin.errors.phoneNumber &&
        formikCreateAdmin.touched.phoneNumber ? (
          <p className="text-xs mt-1 text-error font-thin">
            {formikCreateAdmin.errors.phoneNumber}
          </p>
        ) : null}
      </div>

      <div className="mb-5">
        <Text content="Position" className="text-sm mb-1" />
        <select
          className="w-full rounded-md bg-background text-foreground capitalize text-sm"
          placeholder="Select Type"
          name="position"
          value={formikCreateAdmin.values.position}
          onChange={formikCreateAdmin.handleChange}
        >
          <option className="text-foreground capitalize text-sm" value="">
            Select a type
          </option>
          <option
            className="text-foreground capitalize text-sm"
            value={AdminPositions.ADMIN}
          >
            {AdminPositions.ADMIN}
          </option>
          <option
            className="text-foreground capitalize text-sm"
            value={AdminPositions.INVESTOR}
          >
            {AdminPositions.INVESTOR}
          </option>
        </select>
        {formikCreateAdmin.errors.position &&
        formikCreateAdmin.touched.position ? (
          <p className="text-xs mt-1 text-error font-thin">
            {formikCreateAdmin.errors.position}
          </p>
        ) : null}
      </div>

      {formikCreateAdmin.values.position === AdminPositions.INVESTOR ? (
        <>
          <div className="mb-5">
            <Text content="Country" className="text-sm mb-1" />
            <select
              className="w-full rounded-md bg-background text-foreground capitalize text-sm"
              placeholder="Select Type"
              name="country"
              value={formikCreateAdmin.values.country}
              onChange={formikCreateAdmin.handleChange}
            >
              <option className="text-foreground capitalize text-sm" value="">
                Select a country
              </option>
              {Country.getAllCountries().map((country) => (
                <option
                  key={country.isoCode}
                  className="text-foreground capitalize text-sm"
                  value={country.isoCode}
                >
                  {country.flag} {country.name}
                </option>
              ))}
            </select>

            {formikCreateAdmin.errors.country &&
            formikCreateAdmin.touched.country ? (
              <p className="text-xs mt-1 text-error font-thin">
                {formikCreateAdmin.errors.country}
              </p>
            ) : null}
          </div>

          {formikCreateAdmin.values.country ? (
            <div className="mb-5">
              <Text content="State" className="text-sm mb-1" />
              <select
                className="w-full rounded-md bg-background text-foreground capitalize text-sm"
                placeholder="Select Type"
                name="state"
                value={formikCreateAdmin.values.state}
                onChange={formikCreateAdmin.handleChange}
              >
                <option className="text-foreground capitalize text-sm" value="">
                  Select a state
                </option>
                {State.getStatesOfCountry(formikCreateAdmin.values.country).map(
                  (state) => (
                    <option
                      key={state.isoCode}
                      className="text-foreground capitalize text-sm"
                      value={state.isoCode}
                    >
                      {state.name}
                    </option>
                  )
                )}
              </select>

              {formikCreateAdmin.errors.state &&
              formikCreateAdmin.touched.state ? (
                <p className="text-xs mt-1 text-error font-thin">
                  {formikCreateAdmin.errors.state}
                </p>
              ) : null}
            </div>
          ) : null}
        </>
      ) : null}

      <Button
        text={formikCreateAdmin.isSubmitting ? "Please wait..." : "Create"}
        className="w-full disabled:opacity-50"
        disabled={formikCreateAdmin.isSubmitting}
      />
    </form>
  );
}

export default CreateAdminForm;
