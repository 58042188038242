import React, { PureComponent } from "react";
import { MdLock } from "react-icons/md";
import Text from "./Text";

class Error extends PureComponent<{ message?: string }> {
  render() {
    const { message } = this.props;
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <MdLock size={25} className="mb-1" />
        <Text
          content={`${message || "Unauthorized"}`}
          className="text-center text-xs"
        />
      </div>
    );
  }
}

export default Error;
