import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { IActions } from "../interfaces";
import adminService from "../services/admin.service";
import { handleError } from "../utils/_helper";

const useAdmins = () => {
  const [adminsActions, setAdminsActions] = useState<{
    actions: IActions[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    actions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const getActions = useCallback(async (lv: any) => {
    setAdminsActions((prevState) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const res = await adminService.fetchActions(lv);
      setAdminsActions((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        lastVisible: res.lv,
        more: res.more,
        actions: [
          ...prevState.actions.filter(
            (action) => !res.data.find((d) => d.id === action.id)
          ),
          ...res.data,
        ],
      }));
    } catch (error) {
      setAdminsActions((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      toast.error(handleError(error));
    }
  }, []);

  useEffect(() => {
    if (!adminsActions.loaded && !adminsActions.actions.length) {
      getActions(adminsActions.lastVisible);
    }
  }, [
    getActions,
    adminsActions.actions.length,
    adminsActions.lastVisible,
    adminsActions.loaded,
  ]);

  return {
    adminsActions,
    getActions,
  };
};

export default useAdmins;
