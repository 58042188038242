import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import React from "react";
import { IoWalletOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useAppSelector } from "../../hooks/useRedux";
import { selectSettings } from "../../redux/slices/settingsSlice";
import adminService from "../../services/admin.service";
import { handleError } from "../../utils/_helper";

function CompaniesSettings() {
  const { general } = useAppSelector(selectSettings);
  const companiesSettingsForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      suspendCompaniesWalletServives:
        general?.suspendCompaniesWalletServives || false,
    },
    validationSchema: yup.object({
      suspendCompaniesWalletServives: yup.boolean(),
    }),
    onSubmit: async ({ suspendCompaniesWalletServives }, { resetForm }) => {
      try {
        const res = await adminService.updateSettings({
          suspendCompaniesWalletServives,
        });
        resetForm();
        toast.success(res.message);
      } catch (error) {
        toast.error(handleError(error));
      }
    },
  });

  return (
    <div className="mt-2">
      <h2 className="text-sm font-semibold mb-2">Logistics Partners</h2>
      <form
        className="rounded-sm bg-backgroundTint p-3 shadow-sm divide-y divide-foreground/20"
        onSubmit={companiesSettingsForm.handleSubmit}
      >
        <div className="flex items-center justify-between py-2">
          <div className="flex items-center">
            <div className="p-2 h-fit flex items-center justify-center rounded-md bg-backgroundShade">
              <IoWalletOutline size={30} className="text-foreground" />
            </div>
            <div className="ml-3">
              <p className="text-xs opacity-80">Suspend wallet services</p>
              <span className="text-xs opacity-60">
                Partner can&apos;t withdraw from wallet
              </span>
            </div>
          </div>

          <div>
            <Switch
              checked={
                companiesSettingsForm.values.suspendCompaniesWalletServives
              }
              onChange={() =>
                companiesSettingsForm.setFieldValue(
                  "suspendCompaniesWalletServives",
                  !companiesSettingsForm.values.suspendCompaniesWalletServives
                )
              }
              className={`${
                companiesSettingsForm.values.suspendCompaniesWalletServives
                  ? "bg-error"
                  : "bg-backgroundShade"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Suspend wallet Services</span>
              <span
                className={`${
                  companiesSettingsForm.values.suspendCompaniesWalletServives
                    ? "translate-x-6"
                    : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-foreground/80`}
              />
            </Switch>
          </div>
        </div>
        <button
          type="submit"
          disabled={companiesSettingsForm.isSubmitting}
          className="rounded-sm bg-primary text-foreground text-xs py-2 px-6 font-semibold shadow-md mt-2 disabled:opacity-70"
        >
          {companiesSettingsForm.isSubmitting ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
}

export default CompaniesSettings;
