/* eslint-disable import/prefer-default-export */
export enum CuzooAdminRoles {
  ADMIN = 1,
  SUPER_ADMIN = 2,
}

export enum AdminPositions {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  INVESTOR = "INVESTOR",
}

export enum CollectionNames {
  ADMINS = "admins",
  ADMINS_ACTIONS = "adminsActions",
  ORDERS = "orders",
  USERS = "users",
  METADATA = "metadata",
  RIDER_REGISTRATION = "riderRegistration",
  RIDERS = "riders",
  RIDERS_WALLETS = "ridersWallets",
  RIDERS_TRANSACTIONS = "ridersTransactions",
  RIDER_LOCATION = "ridersLocation",
  RIDERS_ACTIONS = "ridersActions",
  RIDER_ANALYTICS = "ridersAnalytics",
  RIDERS_RATINGS = "riderRatings",
  SETTINGS = "settings",
  USERS_ACTIONS = "usersActions",
  USER_WALLET = "usersWallets",
  INDIVIDUAL_USER_ANALYTICS = "individualUserAnalytics",
  INDIVIDUAL_RIDER_ANALYTICS = "individualRiderAnalytics",
  USERS_TRANSACTIONS = "usersTransactions",
  INDIVIDUAL_COMPANY_ANALYTICS = "individualCompanyAnalytics",
  COMPANY_REGISTRATION = "companyRegistration",
  COMPANY_TRANSACTIONS = "companyTransactions",
  COMPANIES = "companies",
  COMPANY_WALLET = "companyWallets",
  COMPANY_WITHDRAWAL_REQUEST = "companyWithdrawalRequest",
  COMPANY_AREAS = "companyAreas",
  RIDER_WITHDRAWAL_REQUEST = "riderWithdrawalRequest",
}

export enum ErrorNames {
  AXIOS_ERROR = "AxiosError",
  FIREBASE_ERROR = "FirebaseError",
}

export enum ExtUrls {
  REQUEST_PASSWORD_RESET = "/accounts/password-reset",
  APPROVE_RIDER = "/admins/rider/approve",
  DECLINE_RIDER = "/admins/rider/decline",
  APPROVE_COMPANY = "admins/company/approve",
  DECLINE_COMPANY = "/admins/company/decline",
  CREATE_ADMIN = "/admins/create",
  UPDATE_SETTINGS = "/admins/settings",
  SUSPEND_ADMIN = "/admins/suspend",
  RELEASE_ADMIN = "/admins/release",
  SUSPEND_RIDER_WALLET = "/wallet/riders/suspend",
  RELEASE_RIDER_WALLET = "/wallet/riders/release",
  SUSPEND_COMPANY_WALLET = "/wallet/companies/suspend",
  RELEASE_COMPANY_WALLET = "/wallet/companies/release",
  APPROVE_WITHDRAWAL = "/admins/withdrawal/approve",
  CANCEL_WITHDRAWAL = "/admins/withdrawal/decline",
}

export enum Info {
  rt = "__rt",
  at = "__at",
  tt = 120000,
}
