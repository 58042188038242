import { useFormik } from "formik";
import { useCallback } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import * as yup from "yup";
import adminService from "../services/admin.service";
import { handleError } from "../utils/_helper";
import { AdminPositions } from "../enums";

const useCreateAdmin = (closeModal: (state: boolean) => void) => {
  const formikCreateAdmin = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      position: "",
      country: "",
      state: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .lowercase()
        .email("Invalid Email Address")
        .required("Email is Required")
        .test("Email", "Invalid Email Address", (val) => {
          if (val && val.endsWith("@cuzooapp.com")) {
            return true;
          }

          return false;
        }),
      firstName: yup.string().trim().required("Required."),
      lastName: yup.string().trim().required("Required."),
      phoneNumber: yup
        .string()
        .required("Required")
        .test("Valid Pnone number", "Invalid Phone number", (val) => {
          return !!(val && validator.isMobilePhone(val, "en-NG"));
        }),
      position: yup
        .string()
        .oneOf([AdminPositions.ADMIN, AdminPositions.INVESTOR]),

      country: yup.string().when("position", {
        is: AdminPositions.INVESTOR,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.optional(),
      }),

      state: yup.string().when("position", {
        is: AdminPositions.INVESTOR,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.optional(),
      }),
    }),

    onSubmit: async (
      { firstName, lastName, email, phoneNumber, position, country, state },
      { resetForm }
    ) => {
      try {
        const res = await adminService.createAdmin({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim().toLowerCase(),
          phoneNumber,
          position: position as AdminPositions,
          country,
          state,
        });

        resetForm();
        toast.success(res.message);
        closeModal(false);
      } catch (error: any) {
        toast.error(handleError(error));
      }
    },
  });

  const setPhoneNumber = useCallback(
    (val: string) => {
      formikCreateAdmin.setFieldValue("phoneNumber", val || "", true);
    },
    [formikCreateAdmin]
  );

  return {
    formikCreateAdmin,
    setPhoneNumber,
  };
};

export default useCreateAdmin;
