/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { IoStopSharp } from "react-icons/io5";

interface Props {
  address: string;
  delivered: boolean;
  cancelled?: boolean;
  lat?: number;
  lng?: number;
}
function DestinationMarker({ address, delivered, cancelled }: Props) {
  return (
    <div
      className={`p-2 rounded-full bg-white flex items-center justify-center w-fit ${
        // eslint-disable-next-line no-nested-ternary
        cancelled ? "ring ring-error" : delivered ? "ring ring-success" : "ring"
      }`}
      title={address}
    >
      <IoStopSharp size={20} className="text-primary" />
    </div>
  );
}

export default DestinationMarker;
