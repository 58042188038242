import { DocumentData, Query } from "firebase/firestore";
import {
  onSnapshot,
  db,
  doc,
  query,
  collection,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getDoc,
  where,
} from "../config/fb";
import { CollectionNames } from "../enums";
import {
  IActions,
  IIndividualUserAnalytics,
  ITransaction,
  IUser,
  IWallet,
} from "../interfaces";

const userService = {
  watchWallet: (userId: string, updateWallet: (d: IWallet | null) => void) => {
    if (!userId) {
      updateWallet(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.USER_WALLET, userId),
      (walletDoc) => {
        const data = {
          id: walletDoc.id,
          ...walletDoc.data(),
        };
        updateWallet(data as IWallet);
      }
    );
  },

  getSingleUser: async (userId: string): Promise<IUser | null> => {
    if (!userId) {
      return null;
    }

    const snapshot = await getDoc(doc(db, CollectionNames.USERS, userId));

    if (!snapshot.exists()) {
      return null;
    }

    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as IUser;
  },

  fetchAnalytics: (
    userId: string,
    updateAnalytics: (d: IIndividualUserAnalytics | null) => void
  ) => {
    if (!userId) {
      updateAnalytics(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.INDIVIDUAL_USER_ANALYTICS, userId),
      (analyticsDoc) => {
        if (!analyticsDoc.exists()) {
          updateAnalytics(null);
        } else {
          const data = {
            id: analyticsDoc.id,
            ...analyticsDoc.data(),
          };
          updateAnalytics(data as IIndividualUserAnalytics);
        }
      }
    );
  },

  fetchTransactions: async (
    userId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: ITransaction[]; more: boolean }> => {
    if (!userId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.USERS_TRANSACTIONS),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.USERS_TRANSACTIONS),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: ITransaction[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  fetchActions: async (
    userId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: IActions[]; more: boolean }> => {
    if (!userId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.USERS_ACTIONS),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.USERS_ACTIONS),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IActions[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },
};

export default userService;
