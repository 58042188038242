import React from "react";
import { IoBusinessOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Card from "../components/shared/Card";
import Header from "../components/shared/Header";
import Text from "../components/shared/Text";
import { useAppSelector } from "../hooks/useRedux";
import { selectCompaniesRegistration } from "../redux/slices/companiesRegistrationSlice";

function CompanyRegistration() {
  const { pendingRegistration } = useAppSelector(selectCompaniesRegistration);

  return (
    <div className="h-full">
      <Header>
        <Text
          content="Pending Logistics Company Registration"
          className="text-sm"
        />
      </Header>
      {pendingRegistration.length ? (
        <div className="p-3 grid md:grid-cols-2 lg:grid-cols-4 gap-2">
          {pendingRegistration.map((company) => (
            <Link to={`/company-registration/${company.id}`}>
              <Card key={company.id}>
                <div className="flex items-center">
                  <div className="p-3 bg-backgroundShade">
                    <IoBusinessOutline size={30} className="text-foreground" />
                  </div>
                  <div className="px-4">
                    <p className="text-sm capitalize font-regular flex-1">
                      {company.businessName}
                    </p>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </div>
      ) : (
        <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
          No Pending Registration
        </div>
      )}
    </div>
  );
}

export default CompanyRegistration;
