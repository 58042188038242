import { createSlice } from "@reduxjs/toolkit";
import { ActivitiesType } from "../../types";
import { getActivitiesAction } from "../actions/activity.actions";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: ActivitiesType & {
  loading: boolean;
  error: { message: string } | null;
} = {
  results: [],
  page: 0,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  error: null,
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    reset: (state) => {
      state.results = [];
      state.page = 0;
      state.totalPages = 0;
      state.totalResults = 0;
      state.loading = false;
      state.error = null;
    },
    nextPage: (state) => {
      state.page += 1;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction.fulfilled, (state) => {
        state.results = [];
        state.page = 0;
        state.totalPages = 0;
        state.totalResults = 0;
        state.loading = false;
        state.error = null;
      })
      .addCase(getActivitiesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActivitiesAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as { message: string };
        }
      })
      .addCase(getActivitiesAction.fulfilled, (state, action) => {
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
        if (action.payload.page === 1) {
          state.results = [...action.payload.results];
        } else {
          state.results = [...state.results, ...action.payload.results];
        }
        state.error = null;
        state.loading = false;
      }),
});

export const { reset, nextPage } = activitiesSlice.actions;
export const selectActivities = (state: RootState) => state.activities;

export default activitiesSlice.reducer;
