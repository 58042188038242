import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  IoMailSharp,
  IoCallSharp,
  IoLocationSharp,
  IoPersonOutline,
  IoMailOutline,
  IoCallOutline,
  IoLocationOutline,
  IoTransgenderOutline,
  IoCarSportOutline,
  IoAttachOutline,
} from "react-icons/io5";
import Select from "react-select";
import Card from "../components/shared/Card";
import { useAppSelector } from "../hooks/useRedux";
import { selectRiderRegistration } from "../redux/slices/riderRegistrationSlice";
import Button from "../components/shared/Button";
import Modal from "../components/shared/Modal";
import useRiderRegistration from "../hooks/useRiderRegistration";
import Text from "../components/shared/Text";
import TextArea from "../components/shared/TextArea";
import Header from "../components/shared/Header";
import { getReason } from "../utils/_helper";

function RegistrationDetails() {
  const params = useParams();
  const { pendingRegistration } = useAppSelector(selectRiderRegistration);

  const rider = useMemo(
    () => pendingRegistration.find((v) => v.id === params.id),
    [params.id, pendingRegistration]
  );

  const {
    declineFrom,
    showApproveRegistration,
    setShowApproveRegistration,
    showDeclineeRegistration,
    setShowDeclineeRegistration,
    handleSelectForm3Multiple,
    approveForm,
    transportTypes,
  } = useRiderRegistration(rider?.id!, rider?.vehicle!);

  return (
    <div className="h-full">
      <Header>
        <Text content="Riders Details" className="text-lg" />
      </Header>
      {rider ? (
        <div className="p-2 grid grid-cols-1 gap-3">
          <Card className="p-3 w-full md:w-2/3 lg:1/2 mx-auto">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="w-10 h-10 rounded-md border border-foreground/30 mr-2"
                  style={{
                    background: `url(${rider?.passport?.url})`,
                    backgroundSize: "cover",
                  }}
                />
                <div>
                  <h2 className="text-lg font-bold">
                    {rider?.firstName} {rider?.lastName}
                  </h2>
                  <div className="grid grid-cols-3 mt-2 gap-2">
                    <a
                      href={`mailto:${rider?.email}`}
                      className="text-xs rounded-md flex items-center justify-center bg-background"
                    >
                      <IoMailSharp size={15} className="text-foreground/80" />
                    </a>

                    <a
                      href={`tel:${rider?.phoneNumber?.number}`}
                      className="text-xs rounded-md flex items-center justify-center bg-background"
                    >
                      <IoCallSharp size={15} className="text-foreground/80" />
                    </a>

                    {rider?.address?.geometry?.location ? (
                      <a
                        target="blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${rider?.address.geometry.location.lat},${rider?.address.geometry.location.lng}&query_place_id=${rider?.address.place_id}`}
                        className="text-xs rounded-md flex items-center justify-center bg-background"
                      >
                        <IoLocationSharp
                          size={15}
                          className="text-foreground/80"
                        />
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
              <p className="text-xs">{rider.createdAt}</p>
            </div>
          </Card>
          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Personal Details
            </h2>
            <div className="text-sm divide-y divide-foreground/30">
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoPersonOutline size={15} />
                  <p className="ml-2">{rider?.firstName}</p>
                </div>

                <p className="text-xs opacity-60">First Name</p>
              </div>
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoPersonOutline size={15} />
                  <p className="ml-2">{rider?.lastName}</p>
                </div>

                <p className="text-xs opacity-60">Last Name</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoTransgenderOutline size={15} />
                  <p className="ml-2">{rider?.gender}</p>
                </div>

                <p className="text-xs opacity-60">Gender</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoMailOutline size={15} />
                  <p className="ml-2">{rider?.email}</p>
                </div>

                <p className="text-xs opacity-60">Email</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoCallOutline size={15} />
                  <p className="ml-2">{rider?.phoneNumber?.nationalFormat}</p>
                </div>

                <p className="text-xs opacity-60">Phone Number</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoLocationOutline size={15} />
                  <p className="ml-2">{rider?.address?.formatted_address}</p>
                </div>

                <p className="text-xs opacity-60">Address</p>
              </div>
            </div>
          </Card>

          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Gaurantor Details
            </h2>
            <div className="text-sm divide-y divide-foreground/30">
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoPersonOutline size={15} />
                  <p className="ml-2">{rider?.gaurantor?.firstName}</p>
                </div>

                <p className="text-xs opacity-60">First Name</p>
              </div>
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoPersonOutline size={15} />
                  <p className="ml-2">{rider.gaurantor?.lastName}</p>
                </div>

                <p className="text-xs opacity-60">Last Name</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoTransgenderOutline size={15} />
                  <p className="ml-2">{rider.gaurantor?.gender}</p>
                </div>

                <p className="text-xs opacity-60">Gender</p>
              </div>

              {rider.gaurantor?.email ? (
                <div className="p-3 flex items-center justify-between">
                  <div className="flex items-center">
                    <IoMailOutline size={15} />
                    <p className="ml-2">{rider.gaurantor?.email}</p>
                  </div>

                  <p className="text-xs opacity-60">Email</p>
                </div>
              ) : null}

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoCallOutline size={15} />
                  <p className="ml-2">{rider.gaurantor?.phoneNumber?.number}</p>
                </div>

                <p className="text-xs opacity-60">Phone Number</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoLocationOutline size={15} />
                  <p className="ml-2">
                    {rider.gaurantor?.address?.formatted_address}
                  </p>
                </div>

                <p className="text-xs opacity-60">Address</p>
              </div>
            </div>
          </Card>

          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Vehicle Details
            </h2>
            <div className="text-sm divide-y divide-foreground/30">
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoCarSportOutline size={15} />
                  <p className="ml-2">
                    {rider.vehicle?.hasVehicle
                      ? rider.vehicle?.vehicles?.join()
                      : "This rider does not have a vehicle"}
                  </p>
                </div>

                <p className="text-xs opacity-60">Vehicle(s)</p>
              </div>
            </div>
          </Card>

          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Documents
            </h2>
            <div className="grid gap-2 md:grid-cols-2 overflow-hidden mb-5">
              {rider.gaurantorForm ? (
                <a
                  href={rider.gaurantorForm?.url}
                  target="_blank"
                  className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                  rel="noreferrer"
                >
                  <IoAttachOutline size={25} />

                  <p className="pl-2 text-sm">Gaurantor Form</p>
                </a>
              ) : null}

              {rider.kycForm ? (
                <a
                  href={rider.kycForm.url}
                  target="_blank"
                  className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                  rel="noreferrer"
                >
                  <IoAttachOutline size={25} />
                  <p className="pl-2 text-sm">KYC Form</p>
                </a>
              ) : null}
              {rider.partnershipForm ? (
                <a
                  href={rider.partnershipForm.url}
                  target="_blank"
                  className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                  rel="noreferrer"
                >
                  <IoAttachOutline size={25} />
                  <p className="pl-2 text-sm">Partnership Form</p>
                </a>
              ) : null}
            </div>

            <div className="p-3 text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-2">
              {rider.passport ? (
                <a
                  href={rider.passport?.url}
                  className="block rounded-md"
                  target="blank"
                >
                  <img
                    className="w-full h-36 object-cover rounded-sm"
                    src={rider.passport.url}
                    alt="Passport"
                  />
                  <p className="font-medium text-center p-2 bg-background rounded-b-md">
                    Passport
                  </p>
                </a>
              ) : null}

              {rider.nationalId ? (
                <a
                  href={rider.nationalId.url}
                  className="block rounded-md"
                  target="blank"
                >
                  <img
                    className="w-full h-36 object-cover rounded-sm"
                    src={rider.nationalId.url}
                    alt="NIN"
                  />
                  <p className="font-medium text-center p-2 bg-background rounded-b-md">
                    National ID (NIN)
                  </p>
                </a>
              ) : null}

              {rider.riderCard ? (
                <a
                  href={rider.riderCard.url}
                  className="block rounded-md"
                  target="blank"
                >
                  <img
                    className="w-full h-36 object-cover rounded-sm"
                    src={rider.riderCard.url}
                    alt="Rider Card"
                  />
                  <p className="font-medium text-center p-2 bg-background rounded-b-md">
                    Rider Card
                  </p>
                </a>
              ) : null}
              {rider.nepaBill ? (
                <a
                  href={rider.nepaBill.url}
                  className="block rounded-md"
                  target="blank"
                >
                  <img
                    className="w-full h-36 object-cover rounded-sm"
                    src={rider.nepaBill.url}
                    alt="NEPA Bill"
                  />
                  <p className="font-medium text-center p-2 bg-background rounded-b-md">
                    NEPA Bill
                  </p>
                </a>
              ) : null}
            </div>
          </Card>

          {rider.regComplete ? (
            <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
              <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
                Actions
              </h2>
              <div className="flex items-center p-3">
                <Button
                  text="Approve"
                  className=" !text-foreground"
                  onClick={() => setShowApproveRegistration(true)}
                />
                <Button
                  text="Decline"
                  className="ml-2 !bg-error !text-foreground"
                  onClick={() => setShowDeclineeRegistration(true)}
                />
              </div>
            </Card>
          ) : (
            <div className="mx-auto rounded-sm w-full md:w-2/3 lg:1/2 bg-background/70">
              <h3 className="text-xl font-semibold">Incomplete Registration</h3>
              <p className="text-sm p-2">
                This rider has not uploaded all required information
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
          Not Found
        </div>
      )}

      <Modal
        isOpen={showDeclineeRegistration}
        closeModal={setShowDeclineeRegistration}
        closeBtnDissabled={declineFrom.isSubmitting}
        title="Please state reasons for this decline"
      >
        <form className="transition-all" onSubmit={declineFrom.handleSubmit}>
          <div className="mb-5">
            {declineFrom.values.reasons.map((reason, i) => (
              <div key={`reason-${reason.name}`}>
                <TextArea
                  name={`reasons.${i}.message`}
                  placeholder={getReason(reason.name)}
                  onChange={declineFrom.handleChange}
                  value={declineFrom.values.reasons[i].message}
                  className={`bg-backgroundShade ${
                    (declineFrom.errors.reasons as any)?.[i].message &&
                    (declineFrom.touched.reasons as any)?.[i].message
                      ? "!border-error"
                      : ""
                  }`}
                />
                {(declineFrom.errors.reasons as any)?.[i].message &&
                (declineFrom.touched.reasons as any)?.[i].message ? (
                  <Text
                    content={(declineFrom.errors.reasons as any)?.[i].message}
                    className="text-xs mt-1 text-error font-thin"
                  />
                ) : null}
              </div>
            ))}
          </div>

          <Button
            text={declineFrom.isSubmitting ? "Please wait..." : "Decline"}
            className="w-full !bg-error text-foreground disabled:opacity-50 "
            disabled={declineFrom.isSubmitting}
          />
        </form>
      </Modal>

      <Modal
        isOpen={showApproveRegistration}
        closeModal={setShowApproveRegistration}
        closeBtnDissabled={approveForm.isSubmitting}
        title={` Please be sure that you have properly vetted ${rider?.firstName} before approving.`}
      >
        <form className="transition-all" onSubmit={approveForm.handleSubmit}>
          {!rider?.vehicle?.vehicles.length ? (
            <div className="mb-5">
              <p className="text-sm mb-1">Select vehicle(s)</p>
              <Select
                value={approveForm.values.vehicles.map((v: string) => ({
                  value: v,
                  label: v?.toUpperCase(),
                }))}
                className="test-xs"
                isMulti
                styles={{
                  container: (provided) => ({
                    ...provided,
                    fontSize: "14px",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#242424",
                    primary: "#4d37b3",
                    neutral0: "#171717",
                    neutral80: "#e2e2e2",
                  },
                })}
                onChange={(value) => handleSelectForm3Multiple(value)}
                options={transportTypes.map((v) => ({
                  value: v,
                  label: v.toUpperCase(),
                }))}
              />
              {approveForm.errors.vehicles && approveForm.touched.vehicles ? (
                <p className="text-xs mt-1 text-error font-thin">
                  {approveForm.errors.vehicles}
                </p>
              ) : null}
            </div>
          ) : null}

          <Button
            text={approveForm.isSubmitting ? "Please wait..." : "Approve"}
            className="w-full text-white disabled:opacity-50 "
            disabled={approveForm.isSubmitting}
          />
        </form>
      </Modal>
    </div>
  );
}

export default RegistrationDetails;
