import dayjs from "dayjs";
import React from "react";
import { IActions } from "../../interfaces";

interface Props {
  action: IActions;
}

function Action({ action }: Props) {
  return (
    <div className="bg-backgroundTint rounded-md shadow-md my-1 p-2">
      <div className="flex items-center py-2">
        <div className="text-xs font-semibold w-10 h-10 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
          <p>
            {action.firstName[0].toUpperCase() +
              action.lastName[0].toUpperCase()}
          </p>
        </div>
        <div className="ml-3">
          <h3 className="text-xs">
            {action.firstName} {action.lastName}
          </h3>
          <p className="text-xs opacity-70">{action.email}</p>
        </div>
      </div>
      <div className="py-2">
        <p className="text-sm">{action.action}</p>
        <span className="text-xs opacity-50">
          {dayjs(action.createdAt?.toDate()).format("DD/MM/YYYY - hh:mma")}
        </span>
      </div>
    </div>
  );
}

export default Action;
