import React from "react";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { Link } from "react-router-dom";
import Button from "./Button";
import Input from "./Input";
import Text from "./Text";
import useLogin from "../../hooks/useLogin";

function LoginForm() {
  const { setIspasswordVisible, isPasswordVisible, formikLogin } = useLogin();
  return (
    <form className="mt-10 transition-all" onSubmit={formikLogin.handleSubmit}>
      <div className="mb-5">
        <Text content="Email" className="text-sm mb-1" />
        <Input
          type="email"
          name="email"
          placeholder="Enter your Cuzoo Admin Email Address"
          onChange={formikLogin.handleChange}
          value={formikLogin.values.email}
          className={`${
            formikLogin.errors.email && formikLogin.touched.email
              ? "!border-error"
              : ""
          }`}
        />
        {formikLogin.errors.email && formikLogin.touched.email ? (
          <Text
            content={formikLogin.errors.email}
            className="text-xs mt-1 text-error font-thin"
          />
        ) : null}
      </div>

      <div className="mb-5">
        <div className="flex items-center justify-between">
          <Text content="Password" className="text-sm mb-1" />
          <div className="flex items-center cursor-pointer text-gray-500">
            <Link to="/forgot-password" className="text-xs mr-1 mb-1 font-thin">
              Forgot Password?
            </Link>
          </div>
        </div>
        <div className="relative">
          <Input
            type={isPasswordVisible ? "text" : "password"}
            name="password"
            placeholder="Enter your password"
            onChange={formikLogin.handleChange}
            value={formikLogin.values.password}
            className={`${
              formikLogin.errors.password && formikLogin.touched.password
                ? "!border-error"
                : ""
            }`}
          />
          <div
            className="absolute right-2 top-2 cursor-pointer"
            onClick={() => setIspasswordVisible((prevState) => !prevState)}
            role="none"
          >
            {isPasswordVisible ? (
              <IoMdEye size={25} />
            ) : (
              <IoMdEyeOff size={25} />
            )}
          </div>
        </div>
        {formikLogin.errors.password && formikLogin.touched.password ? (
          <Text
            content={formikLogin.errors.password}
            className="text-xs mt-1 text-error font-thin"
          />
        ) : null}
      </div>

      <Button
        text={formikLogin.isSubmitting ? "Please wait..." : "Login"}
        className="w-full disabled:opacity-50"
        disabled={formikLogin.isSubmitting}
      />
    </form>
  );
}

export default LoginForm;
