/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { IoDiscSharp } from "react-icons/io5";

interface Props {
  address: string;
  lat?: number;
  lng?: number;
}
function PickupMarker({ address }: Props) {
  return (
    <div
      className="p-2 rounded-full border-2 bg-white flex items-center justify-center w-fit"
      title={address}
    >
      <IoDiscSharp size={20} className="text-primary" />
    </div>
  );
}

export default PickupMarker;
