import React, { PureComponent } from "react";
// import Activity from "../components/shared/Activity";
// import Charts from "../components/shared/Charts";
import Header from "../components/shared/Header";
// import RevenueCard from "../components/shared/RevenueCard";
import Text from "../components/shared/Text";
import TodaySummary from "../components/shared/TodaySummary";
import { useAppSelector } from "../hooks/useRedux";
import { selectAuth } from "../redux/slices/authSlice";
import { AdminPositions } from "../enums";
import Financials from "./Financials";

function InvFinance() {
  const { user } = useAppSelector(selectAuth);

  if (user?.position !== AdminPositions.INVESTOR) return null;

  return <Financials />;
}

class Dashboard extends PureComponent {
  render() {
    return (
      <div className="h-full">
        <Header>
          <Text content="Dashboard" className="text-lg" />
        </Header>
        <div className="py-2 px-3 flex h-full">
          <section className="flex-1 overflow-y-scroll h-full">
            <TodaySummary />
            {/* <Charts /> */}
            <InvFinance />
          </section>
        </div>
      </div>
    );
  }
}

export default Dashboard;
