import { useFormik } from "formik";
import React from "react";
import { AiOutlinePercentage } from "react-icons/ai";
import { RiTruckFill } from "react-icons/ri";
import { BsBicycle } from "react-icons/bs";
import { FaShuttleVan } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";
import { MdDeliveryDining } from "react-icons/md";
import { TbCurrencyNaira } from "react-icons/tb";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useAppSelector } from "../../hooks/useRedux";
import { selectSettings } from "../../redux/slices/settingsSlice";
import adminService from "../../services/admin.service";
import { handleError } from "../../utils/_helper";
import { selectAuth } from "../../redux/slices/authSlice";
import { AdminPositions } from "../../enums";

function FinancialSettings() {
  const { user } = useAppSelector(selectAuth);
  const { general } = useAppSelector(selectSettings);
  const financialSettingsForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      cuzooPercentage: general?.cuzooPercentage || 20,
      cuzooCompanyPercentage: general?.cuzooCompanyPercentage || 20,
      car: general?.pricing?.car || 10,
      bike: general?.pricing?.bike || 10,
      bicycle: general?.pricing?.bicycle || 10,
      van: general?.pricing?.van || 10,
      truck: general?.pricing?.truck || 10,
    },
    validationSchema: yup.object({
      cuzooPercentage: yup
        .number()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .max(100, "Minimum value is 100")
        .required("Required."),
      cuzooCompanyPercentage: yup
        .number()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .max(100, "Minimum value is 100")
        .required("Required."),
      car: yup
        .number()
        .integer()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .required("Required."),
      bike: yup
        .number()
        .integer()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .required("Required."),
      bicycle: yup
        .number()
        .integer()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .required("Required."),
      van: yup
        .number()
        .integer()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .required("Required."),
      truck: yup
        .number()
        .integer()
        .positive("Must be a positive number")
        .min(1, "Minimum value is 1")
        .required("Required."),
      suspendAllWalletServices: yup.boolean(),
    }),
    onSubmit: async (
      {
        cuzooPercentage,
        cuzooCompanyPercentage,
        car,
        van,
        truck,
        bicycle,
        bike,
      },
      { resetForm }
    ) => {
      try {
        const res = await adminService.updateSettings({
          cuzooPercentage: Number(cuzooPercentage),
          cuzooCompanyPercentage: Number(cuzooCompanyPercentage),
          pricing: {
            car: Number(car),
            van: Number(van),
            truck: Number(truck),
            bicycle: Number(bicycle),
            bike: Number(bike),
          },
        });
        resetForm();
        toast.success(res.message);
      } catch (error) {
        toast.error(handleError(error));
      }
    },
  });

  return (
    <div className="mt-2">
      <h2 className="text-sm font-semibold mb-2">Financials</h2>
      <form
        className="rounded-sm bg-backgroundTint p-3 shadow-sm divide-y divide-foreground/20"
        onSubmit={financialSettingsForm.handleSubmit}
      >
        {user?.position !== AdminPositions.INVESTOR ? (
          <>
            <div className="py-2">
              <p className="text-xs mb-2 opacity-80">
                Cuzoo commission for partners -{" "}
                <span className="font-bold text-xs uppercase">logistics</span>
              </p>
              <div className="flex">
                <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                  <AiOutlinePercentage size={15} className="text-foreground" />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="cuzooCompanyPercentage"
                    value={financialSettingsForm.values.cuzooCompanyPercentage}
                    onChange={financialSettingsForm.handleChange}
                    className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                      financialSettingsForm.touched.cuzooCompanyPercentage &&
                      financialSettingsForm.errors.cuzooCompanyPercentage
                        ? "ring-1 ring-error"
                        : ""
                    }`}
                  />
                  {financialSettingsForm.touched.cuzooCompanyPercentage &&
                  financialSettingsForm.errors.cuzooCompanyPercentage ? (
                    <p className="mt-2 text-xs text-error font-extralight">
                      {financialSettingsForm.errors.cuzooCompanyPercentage}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="py-2">
              <p className="text-xs mb-2 opacity-80">
                Cuzoo commission for riders
              </p>
              <div className="flex">
                <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                  <AiOutlinePercentage size={15} className="text-foreground" />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="cuzooPercentage"
                    value={financialSettingsForm.values.cuzooPercentage}
                    onChange={financialSettingsForm.handleChange}
                    className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                      financialSettingsForm.touched.cuzooPercentage &&
                      financialSettingsForm.errors.cuzooPercentage
                        ? "ring-1 ring-error"
                        : ""
                    }`}
                  />
                  {financialSettingsForm.touched.cuzooPercentage &&
                  financialSettingsForm.errors.cuzooPercentage ? (
                    <p className="mt-2 text-xs text-error font-extralight">
                      {financialSettingsForm.errors.cuzooPercentage}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="py-2">
          <h2 className="text-xs opacity-80">Charge per km for delivery</h2>
          <div className="grid grid-cols-1 gap-2 py-2">
            <div className="flex justify-between">
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <TbCurrencyNaira size={15} className="text-foreground" />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  name="car"
                  value={financialSettingsForm.values.car}
                  onChange={financialSettingsForm.handleChange}
                  className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                    financialSettingsForm.touched.car &&
                    financialSettingsForm.errors.car
                      ? "ring-1 ring-error"
                      : ""
                  }`}
                />
                {financialSettingsForm.touched.car &&
                financialSettingsForm.errors.car ? (
                  <p className="mt-2 text-xs text-error font-extralight">
                    {financialSettingsForm.errors.car}
                  </p>
                ) : null}
              </div>
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <IoCarSport size={15} className="text-foreground" />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <TbCurrencyNaira size={15} className="text-foreground" />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  name="bicycle"
                  value={financialSettingsForm.values.bicycle}
                  onChange={financialSettingsForm.handleChange}
                  className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                    financialSettingsForm.touched.bicycle &&
                    financialSettingsForm.errors.bicycle
                      ? "ring-1 ring-error"
                      : ""
                  }`}
                />
                {financialSettingsForm.touched.bicycle &&
                financialSettingsForm.errors.bicycle ? (
                  <p className="mt-2 text-xs text-error font-extralight">
                    {financialSettingsForm.errors.bicycle}
                  </p>
                ) : null}
              </div>
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <BsBicycle size={15} className="text-foreground" />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <TbCurrencyNaira size={15} className="text-foreground" />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  name="bike"
                  value={financialSettingsForm.values.bike}
                  onChange={financialSettingsForm.handleChange}
                  className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                    financialSettingsForm.touched.bike &&
                    financialSettingsForm.errors.bike
                      ? "ring-1 ring-error"
                      : ""
                  }`}
                />
                {financialSettingsForm.touched.bike &&
                financialSettingsForm.errors.bike ? (
                  <p className="mt-2 text-xs text-error font-extralight">
                    {financialSettingsForm.errors.bike}
                  </p>
                ) : null}
              </div>
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <MdDeliveryDining size={15} className="text-foreground" />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <TbCurrencyNaira size={15} className="text-foreground" />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  name="van"
                  value={financialSettingsForm.values.van}
                  onChange={financialSettingsForm.handleChange}
                  className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                    financialSettingsForm.touched.van &&
                    financialSettingsForm.errors.van
                      ? "ring-1 ring-error"
                      : ""
                  }`}
                />
                {financialSettingsForm.touched.van &&
                financialSettingsForm.errors.van ? (
                  <p className="mt-2 text-xs text-error font-extralight">
                    {financialSettingsForm.errors.van}
                  </p>
                ) : null}
              </div>
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <FaShuttleVan size={15} className="text-foreground" />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <TbCurrencyNaira size={15} className="text-foreground" />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  name="truck"
                  value={financialSettingsForm.values.truck}
                  onChange={financialSettingsForm.handleChange}
                  className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                    financialSettingsForm.touched.truck &&
                    financialSettingsForm.errors.truck
                      ? "ring-1 ring-error"
                      : ""
                  }`}
                />
                {financialSettingsForm.touched.truck &&
                financialSettingsForm.errors.truck ? (
                  <p className="mt-2 text-xs text-error font-extralight">
                    {financialSettingsForm.errors.truck}
                  </p>
                ) : null}
              </div>
              <div className="p-2 h-fit flex items-center justify-center bg-backgroundTint">
                <RiTruckFill size={15} className="text-foreground" />
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={financialSettingsForm.isSubmitting}
          className="rounded-sm bg-primary text-foreground text-xs py-2 px-6 font-semibold shadow-md mt-2 disabled:opacity-70"
        >
          {financialSettingsForm.isSubmitting ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
}

export default FinancialSettings;
