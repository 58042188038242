import { IconType } from "react-icons";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import Text from "./Text";

interface NavLinkProps extends LinkProps {
  text: string;
  Icon: IconType;
  open: boolean;
  // eslint-disable-next-line react/require-default-props
  badge?: number | null;
}

function NavLink({ Icon, text, open, to, badge, ...props }: NavLinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div
      className={`my-2 py-2 px-4 rounded-sm ${
        match ? "bg-primary" : "hover:bg-background"
      }`}
      title={text}
    >
      <Link className="flex items-center justify-between" to={to} {...props}>
        <Icon size={20} />

        <div className="flex items-center flex-1">
          <Text
            content={text}
            className={`text-xs ml-2 transition-all flex-1 ${
              open ? "block" : "hidden"
            }`}
          />
          {badge ? (
            <div className="w-6 h-6 rounded-full bg-error flex items-center justify-center ml-2">
              <p className="text-foreground text-xs font-bold">
                {badge > 99 ? 99 : badge}
              </p>
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  );
}

export default NavLink;
