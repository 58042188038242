/* eslint-disable no-nested-ternary */
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import StarRatings from "react-star-ratings";
import { GiCardPickup, GiFullMotorcycleHelmet, GiRoad } from "react-icons/gi";
import { HiOutlineUser } from "react-icons/hi";
import {
  IoArrowBackOutline,
  IoCall,
  IoCheckmarkDoneOutline,
  IoMail,
  IoMapOutline,
} from "react-icons/io5";
import {
  MdLocationPin,
  MdOutlineCancelPresentation,
  MdOutlinePending,
} from "react-icons/md";
import GoogleMapReact from "google-map-react";
import { TbCurrencyNaira } from "react-icons/tb";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Header from "../components/shared/Header";
import Spinner from "../components/shared/Spinner";
import useOrderDetails from "../hooks/useOrderDetails";
import PickupMarker from "../components/shared/PickupMarker";
import DestinationMarker from "../components/shared/DestinationMarker";
import RiderLocationMarker from "../components/shared/RiderLocationMarker";
import { moneyToNaira } from "../utils/_helper";

function OrderDetails() {
  const { order, navigate, paymentType, status, rating, riderLocation } =
    useOrderDetails();

  const [showMap, setShowMap] = useState(false);

  const handleApiLoaded = ({ map, maps }: { map: any; maps: any }) => {
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    order?.destinations.forEach((destination, i) => {
      const request = {
        origin:
          i === 0
            ? order.pickUp?.pickupAddress.address
            : order.destinations[i - 1].destinationAddress.address,
        destination: destination.destinationAddress.address,
        travelMode: "DRIVING",
        unitSystem: maps.UnitSystem.METRIC,
      };

      directionsService.route(request, (result: any, s: any) => {
        if (s === "OK") {
          directionsRenderer.setDirections(result);
        }
      });
    });
  };

  if (!order) {
    return (
      <div className="h-full grid place-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="h-full">
        <Header>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <button
                type="button"
                className="p-2"
                onClick={() => navigate("/orders", { replace: true })}
              >
                <IoArrowBackOutline size={20} />
              </button>
              <div className="ml-3">
                <p className="text-sm leading-none">{order?.orderId}</p>
                <span className="text-xs opacity-60 font-bold">
                  Created -{" "}
                  {dayjs(order?.createdAt.toDate()).format("ddd, MMM DD YYYY")}{" "}
                  - {dayjs(order?.createdAt.toDate()).format("HH:mm:ssa")}
                </span>
                {order?.cancelled ? (
                  <span className="text-xs text-error block">
                    Cancelled by {order.cancelledBy?.role}
                    {order?.cancelledAt
                      ? ` - ${dayjs(order?.cancelledAt.toDate()).format(
                          "ddd, MMM DD YYYY"
                        )} - ${dayjs(order?.cancelledAt.toDate()).format(
                          "HH:mm:ssa"
                        )}`
                      : ""}
                  </span>
                ) : null}
              </div>
            </div>
            <button
              type="button"
              className="p-2 rounded-full bg-backgroundShade"
              onClick={() => setShowMap(true)}
            >
              <IoMapOutline size={25} />
            </button>
          </div>
        </Header>
        {order ? (
          <>
            <section className="p-2 grid md:grid-cols-3 lg:grid-cols-4 gap-2">
              {/* Amount */}
              <div className="flex">
                <div className="p-2 h-fit flex items-center justify-center rounded-md bg-sky-500/20">
                  <TbCurrencyNaira size={30} className="text-sky-500" />
                </div>
                <div className="flex-1 ml-3 h-full">
                  <p className="text-xs font-extralight opacity-60">Amount</p>
                  <h2 className="text-lg font-bold">
                    <NumberFormat
                      value={moneyToNaira(order.amount.totalAmount)}
                      displayType="text"
                      decimalScale={2}
                      thousandSeparator
                      prefix="₦"
                    />
                  </h2>
                </div>
              </div>
              {/* Charge */}
              <div className="flex">
                <div className="p-2 h-fit flex items-center justify-center rounded-md bg-yellow-500/20">
                  <TbCurrencyNaira size={30} className="text-yellow-500" />
                </div>
                <div className="flex-1 ml-3 h-full">
                  <p className="text-xs font-extralight opacity-60">Charge</p>
                  <h2 className="text-lg font-bold">
                    <NumberFormat
                      value={moneyToNaira(order.amount.serviceCharge) || 0}
                      displayType="text"
                      decimalScale={2}
                      thousandSeparator
                      prefix="₦"
                    />
                  </h2>
                </div>
              </div>

              {/* Current status */}
              {status ? (
                <div className="flex">
                  <div
                    className={`p-2 flex h-fit items-center justify-center rounded-md ${status.bg}`}
                  >
                    <status.Icon size={30} className={`${status.iconColor}`} />
                  </div>
                  <div className="flex-1 ml-3 h-full">
                    <p className="text-xs font-extralight opacity-60">
                      Current status
                    </p>
                    <h2
                      className={`text-lg font-bold ${
                        status.time ? "leading-none" : ""
                      }`}
                    >
                      {status.text}
                    </h2>
                    {status.time ? (
                      <span className="text-xs font-extralight opacity-60">
                        {dayjs(status.time.toDate()).format(
                          "DD/MM/YYYY - HH:mma"
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {paymentType ? (
                <>
                  {/* Payment Method */}
                  <div className="flex">
                    <div className="p-2 h-fit flex items-center justify-center rounded-md bg-yellow-500/20">
                      <paymentType.Icon size={30} className="text-yellow-500" />
                    </div>
                    <div className="flex-1 ml-3 h-full">
                      <p className="text-xs font-extralight opacity-60">
                        Payment Method
                      </p>
                      <h2 className="text-lg font-bold">{paymentType.text}</h2>
                    </div>
                  </div>
                </>
              ) : null}
            </section>
            <section className="p-2">
              <div className="grid md:grid-cols-2 gap-2">
                <div>
                  <h2 className="text-sm font-bold">User Details</h2>
                  <div className="rounded-md p-2 bg-backgroundTint">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="p-2 h-fit flex items-center justify-center rounded-md bg-backgroundShade">
                          <HiOutlineUser
                            size={30}
                            className="text-foreground"
                          />
                        </div>

                        <div className="ml-3">
                          <Link
                            to={`/users/${order.userDetails.userId}`}
                            className="text-sm block hover:text-tertiary"
                          >
                            {order.userDetails.fullName}
                          </Link>
                          <span className="text-xs opacity-80 font-extralight block">
                            {order.userDetails.email}
                          </span>
                          <span className="text-xs opacity-80 font-extralight block">
                            {order.userDetails.phoneNumber}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <a
                          href={`tel:${order.userDetails.phoneNumber}`}
                          className="mx-1 p-2 rounded-full bg-success/70 hover:shadow-md hover:shadow-backgroundShade"
                        >
                          <IoCall size="20" className="text-foreground" />
                        </a>
                        <a
                          href={`mailto:${order.userDetails.email}`}
                          className="mx-1 p-2 rounded-full bg-error/70 hover:shadow-md hover:shadow-backgroundShade"
                        >
                          <IoMail size="20" className="text-foreground" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {order?.riderDetails ? (
                  <div>
                    <h2 className="text-sm font-bold">Rider Details</h2>
                    <div className="rounded-md p-2 bg-backgroundTint">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="p-2 h-fit flex items-center justify-center rounded-md bg-backgroundShade">
                            <GiFullMotorcycleHelmet
                              size={30}
                              className="text-foreground"
                            />
                          </div>

                          <div className="ml-3">
                            <Link
                              to={`/riders/${order.riderDetails.riderId}`}
                              className="text-sm block hover:text-tertiary"
                            >
                              {order.riderDetails.fullName}
                            </Link>
                            <span className="text-xs opacity-80 font-extralight block">
                              {order.riderDetails.email}
                            </span>
                            <span className="text-xs opacity-80 font-extralight block">
                              {order.riderDetails.phoneNumber.number}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <a
                            href={`tel:${order.riderDetails.phoneNumber.number}`}
                            className="mx-1 p-2 rounded-full bg-success/70 hover:shadow-md hover:shadow-backgroundShade"
                          >
                            <IoCall size="20" className="text-foreground" />
                          </a>
                          <a
                            href={`mailto:${order.riderDetails.email}`}
                            className="mx-1 p-2 rounded-full bg-error/70 hover:shadow-md hover:shadow-backgroundShade"
                          >
                            <IoMail size="20" className="text-foreground" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="grid gap-2 mt-2">
                <div>
                  <h2 className="text-sm font-bold">Pick-up Details</h2>
                  <div className="rounded-md p-2 bg-backgroundTint">
                    <div className="flex justify-between">
                      <div className="flex">
                        <div className="p-2 h-fit flex items-center justify-center rounded-md bg-green-500/20">
                          <GiCardPickup size={30} className="text-green-500" />
                        </div>

                        <div className="ml-3">
                          <p className="text-sm">
                            {order.pickUp?.customerName}
                          </p>
                          <span className="text-xs opacity-80 font-extralight block">
                            {order.pickUp?.email}
                          </span>
                          <span className="text-xs opacity-80 font-extralight block">
                            {order.pickUp?.phoneNumber}
                          </span>
                          <span className="text-xs opacity-80 font-extralight block">
                            {order.pickUp?.pickupAddress.address}
                          </span>
                          <span className="text-xs opacity-80 font-extralight block">
                            Landmark - {order.pickUp?.pickupAddress.landMark}
                          </span>
                          {order.pickUp?.note ? (
                            <span className="text-xs opacity-80 font-extralight block">
                              Note - {order.pickUp.note}
                            </span>
                          ) : null}
                          {order.pickUp?.pickupAddress.direction ? (
                            <span className="text-xs opacity-80 font-extralight block">
                              Direction -{" "}
                              {order.pickUp?.pickupAddress.direction}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex h-fit">
                        <a
                          href={`tel:${order.pickUp?.phoneNumber}`}
                          className="mx-1 p-2 rounded-full bg-success/70 hover:shadow-md hover:shadow-backgroundShade"
                        >
                          <IoCall size="20" className="text-foreground" />
                        </a>
                        <a
                          href={`mailto:${order.pickUp?.email}`}
                          className="mx-1 p-2 rounded-full bg-error/70 hover:shadow-md hover:shadow-backgroundShade"
                        >
                          <IoMail size="20" className="text-foreground" />
                        </a>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                            order.pickUp?.pickupAddress.address!
                          )}`}
                          target="_blank"
                          className="mx-1 p-2 rounded-full bg-foreground hover:shadow-md hover:shadow-backgroundShade"
                          rel="noreferrer"
                        >
                          <MdLocationPin size="20" className="text-error" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <h2 className="font-bold">Delivery Details</h2>
                <div className="grid gap-2 mt-2">
                  {order.destinations.map((destination) => (
                    <div key={destination.id}>
                      <h2 className="text-sm font-bold">Destination</h2>
                      <div className="rounded-md p-2 bg-backgroundTint">
                        <div className="flex justify-between">
                          <div className="flex">
                            <div
                              className={`p-2 h-fit flex items-center justify-center rounded-md ${
                                order.completed || destination.delivered
                                  ? "bg-emerald-500/20"
                                  : order.cancelled
                                  ? "bg-rose-500/20"
                                  : order?.pickedUp
                                  ? "bg-amber-500/20"
                                  : "bg-yellow-500/20"
                              }`}
                            >
                              {order.completed || destination.delivered ? (
                                <IoCheckmarkDoneOutline
                                  size={30}
                                  className="text-emerald-500"
                                />
                              ) : order.cancelled ? (
                                <MdOutlineCancelPresentation
                                  size={30}
                                  className="text-rose-500"
                                />
                              ) : order?.pickedUp ? (
                                <GiRoad size={30} className="text-amber-500" />
                              ) : (
                                <MdOutlinePending
                                  size={30}
                                  className="text-yellow-500"
                                />
                              )}
                            </div>

                            <div className="ml-3">
                              <p className="text-sm">
                                {destination.recipientName}
                              </p>
                              {destination.delivered &&
                              destination.deliveredAt ? (
                                <span className="text-xs opacity-80 font-extralight block">
                                  {dayjs(
                                    destination.deliveredAt.toDate()
                                  ).format("ddd, MMM DD YYYY - HH:mma")}
                                </span>
                              ) : null}
                              {destination.email ? (
                                <span className="text-xs opacity-80 font-extralight block">
                                  {destination.email}
                                </span>
                              ) : null}

                              <span className="text-xs opacity-80 font-extralight block">
                                {destination?.phoneNumber}
                              </span>
                              <span className="text-xs opacity-80 font-extralight block">
                                {destination.destinationAddress.address}
                              </span>
                              <span className="text-xs opacity-80 font-extralight block">
                                Landmark -{" "}
                                {destination.destinationAddress.landMark}
                              </span>
                              {destination.note ? (
                                <span className="text-xs opacity-80 font-extralight block">
                                  Note - {destination.note}
                                </span>
                              ) : null}
                              {destination.destinationAddress.direction ? (
                                <span className="text-xs opacity-80 font-extralight block">
                                  Direction -{" "}
                                  {destination.destinationAddress.direction}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex h-fit">
                            <a
                              href={`tel:${destination.phoneNumber}`}
                              className="mx-1 p-2 rounded-full bg-success/70 hover:shadow-md hover:shadow-backgroundShade"
                            >
                              <IoCall size="20" className="text-foreground" />
                            </a>
                            {destination.email ? (
                              <a
                                href={`mailto:${destination.email}`}
                                className="mx-1 p-2 rounded-full bg-error/70 hover:shadow-md hover:shadow-backgroundShade"
                              >
                                <IoMail size="20" className="text-foreground" />
                              </a>
                            ) : null}

                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                                destination.destinationAddress.address!
                              )}`}
                              target="_blank"
                              className="mx-1 p-2 rounded-full bg-foreground hover:shadow-md hover:shadow-backgroundShade"
                              rel="noreferrer"
                            >
                              <MdLocationPin size="20" className="text-error" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section className="p-2">
              <h2 className="text-lg font-bold">More Info</h2>
              <div className="grid md:grid-cols-2 gap-2">
                {order.riderAcceptedAt ? (
                  <div className="rounded-md bg-backgroundTint shadow-sm p-2">
                    <span className="text-xs font-extralight opacity-80">
                      Rider Accepted order
                    </span>
                    <p className="text-xs">
                      {dayjs(order.riderAcceptedAt.toDate()).format(
                        "ddd, MMM DD YYYY - HH:mma"
                      )}
                    </p>
                  </div>
                ) : null}
                {order.arrived && order.riderArrivedAt ? (
                  <div className="rounded-md bg-backgroundTint shadow-sm p-2">
                    <span className="text-xs font-extralight opacity-80">
                      Rider arrived pickup location
                    </span>
                    <p className="text-xs">
                      {dayjs(order.riderArrivedAt.toDate()).format(
                        "ddd, MMM DD YYYY - HH:mma"
                      )}
                    </p>
                  </div>
                ) : null}
                {order.otp ? (
                  <div className="rounded-md bg-backgroundTint shadow-sm p-2">
                    <span className="text-xs font-extralight opacity-80">
                      OTP
                    </span>
                    <p className="text-xs font-bold">{order.otp}</p>
                  </div>
                ) : null}
                {order.pickedUp && order.pickedUpAt ? (
                  <div className="rounded-md bg-backgroundTint shadow-sm p-2">
                    <span className="text-xs font-extralight opacity-80">
                      Picked up on
                    </span>
                    <p className="text-xs">
                      {dayjs(order.pickedUpAt.toDate()).format(
                        "ddd, MMM DD YYYY - HH:mma"
                      )}
                    </p>
                  </div>
                ) : null}
                {order.cancelled && order.cancelledAt ? (
                  <div className="rounded-md bg-backgroundTint shadow-sm p-2">
                    <span className="text-xs font-extralight opacity-80">
                      Cancelled on
                    </span>
                    <p className="text-xs">
                      {dayjs(order.cancelledAt.toDate()).format(
                        "ddd, MMM DD YYYY - HH:mma"
                      )}
                    </p>
                  </div>
                ) : null}
                {order?.rated && rating ? (
                  <div className="rounded-md bg-backgroundTint shadow-sm p-2">
                    <span className="text-xs font-extralight opacity-80">
                      User Rating
                    </span>
                    <div>
                      <StarRatings
                        rating={rating.star}
                        starRatedColor="#f59e0b"
                        starDimension="10px"
                        starSpacing="2px"
                        numberOfStars={5}
                        name="rating"
                      />
                    </div>
                    {rating.review ? (
                      <p className="text-xs">{rating.review}</p>
                    ) : null}
                    {order.ratedAt ? (
                      <p className="text-xs">
                        {dayjs(order.ratedAt.toDate()).format(
                          "ddd, MMM DD YYYY - HH:mma"
                        )}
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </section>
          </>
        ) : null}
      </div>
      <Transition appear show={showMap} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowMap(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-xl" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg h-80 transform overflow-hidden rounded-md bg-backgroundTint text-left align-middle shadow-xl transition-all">
                  <div className="w-full h-full">
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_MAP_KEY!,
                      }}
                      defaultCenter={{
                        lat: order.pickUp?.pickupAddress.geometry.location.lat,
                        lng: order.pickUp?.pickupAddress.geometry.location.lng,
                      }}
                      defaultZoom={15}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={handleApiLoaded}
                    >
                      <PickupMarker
                        lat={order.pickUp?.pickupAddress.geometry.location.lat}
                        lng={order.pickUp?.pickupAddress.geometry.location.lng}
                        address={order.pickUp?.pickupAddress.address!}
                      />

                      {order.destinations.map((destination) => (
                        <DestinationMarker
                          key={destination.id}
                          lat={
                            destination.destinationAddress.geometry.location.lat
                          }
                          lng={
                            destination.destinationAddress.geometry.location.lng
                          }
                          address={destination.destinationAddress.address!}
                          delivered={destination.delivered}
                          cancelled={order.cancelled}
                        />
                      ))}

                      {status?.text.includes("Enroute") && riderLocation ? (
                        <RiderLocationMarker
                          lat={riderLocation.coordinates.latitude}
                          lng={riderLocation.coordinates.longitude}
                          location={riderLocation}
                        />
                      ) : null}
                    </GoogleMapReact>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default OrderDetails;
