import { createSlice } from "@reduxjs/toolkit";
import { ICompanyRegistration } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define a type for the slice state
export interface IInitialState {
  pendingRegistration: ICompanyRegistration[];
}

// Define the initial state using that type
const initialState: IInitialState = {
  pendingRegistration: [],
};

export const companiesRegistrationSlice = createSlice({
  name: "companiesRegistration",
  initialState,
  reducers: {
    updateCompaniesRegistration: (state, action) => {
      state.pendingRegistration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.pendingRegistration = [];
    });
  },
});

export const { updateCompaniesRegistration } =
  companiesRegistrationSlice.actions;
export const selectCompaniesRegistration = (state: RootState) =>
  state.companiesRegistration;

export default companiesRegistrationSlice.reducer;
