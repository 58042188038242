import React, { useCallback, useMemo, useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CollectionNames } from "../../enums";
import { IWithdrawalRequest } from "../../interfaces";
import adminService from "../../services/admin.service";
import { handleError, moneyToNaira } from "../../utils/_helper";
import ActionLoading from "../ActionLoading";

interface Props {
  request: IWithdrawalRequest;
  type: CollectionNames.RIDERS | CollectionNames.COMPANIES;
}

function WithdrawlRequest({ request, type }: Props) {
  const [loading, setLoading] = useState(false);
  const path = useMemo(
    () =>
      type === CollectionNames.RIDERS
        ? CollectionNames.RIDERS
        : "courier-company",
    [type]
  );

  const id = useMemo(
    () =>
      type === CollectionNames.RIDERS ? request.riderId : request.companyId,
    [request.companyId, request.riderId, type]
  );

  const approve = useCallback(async () => {
    setLoading(true);
    try {
      const response = await adminService.approveWithdrawal(
        type === CollectionNames.RIDERS
          ? CollectionNames.RIDER_WITHDRAWAL_REQUEST
          : CollectionNames.COMPANY_WITHDRAWAL_REQUEST,
        request.id
      );
      setLoading(false);
      toast.success(response.message);
    } catch (error) {
      setLoading(false);
      toast.error(handleError(error));
    }
  }, [request.id, type]);

  const decline = useCallback(async () => {
    setLoading(true);
    try {
      const response = await adminService.cancelWithdrawal(
        type === CollectionNames.RIDERS
          ? CollectionNames.RIDER_WITHDRAWAL_REQUEST
          : CollectionNames.COMPANY_WITHDRAWAL_REQUEST,
        request.id
      );
      setLoading(false);
      toast.success(response.message);
    } catch (error) {
      setLoading(false);
      toast.error(handleError(error));
    }
  }, [request.id, type]);

  return (
    <>
      <div className="p-2 flex space-between space-x-3">
        <div className="flex-1">
          <Link
            to={`/${path}/${id}`}
            className="text-xs hover:underline hover:text-tertiary"
          >
            {request.details.account_name}
          </Link>
          <p className="text-xs">{request.details.account_number}</p>
          <p className="text-xs">{request.details.bank_name}</p>
        </div>
        <div className="space-y-2">
          <h2 className="font-bold text-sm">
            <NumberFormat
              value={moneyToNaira(request.amount)}
              displayType="text"
              decimalScale={2}
              thousandSeparator
              prefix="₦"
            />
          </h2>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              className="rounded-full p-1 bg-error"
              title="Decline"
              onClick={decline}
            >
              <MdOutlineCancel size={15} className="text-foreground" />
            </button>
            <button
              type="button"
              className="rounded-full p-1 bg-success"
              title="Approve"
              onClick={approve}
            >
              <HiOutlineCheckCircle size={15} className="text-foreground" />
            </button>
          </div>
        </div>
      </div>
      <ActionLoading isOpen={loading} text="Please wait..." />
    </>
  );
}

export default WithdrawlRequest;
