import { useState } from "react";

const filter = [
  {
    title: "First Name",
    value: "firstName",
  },
  {
    title: "Last Name",
    value: "lastName",
  },
  {
    title: "Email",
    value: "email",
  },
];

const useSearchFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState(filter[0].value);

  return {
    filter,
    selectedFilter,
    setSelectedFilter,
  };
};

export default useSearchFilter;
