import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import adminService from "../services/admin.service";
import { handleError } from "../utils/_helper";

const useCompanyRegistration = (id: string) => {
  const navigate = useNavigate();
  const [approving, setApproving] = useState(false);
  const [showApproveRegistration, setShowApproveRegistration] = useState(false);
  const [showDeclineeRegistration, setShowDeclineeRegistration] =
    useState(false);

  const approve = useCallback(async () => {
    setApproving(true);
    try {
      const res = await adminService.approveCompany(id);
      setApproving(false);
      toast.success(res.message);
      navigate("/company-registration", {
        replace: true,
      });
    } catch (error) {
      setApproving(false);
      toast.error(handleError(error));
    }
  }, [id, navigate]);

  const declineFrom = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object({
      reason: yup.string().trim().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await adminService.declineCompany(id, values.reason);
        toast.success(res.message);
        navigate("/company-registration", {
          replace: true,
        });
      } catch (error) {
        toast.error(handleError(error));
      }
    },
  });

  return {
    declineFrom,
    approve,
    approving,
    showApproveRegistration,
    setShowApproveRegistration,
    showDeclineeRegistration,
    setShowDeclineeRegistration,
  };
};

export default useCompanyRegistration;
