import { createSlice } from "@reduxjs/toolkit";
import { ICompany } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import {
  getCompanyAction,
  searchCompanyAction,
} from "../actions/courierCompany.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  courierCompanies: ICompany[];
  loaded: boolean;
  lastVisible: any;
  more: boolean;
  loading: boolean;
  searchLoading: boolean;
  searchLoaded: boolean;
} = {
  courierCompanies: [],
  loaded: false,
  lastVisible: null,
  more: true,
  loading: false,
  searchLoading: false,
  searchLoaded: false,
};

export const courierCompanySlice = createSlice({
  name: "courierCompany",
  initialState,
  reducers: {
    reset: (state) => {
      state.courierCompanies = [];
      state.loaded = false;
      state.lastVisible = null;
      state.more = true;
      state.loading = false;
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    resetSearchCourierCompanies: (state) => {
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    updateCourierCompanies: (state, action) => {
      state.courierCompanies = [...state.courierCompanies, action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction.fulfilled, (state) => {
        state.courierCompanies = [];
        state.loaded = false;
        state.lastVisible = null;
        state.loading = false;
        state.searchLoading = false;
        state.searchLoaded = false;
      })
      .addCase(getCompanyAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyAction.rejected, (state) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getCompanyAction.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.lastVisible = action.payload.lv;
        state.more = action.payload.more;
        state.courierCompanies = [
          ...state.courierCompanies.filter(
            (company) => !action.payload.data.find((d) => d.id === company.id)
          ),
          ...action.payload.data,
        ];
      })
      .addCase(searchCompanyAction.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchCompanyAction.rejected, (state) => {
        state.searchLoading = false;
        state.searchLoaded = true;
      })
      .addCase(searchCompanyAction.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchLoaded = true;
        state.courierCompanies = [
          ...state.courierCompanies.filter(
            (company) => !action.payload.data.find((d) => d.id === company.id)
          ),
          ...action.payload.data,
        ];
      }),
});

export const { reset, updateCourierCompanies, resetSearchCourierCompanies } =
  courierCompanySlice.actions;
export const selectCourierCompanies = (state: RootState) =>
  state.courierCompany;

export default courierCompanySlice.reducer;
