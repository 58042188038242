import { Unsubscribe, User } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { AdminPositions, CollectionNames, CuzooAdminRoles } from "../enums";
import { IWithdrawalRequest } from "../interfaces";
import { selectAuth, updateUser } from "../redux/slices/authSlice";
import { updateCompaniesRegistration } from "../redux/slices/companiesRegistrationSlice";
import { updateMetadata } from "../redux/slices/metadataSlice";
import { updateRiderRegistration } from "../redux/slices/riderRegistrationSlice";
import { updateSettings } from "../redux/slices/settingsSlice";
import { updateWithdrawalRequests } from "../redux/slices/withdrawalRequestSlice";
import authService from "../services/auth.service";
import { useAppDispatch, useAppSelector } from "./useRedux";

const useAuth = () => {
  const [firebaseAuth, setFirebaseAuth] = useState<User | null>(null);
  const { loaded, user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const updateAuth = useCallback(
    (userData: any) => {
      dispatch(updateUser(userData));
    },
    [dispatch]
  );

  const updateMeta = useCallback(
    (data: any) => {
      dispatch(updateMetadata(data));
    },
    [dispatch]
  );

  const updateCuzooSettings = useCallback(
    (data: any) => {
      dispatch(updateSettings(data));
    },
    [dispatch]
  );

  const updateRiderReg = useCallback(
    (data: any) => {
      dispatch(updateRiderRegistration(data));
    },
    [dispatch]
  );

  const updateCompaniesReg = useCallback(
    (data: any) => {
      dispatch(updateCompaniesRegistration(data));
    },
    [dispatch]
  );

  // Authenticate user
  useEffect(() => {
    authService.authenticate(setFirebaseAuth, updateAuth);
  }, [updateAuth]);

  // Get user data if authenticvation is successfull
  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (firebaseAuth?.uid) {
      unsubscribe = authService.getUser(firebaseAuth.uid, updateAuth);
    }

    if (!firebaseAuth?.uid && unsubscribe) {
      unsubscribe();
      updateAuth(null);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        updateAuth(null);
      }
    };
  }, [firebaseAuth?.uid, updateAuth]);

  // Get app meta data if authenticvation is successfull
  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (firebaseAuth?.uid) {
      unsubscribe = authService.getMetadata(updateMeta);
    }

    if (!firebaseAuth?.uid && unsubscribe) {
      unsubscribe();
      updateMeta(null);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        updateMeta(null);
      }
    };
  }, [firebaseAuth?.uid, updateMeta, user]);

  // Get pending rider registration data if authenticvation is successfull
  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (firebaseAuth?.uid && user) {
      unsubscribe = authService.getRiderRegistration(user, updateRiderReg);
    }

    if (!firebaseAuth?.uid && unsubscribe) {
      unsubscribe();
      updateRiderReg([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        updateRiderReg([]);
      }
    };
  }, [firebaseAuth?.uid, updateRiderReg, user]);

  // Get app settings data if authenticvation is successfull
  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (firebaseAuth?.uid) {
      unsubscribe = authService.getSettings(updateCuzooSettings);
    }

    if (!firebaseAuth?.uid && unsubscribe) {
      unsubscribe();
      updateCuzooSettings(null);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        updateCuzooSettings(null);
      }
    };
  }, [firebaseAuth?.uid, updateCuzooSettings]);

  // Get pending logistics company registration data if authenticvation is successfull
  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (firebaseAuth?.uid && user?.position !== AdminPositions.INVESTOR) {
      unsubscribe = authService.getCompaniesRegistration(updateCompaniesReg);
    }

    if (!firebaseAuth?.uid && unsubscribe) {
      unsubscribe();
      updateCompaniesReg([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        updateCompaniesReg([]);
      }
    };
  }, [firebaseAuth?.uid, updateCompaniesReg]);

  // Load withdrawal request if superadmin
  useEffect(() => {
    let ridersUnsub: Unsubscribe | null = null;
    let companiesUnsub: Unsubscribe | null = null;
    if (user && user?.role > CuzooAdminRoles.ADMIN && !ridersUnsub) {
      ridersUnsub = authService.watchWithdrawals<IWithdrawalRequest>(
        CollectionNames.RIDER_WITHDRAWAL_REQUEST,
        (data) =>
          dispatch(
            updateWithdrawalRequests({
              type: "riders",
              data,
            })
          )
      );
    }

    if (user && user?.role > CuzooAdminRoles.ADMIN && !companiesUnsub) {
      companiesUnsub = authService.watchWithdrawals<IWithdrawalRequest>(
        CollectionNames.COMPANY_WITHDRAWAL_REQUEST,
        (data) =>
          dispatch(
            updateWithdrawalRequests({
              type: "companies",
              data,
            })
          )
      );
    }

    return () => {
      if (ridersUnsub) {
        ridersUnsub();
      }
      if (companiesUnsub) {
        companiesUnsub();
      }
    };
  }, [dispatch, user]);

  return { loaded };
};

export default useAuth;
