/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { getOrdersAction } from "../../redux/actions/orders.actions";
import { reset, selectOrders } from "../../redux/slices/ordersSlice";
import { handleError } from "../../utils/_helper";
import Button from "./Button";
import OrderItem from "./OrderItem";
import Spinner from "./Spinner";

interface Props {
  range: DateValueType;
  country: string;
  state: string;
}

function OrdersList({ range, country, state }: Props) {
  const {
    loaded,
    loading,
    more,
    orders,
    lastVisible,
    range: stateRange,
    country: stateCountry,
    state: stateState,
  } = useAppSelector(selectOrders);
  const dispatch = useAppDispatch();

  const fetchOrders = useCallback(
    async (lv: any) => {
      try {
        await dispatch(
          getOrdersAction({ lastVisible: lv, range, country, st: state })
        );
      } catch (error) {
        toast.error(handleError(error));
      }
    },
    [country, dispatch, range, state]
  );

  useEffect(() => {
    if (
      !loaded ||
      (range &&
        (dayjs(range.startDate).format("DD/MM/YYYY") !==
          dayjs(stateRange?.startDate).format("DD/MM/YYYY") ||
          dayjs(range.endDate).format("DD/MM/YYYY") !==
            dayjs(stateRange?.endDate).format("DD/MM/YYYY"))) ||
      (country && country !== stateCountry) ||
      (state && state !== stateState)
    ) {
      dispatch(reset());
      fetchOrders(lastVisible);
    }
  }, [country, dispatch, fetchOrders, lastVisible, loaded, range, state]);

  return (
    <div className="grid md:grid-cols-2 p-2 gap-2">
      {orders.map((order) => (
        <OrderItem key={order.id} order={order} />
      ))}
      {loading ? <Spinner /> : null}

      {loaded && more && !loading ? (
        <Button
          onClick={() => fetchOrders(lastVisible)}
          text="Load More"
          className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
        />
      ) : null}
    </div>
  );
}

export default OrdersList;
