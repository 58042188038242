import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import authService from "../services/auth.service";
import { handleError } from "../utils/_helper";

const useForgotPassword = () => {
  const formikForgotPassword = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .lowercase()
        .email("Invalid Email Address")
        .required("Email is Required")
        .test("Email", "Invalid Email Address", (val) => {
          if (val && val.endsWith("@cuzooapp.com")) {
            return true;
          }

          return false;
        }),
    }),

    onSubmit: async ({ email }) => {
      try {
        const { message } = await authService.requestPasswordReset(
          email.trim().toLowerCase()
        );
        toast.success(message);
      } catch (e: any) {
        toast.error(handleError(e));
      }
    },
  });

  return {
    formikForgotPassword,
  };
};

export default useForgotPassword;
