import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { selectSettings } from "../redux/slices/settingsSlice";
import adminService from "../services/admin.service";
import { handleError } from "../utils/_helper";
import { useAppSelector } from "./useRedux";

const useRiderRegistration = (
  id: string,
  vehicle: {
    hasVehicle: boolean;
    vehicles: string[];
  }
) => {
  const navigate = useNavigate();
  const { general } = useAppSelector(selectSettings);
  const [showApproveRegistration, setShowApproveRegistration] = useState(false);
  const [showDeclineeRegistration, setShowDeclineeRegistration] =
    useState(false);

  const approveForm = useFormik({
    initialValues: {
      vehicles: [],
    },
    validationSchema: yup.object({
      vehicles: yup
        .array()
        .of(
          yup
            .string()
            .oneOf(
              general?.transportTypes || [
                "car",
                "bike",
                "truck",
                "van",
                "bicycle",
              ],
              "Invalid vehicle type."
            )
        )
        .test("hasVehicle", "Must select at least one", (val) => {
          return !(!vehicle.hasVehicle && !val?.length);
        }),
    }),
    onSubmit: async (values) => {
      try {
        const res = await adminService.approveRider(id, values.vehicles);
        toast.success(res.message);
        navigate("/riders-registration", {
          replace: true,
        });
      } catch (error) {
        toast.error(handleError(error));
      }
    },
  });

  const handleSelectForm3Multiple = useCallback(
    (selected: any) => {
      approveForm.setFieldValue(
        "vehicles",
        selected.map((v: any) => v.value),
        true
      );
    },
    [approveForm]
  );

  const declineFrom = useFormik({
    initialValues: {
      reasons: [
        {
          name: "address",
          message: "",
        },
        {
          name: "passport",
          message: "",
        },
        {
          name: "guarantor",
          message: "",
        },
        {
          name: "nationalId",
          message: "",
        },
        {
          name: "riderCard",
          message: "",
        },
      ],
    },
    validationSchema: yup.object({
      reason: yup.array().of(
        yup.object({
          name: yup.string().required("Required"),
          message: yup.string().optional(),
        })
      ),
    }),
    onSubmit: async (values) => {
      try {
        const d = values.reasons.filter((r) => r.message !== "");
        if (!d.length) throw new Error("At least, one reason is needed");
        const res = await adminService.declineRider(id, d);
        toast.success(res.message);
        navigate("/riders-registration", {
          replace: true,
        });
      } catch (error) {
        toast.error(handleError(error));
      }
    },
  });

  return {
    declineFrom,
    showApproveRegistration,
    setShowApproveRegistration,
    showDeclineeRegistration,
    setShowDeclineeRegistration,
    handleSelectForm3Multiple,
    approveForm,
    transportTypes: general?.transportTypes || [
      "car",
      "bike",
      "truck",
      "van",
      "bicycle",
    ],
  };
};

export default useRiderRegistration;
