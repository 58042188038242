/* eslint-disable no-underscore-dangle */
import React from "react";
import { Link } from "react-router-dom";
import { ICompany } from "../../interfaces";

interface Props {
  company: ICompany;
}

function CourierCompanyItem({ company }: Props) {
  return (
    <div className="bg-background p-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 items-center justify-between">
      <div className="flex items-center">
        {company.profilePicture ? (
          <img
            src={company.profilePicture.url}
            alt="User Profile"
            className="w-10 h-10 object-cover rounded-full"
          />
        ) : (
          <div className="text-xs font-semibold w-10 h-10 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
            <p>{company.businessName[0].toUpperCase()}</p>
          </div>
        )}

        <p className="text-xs ml-3">{company.businessName}</p>
      </div>
      <a
        href={`mailto:${company.email}`}
        className="text-xs hover:text-tertiary text-left hidden xl:block w-fit mx-auto"
      >
        {company.email}
      </a>
      <a
        href={`tel:${company.phoneNumber.number}`}
        className="text-xs hover:text-tertiary text-left hidden md:block w-fit mx-auto"
      >
        {company.phoneNumber.nationalFormat}
      </a>
      <p className="text-xs hidden lg:block w-fit mx-auto">
        {company.tinNumber}
      </p>
      <Link
        to={`/courier-company/${company.id}`}
        className="bg-primary text-xs w-fit py-2 px-4 rounded-sm hover:shadow-md mx-auto"
      >
        View
      </Link>
    </div>
  );
}

export default CourierCompanyItem;
