import { DocumentData, Query } from "firebase/firestore";
import {
  collection,
  db,
  query,
  orderBy,
  startAfter,
  limit,
  getDoc,
  doc,
  where,
  getDocs,
} from "../config/fb";
import httpClient from "../config/HTTPClient";
import { CollectionNames, ExtUrls } from "../enums";
import { IActions, IAdmin, ICreateAdmin } from "../interfaces";
import authService from "./auth.service";

const adminService = {
  fetchAdmins: async (
    lastVisible: any
  ): Promise<{ lv: any; data: any[]; more: boolean }> => {
    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.ADMINS),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.ADMINS),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: any[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  getSingleAdmin: async (adminId: string): Promise<IAdmin | null> => {
    if (!adminId) {
      return null;
    }

    const snapshot = await getDoc(doc(db, CollectionNames.ADMINS, adminId));

    if (!snapshot.exists()) {
      return null;
    }

    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as IAdmin;
  },

  searchAdmins: async (
    key: string,
    value: string
  ): Promise<{ data: IAdmin[] }> => {
    const q: Query<DocumentData> = query(
      collection(db, CollectionNames.ADMINS),
      where(key, "==", value)
    );

    const querySnapshots = await getDocs(q);

    const data: IAdmin[] = [];

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
    };
  },

  fetchActions: async (
    lastVisible: any
  ): Promise<{ lv: any; data: IActions[]; more: boolean }> => {
    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.ADMINS_ACTIONS),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.ADMINS_ACTIONS),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IActions[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  fetchAdminActions: async (
    adminId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: IActions[]; more: boolean }> => {
    if (!adminId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.ADMINS_ACTIONS),
        where("userId", "==", adminId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.ADMINS_ACTIONS),
        where("userId", "==", adminId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IActions[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  approveRider: async (
    id: string,
    vehicles?: string[]
  ): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      `${ExtUrls.APPROVE_RIDER}/${id}`,
      {
        vehicles: vehicles || [],
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },

  declineRider: async (
    id: string,
    reasons: { name: string; message: string }[]
  ): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      `${ExtUrls.DECLINE_RIDER}/${id}`,
      {
        reasons,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },

  approveCompany: async (id: string): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.get(`${ExtUrls.APPROVE_COMPANY}/${id}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return data;
  },

  declineCompany: async (
    id: string,
    reason: string
  ): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      `${ExtUrls.DECLINE_COMPANY}/${id}`,
      {
        reason,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },

  createAdmin: async (input: ICreateAdmin): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      "/v2/admins",
      {
        ...input,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },

  actionAgainstRiderWallet: async (
    riderId: string,
    action: "suspend" | "release"
  ): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const url =
      action === "release"
        ? ExtUrls.RELEASE_RIDER_WALLET
        : ExtUrls.SUSPEND_RIDER_WALLET;
    const { data } = await httpClient.get(`${url}/${riderId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return data;
  },

  actionAgainstCompanyWallet: async (
    companyId: string,
    action: "suspend" | "release"
  ): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const url =
      action === "release"
        ? ExtUrls.RELEASE_COMPANY_WALLET
        : ExtUrls.SUSPEND_COMPANY_WALLET;

    const { data } = await httpClient.get(`${url}/${companyId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return data;
  },

  suspendAdmin: async (
    adminId: string
  ): Promise<{
    message: string;
    id: string;
    update: { suspended: boolean };
  }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.get(
      `${ExtUrls.SUSPEND_ADMIN}/${adminId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return {
      ...data,
      id: adminId,
      update: {
        suspended: true,
      },
    };
  },

  releaseAdmin: async (
    adminId: string
  ): Promise<{
    message: string;
    id: string;
    update: { suspended: boolean };
  }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.get(
      `${ExtUrls.RELEASE_ADMIN}/${adminId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return {
      ...data,
      id: adminId,
      update: {
        suspended: false,
      },
    };
  },

  updateSettings: async (update: any): Promise<{ message: string }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      `${ExtUrls.UPDATE_SETTINGS}`,
      {
        ...update,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },

  approveWithdrawal: async (
    name:
      | CollectionNames.RIDER_WITHDRAWAL_REQUEST
      | CollectionNames.COMPANY_WITHDRAWAL_REQUEST,
    id: string
  ): Promise<{
    message: string;
  }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      `${ExtUrls.APPROVE_WITHDRAWAL}`,
      { name, id },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },
  cancelWithdrawal: async (
    name:
      | CollectionNames.RIDER_WITHDRAWAL_REQUEST
      | CollectionNames.COMPANY_WITHDRAWAL_REQUEST,
    id: string
  ): Promise<{
    message: string;
  }> => {
    const idToken = await authService.getIdToken();
    const { data } = await httpClient.post(
      `${ExtUrls.CANCEL_WITHDRAWAL}`,
      { name, id },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return data;
  },
};

export default adminService;
