/* eslint-disable import/prefer-default-export */
import { DateValueType } from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces";
import usersService from "../../services/users.service";

export const getUsersAction = createAsyncThunk<
  {
    data: IUser[];
    lv: any;
    more: boolean;
    range: { startDate: number; endDate: number } | null;
    country: string;
    state: string;
  },
  { lastVisible: any; range: DateValueType; country: string; st: string }
>(
  "users/getUsersAction",
  async (
    { lastVisible, range, country, st },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as any;
      const response = await usersService.fetchUsers(
        state.auth.user!,
        lastVisible,
        range,
        country,
        st
      );
      return {
        ...response,
        range:
          range?.startDate && range.endDate
            ? {
                startDate: +dayjs(range.startDate),
                endDate: +dayjs(range.endDate),
              }
            : null,
        country,
        state: st,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const searcUsersAction = createAsyncThunk<
  { data: IUser[] },
  { key: string; value: string }
>(
  "users/searcUsersAction",
  async ({ key, value }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;
      const response = await usersService.searchUsers(
        state.auth.user!,
        key,
        value
      );
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
