import React, { Fragment, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoArrowBackOutline } from "react-icons/io5";
import { Country, State } from "country-state-city";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import Header from "../components/shared/Header";
import Spinner from "../components/shared/Spinner";
import Button from "../components/shared/Button";
import Action from "../components/shared/Action";
import { selectAdmins, updateAdmins } from "../redux/slices/adminsSlice";
import useAdminDetails from "../hooks/useAdminDetails";
import ActionLoading from "../components/ActionLoading";
import { selectAuth } from "../redux/slices/authSlice";
import adminService from "../services/admin.service";
import { CuzooAdminRoles } from "../enums";

function AdminDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAppSelector(selectAuth);
  const { admins, loading } = useAppSelector(selectAdmins);
  const [loadingText, setLoadingText] = useState("");

  const admin = useMemo(
    () => admins.find((v) => v.id === params.id),
    [params.id, admins]
  );

  useEffect(() => {
    const getSingleAdmin = async () => {
      try {
        const res = await adminService.getSingleAdmin(params.id!);
        if (!res) {
          return navigate("/admins", { replace: true });
        }
        return dispatch(updateAdmins(res));
      } catch (error) {
        return navigate("/admins", { replace: true });
      }
    };

    if (!admin) {
      getSingleAdmin();
    }
  }, [admin, dispatch, navigate, params.id]);

  const { adminActions, getActions, suspendAdmin, releaseAdmin } =
    useAdminDetails(params.id!);

  if (!admin) {
    return (
      <div className="h-full grid place-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="h-full">
        <Header>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <button
                type="button"
                className="p-2"
                onClick={() => navigate("/admins", { replace: true })}
              >
                <IoArrowBackOutline size={20} />
              </button>
              <div className="ml-3">
                <p className="text-lg leading-none">
                  {admin?.firstName} {admin?.lastName}
                </p>
                <span className="text-xs opacity-60 font-bold">
                  Admin Profile
                </span>
                {admin?.suspended ? (
                  <span className="text-xs text-error block">
                    Ristricted - Admin is currently suspended
                  </span>
                ) : null}
              </div>
            </div>
            <Menu
              as="div"
              className={`relative inline-block ${
                user?.id === admin?.id ||
                admin.role >= CuzooAdminRoles.SUPER_ADMIN
                  ? "hidden"
                  : "block"
              }`}
            >
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-backgroundTint bg-opacity-20 px-4 py-2 text-sm font-medium text-foreground/90 hover:text-foreground">
                  <span className="hidden md:inline-block">Actions</span>
                  <HiOutlineDotsVertical size={20} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-6 mt-2 w-32 origin-top-right divide-y divide-foreground/30 rounded-md bg-backgroundTint shadow-lg focus:outline-none">
                  <div className="py-1 ">
                    <Menu.Item
                      as="button"
                      role="button"
                      className={`text-xs p-2 w-full hover:bg-background ${
                        admin?.suspended ? "text-success" : "text-error"
                      }`}
                      onClick={() => {
                        if (admin?.suspended) {
                          setLoadingText("Releasing Admin");
                          releaseAdmin();
                        } else {
                          setLoadingText("Suspending Admin");
                          suspendAdmin();
                        }
                      }}
                    >
                      {admin?.suspended ? "Release Admin" : "Suspend Admin"}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </Header>
        {admin ? (
          <div className="p-2 grid gap-2">
            <div className="p-2 bg-background">
              <div className="text-center p-2">
                <div className="text-sm mx-auto font-semibold w-20 h-20 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
                  <p>
                    {admin.firstName[0].toUpperCase() +
                      admin.lastName[0].toUpperCase()}
                  </p>
                </div>

                <h2>
                  {admin.firstName} {admin.lastName}
                </h2>
                <a
                  href={`mailto:${admin.email}`}
                  className="block hover:text-tertiary text-xs my-1"
                >
                  {admin.email}
                </a>
                <a
                  href={`mailto:${admin.phoneNumber.number}`}
                  className="block hover:text-tertiary text-xs my-1"
                >
                  {admin.phoneNumber.nationalFormat}
                </a>
                {admin.country ? (
                  <div className="flex items-center gap-2 w-fit text-center">
                    <p>
                      {Country.getCountryByCode(admin.country)?.flag}{" "}
                      {Country.getCountryByCode(admin.country)?.name}
                    </p>

                    {admin.state ? (
                      <p>
                        {
                          State.getStateByCodeAndCountry(
                            admin.country,
                            admin.state
                          )?.name
                        }
                      </p>
                    ) : null}
                  </div>
                ) : null}
                <p className="text-xs opacity-50">
                  Joined -{" "}
                  {dayjs(admin.createdAt.toDate()).format("DD/MM/YYYY")}
                </p>
              </div>

              <div className="mt-2 mx-auto">
                <h2 className="p-2 font-bold">Admin Actions</h2>
                <div className="grid md:grid-cols-2 gap-2">
                  {adminActions.actions.map((action) => (
                    <Action action={action} key={action.id} />
                  ))}
                </div>
                {adminActions.loaded && !adminActions.actions.length ? (
                  <p className="text-center p-2 text-xs">
                    This user has no action yet.
                  </p>
                ) : null}
                {adminActions.loading ? <Spinner /> : null}
                {adminActions.loaded &&
                adminActions.more &&
                !adminActions.loading ? (
                  <Button
                    onClick={() => getActions(adminActions.lastVisible)}
                    text="Load More"
                    className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
            Not Found
          </div>
        )}
      </div>
      <ActionLoading isOpen={loading} text={loadingText} />
    </>
  );
}

export default AdminDetails;
