import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";
import { selectAuth } from "../../redux/slices/authSlice";

interface Props {
  children: JSX.Element;
}

function NoAuthGuard({ children }: Props) {
  const auth = useAppSelector(selectAuth);

  if (auth.user) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default NoAuthGuard;
