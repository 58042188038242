import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  IoMailSharp,
  IoCallSharp,
  IoLocationSharp,
  IoPersonOutline,
  IoMailOutline,
  IoCallOutline,
  IoLocationOutline,
  IoBusinessOutline,
  IoAttachOutline,
} from "react-icons/io5";
import { HiOutlineReceiptTax } from "react-icons/hi";
import Card from "../components/shared/Card";
import { useAppSelector } from "../hooks/useRedux";
import Button from "../components/shared/Button";
import Modal from "../components/shared/Modal";
import Text from "../components/shared/Text";
import TextArea from "../components/shared/TextArea";
import Header from "../components/shared/Header";
import { selectCompaniesRegistration } from "../redux/slices/companiesRegistrationSlice";
import useCompanyRegistration from "../hooks/useCompanyRegistration";

function CompanyRegistrationDetails() {
  const params = useParams();
  const { pendingRegistration } = useAppSelector(selectCompaniesRegistration);

  const company = useMemo(
    () => pendingRegistration.find((v) => v.id === params.id),
    [params.id, pendingRegistration]
  );

  const {
    declineFrom,
    showApproveRegistration,
    setShowApproveRegistration,
    showDeclineeRegistration,
    setShowDeclineeRegistration,
    approve,
    approving,
  } = useCompanyRegistration(company?.id!);

  return (
    <div className="h-full">
      <Header>
        <Text content="Riders Details" className="text-lg" />
      </Header>
      {company ? (
        <div className="p-2 grid grid-cols-1 gap-3">
          <Card className="p-4 w-full md:w-2/3 lg:1/2 mx-auto">
            <div className="flex justify-between">
              <div>
                <h2 className="text-lg font-bold">{company.businessName}</h2>
                <div className="grid grid-cols-3 mt-2 gap-2">
                  <a
                    href={`mailto:${company.email}`}
                    className="text-xs rounded-md flex items-center justify-center bg-background"
                  >
                    <IoMailSharp size={15} className="text-foreground/80" />
                  </a>

                  <a
                    href={`tel:${company.phoneNumber.number}`}
                    className="text-xs rounded-md p-2 flex items-center justify-center bg-background"
                  >
                    <IoCallSharp size={15} className="text-foreground/80" />
                  </a>

                  <a
                    target="blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${company.address.geometry.location.lat},${company.address.geometry.location.lng}&query_place_id=${company.address.place_id}`}
                    className="text-xs rounded-md p-2 flex items-center justify-center bg-background"
                  >
                    <IoLocationSharp size={15} className="text-foreground/80" />
                  </a>
                </div>
              </div>
              <p className="text-xs">
                {dayjs(company.createdAt.toDate()).format("DD/MM/YYYY")}
              </p>
            </div>
          </Card>
          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Personal Details
            </h2>
            <div className="text-sm divide-y divide-foreground/30">
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoPersonOutline size={15} />
                  <p className="ml-2">{company.firstName}</p>
                </div>

                <p className="text-xs opacity-60">First Name</p>
              </div>
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoPersonOutline size={15} />
                  <p className="ml-2">{company.lastName}</p>
                </div>

                <p className="text-xs opacity-60">Last Name</p>
              </div>
            </div>
          </Card>

          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Company Details
            </h2>
            <div className="text-sm divide-y divide-foreground/30">
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoBusinessOutline size={15} />
                  <p className="ml-2">{company.businessName}</p>
                </div>

                <p className="text-xs opacity-60">Business Name</p>
              </div>
              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoMailOutline size={15} />
                  <p className="ml-2">{company.email}</p>
                </div>

                <p className="text-xs opacity-60">Email</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <HiOutlineReceiptTax size={15} />
                  <p className="ml-2">{company.tinNumber}</p>
                </div>

                <p className="text-xs opacity-60">TIN Number</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoCallOutline size={15} />
                  <p className="ml-2">{company.phoneNumber.nationalFormat}</p>
                </div>

                <p className="text-xs opacity-60">Phone Number</p>
              </div>

              <div className="p-3 flex items-center justify-between">
                <div className="flex items-center">
                  <IoLocationOutline size={15} />
                  <p className="ml-2">{company.address.formatted_address}</p>
                </div>

                <p className="text-xs opacity-60">Address</p>
              </div>
            </div>
          </Card>

          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Documents
            </h2>
            <div className="grid gap-2 md:grid-cols-2 overflow-hidden mb-5">
              <a
                href={company.gaurantorForm.url}
                target="_blank"
                className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                rel="noreferrer"
              >
                <IoAttachOutline size={25} />

                <p className="pl-2 text-sm">Gaurantor Form</p>
              </a>

              <a
                href={company.kyc.url}
                target="_blank"
                className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                rel="noreferrer"
              >
                <IoAttachOutline size={25} />
                <p className="pl-2 text-sm">KYC Form</p>
              </a>

              <a
                href={company.partnershipForm.url}
                target="_blank"
                className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                rel="noreferrer"
              >
                <IoAttachOutline size={25} />
                <p className="pl-2 text-sm">Partnership Form</p>
              </a>
            </div>
            <div className="p-3 text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-2">
              <a
                href={company.cac.url}
                className="block rounded-md"
                target="blank"
              >
                <img
                  className="w-full h-36 object-cover rounded-sm"
                  src={company.cac.url}
                  alt="CAC  document"
                />
                <p className="font-medium text-center p-2 bg-background rounded-b-md">
                  CAC
                </p>
              </a>

              <a
                href={company.proofOfAddress.url}
                className="block rounded-md"
                target="blank"
              >
                <img
                  className="w-full h-36 object-cover rounded-sm"
                  src={company.proofOfAddress.url}
                  alt="Proof of Address"
                />
                <p className="font-medium text-center p-2 bg-background rounded-b-md">
                  Proof of Address
                </p>
              </a>
            </div>
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Directors
            </h2>
            <div className="p-3 text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-2">
              {company.directors.map((director) => (
                <a
                  href={director.url}
                  className="block rounded-md"
                  target="blank"
                >
                  <img
                    className="w-full h-36 object-cover rounded-sm"
                    src={director.url}
                    alt="Director"
                  />
                </a>
              ))}
            </div>
          </Card>

          <Card className="w-full md:w-2/3 lg:1/2 mx-auto">
            <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
              Actions
            </h2>
            <div className="flex items-center p-3">
              <Button
                text="Approve"
                className=" !text-foreground"
                onClick={() => setShowApproveRegistration(true)}
              />
              <Button
                text="Decline"
                className="ml-2 !bg-error !text-foreground"
                onClick={() => setShowDeclineeRegistration(true)}
              />
            </div>
          </Card>
        </div>
      ) : (
        <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
          Not Found
        </div>
      )}

      <Modal
        isOpen={showDeclineeRegistration}
        closeModal={setShowDeclineeRegistration}
        closeBtnDissabled={declineFrom.isSubmitting}
        title="Please state reaseon for this decline"
      >
        <form className="transition-all" onSubmit={declineFrom.handleSubmit}>
          <div className="mb-5">
            <TextArea
              name="reason"
              placeholder="Reason"
              onChange={declineFrom.handleChange}
              value={declineFrom.values.reason}
              className={`bg-backgroundShade ${
                declineFrom.errors.reason && declineFrom.touched.reason
                  ? "!border-error"
                  : ""
              }`}
            />
            {declineFrom.errors.reason && declineFrom.touched.reason ? (
              <Text
                content={declineFrom.errors.reason}
                className="text-xs mt-1 text-error font-thin"
              />
            ) : null}
          </div>

          <Button
            text={declineFrom.isSubmitting ? "Please wait..." : "Decline"}
            className="w-full !bg-error text-foreground disabled:opacity-50 "
            disabled={declineFrom.isSubmitting}
          />
        </form>
      </Modal>

      <Modal
        isOpen={showApproveRegistration}
        closeModal={setShowApproveRegistration}
        closeBtnDissabled={false}
        title={`Please be sure that you have properly vetted ${company?.businessName} before approving.`}
      >
        <Button
          text={approving ? "Please wait..." : "Approve"}
          onClick={approve}
          className="w-full text-foreground disabled:opacity-50 "
          disabled={approving}
        />
      </Modal>
    </div>
  );
}

export default CompanyRegistrationDetails;
