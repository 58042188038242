import React, { useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { logoutAction } from "../../redux/actions/auth.actions";
import { selectAuth } from "../../redux/slices/authSlice";
import { selectCompaniesRegistration } from "../../redux/slices/companiesRegistrationSlice";
import { selectRiderRegistration } from "../../redux/slices/riderRegistrationSlice";
import MobileNav from "../navbar/MobileNav";
import Nav from "../navbar/Nav";
import Button from "../shared/Button";

function Layout() {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);
  const { pendingRegistration } = useAppSelector(selectRiderRegistration);
  const { pendingRegistration: logisticsPartnerPendingRegistration } =
    useAppSelector(selectCompaniesRegistration);
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  // console.log(pendingRegistration);

  return (
    <div className="h-screen sm:flex">
      <Nav user={user} logout={logout} />
      <MobileNav user={user} logout={logout} />
      <main className="h-screen overflow-y-scroll flex-1">
        {pendingRegistration.length ? (
          <div className="p-3 bg-error text-foreground flex items-center justify-between">
            <h2 className="font-bold text-lg">Rider Registeration</h2>
            <Button
              text="View"
              className="!bg-primary-50 !text-background !text-xs !font-semibold"
              onClick={() => navigate("/riders-registration")}
            />
          </div>
        ) : null}
        {logisticsPartnerPendingRegistration.length ? (
          <div className="p-3 bg-error text-foreground flex items-center justify-between">
            <h2 className="text-lg">Partner Registeration</h2>
            <Button
              text="View"
              className="!bg-primary-50 !text-background !text-xs !font-semibold"
              onClick={() => navigate("/company-registration")}
            />
          </div>
        ) : null}
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
