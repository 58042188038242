import { DateValueType } from "react-tailwindcss-datepicker";
import { DocumentData, Query, Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import {
  collection,
  db,
  query,
  orderBy,
  where,
  startAfter,
  limit,
  getDocs,
} from "../config/fb";
import { AdminPositions, CollectionNames } from "../enums";
import { IActions, IAuth, IRider } from "../interfaces";

const ridersService = {
  fetchRiders: async (
    user: IAuth,
    lastVisible: any,
    range: DateValueType,
    country: string,
    state: string
  ): Promise<{ lv: any; data: IRider[]; more: boolean }> => {
    let q: Query<DocumentData> = query(
      collection(db, CollectionNames.RIDERS),
      where("approved", "==", true)
    );

    if (
      user.position === AdminPositions.INVESTOR &&
      user.country &&
      user.state
    ) {
      q = query(
        q,
        where("country", "==", user.country),
        where("state", "==", user.state)
      );
    }

    if (user.position !== AdminPositions.INVESTOR) {
      if (country) {
        q = query(q, where("country", "==", country));
      }

      if (state) {
        q = query(q, where("state", "==", state));
      }
    }

    if (range && range.startDate && range.endDate) {
      q = query(
        q,
        where(
          "createdAt",
          ">=",
          Timestamp.fromDate(dayjs(range.startDate).toDate())
        ),
        where(
          "createdAt",
          "<=",
          Timestamp.fromDate(dayjs(range.endDate).toDate())
        )
      );
    }

    q = query(q, orderBy("createdAt", "desc"));

    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    q = query(q, limit(10));

    const querySnapshots = await getDocs(q);

    const data: IRider[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((doc) => {
      const document: any = {
        id: doc.id,
        ...doc.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  searchRiders: async (
    user: IAuth,
    key: string,
    value: string
  ): Promise<{ data: IRider[] }> => {
    let q: Query<DocumentData> = query(
      collection(db, CollectionNames.RIDERS),
      where(key, "==", value)
    );

    if (
      user.position === AdminPositions.INVESTOR &&
      user.country &&
      user.state
    ) {
      q = query(
        q,
        where("country", "==", user.country),
        where("state", "==", user.state)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IRider[] = [];

    querySnapshots.forEach((doc) => {
      const document: any = {
        id: doc.id,
        ...doc.data(),
      };

      data.push(document);
    });

    return {
      data,
    };
  },

  fetchActions: async (
    lastVisible: any
  ): Promise<{ lv: any; data: IActions[]; more: boolean }> => {
    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.RIDERS_ACTIONS),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.RIDERS_ACTIONS),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: IActions[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((doc) => {
      const document: any = {
        id: doc.id,
        ...doc.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },
};

export default ridersService;
