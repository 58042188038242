import React, { useMemo, useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos, MdLogout } from "react-icons/md";
import Text from "../shared/Text";
import {
  adminMenu,
  superAdminRoutesAccess,
  userMenu,
} from "../../utils/_helper";
import NavLink from "../shared/NavLink";
import { AdminPositions, CuzooAdminRoles } from "../../enums";
import { IAuth } from "../../interfaces";
import CuzooLogo from "../shared/CuzooLogo";
import { useAppSelector } from "../../hooks/useRedux";
import { selectWithdrawalRequests } from "../../redux/slices/withdrawalRequestSlice";

interface Props {
  user: IAuth | null;
  logout: () => void;
}
function Nav({ user, logout }: Props) {
  const [open, setOpen] = useState(true);
  const withdrawalRequests = useAppSelector(selectWithdrawalRequests);

  const totalRequest = useMemo(
    () =>
      [...withdrawalRequests.companies, ...withdrawalRequests.riders].length,
    [withdrawalRequests.companies, withdrawalRequests.riders]
  );

  return (
    <nav className="h-screen hidden md:flex bg-backgroundTint rounded pt-5 border-r border-background">
      <div className="flex flex-col items-center justify-between ">
        <div>
          <CuzooLogo hideText={!open} />
        </div>
        <div className="py-5 px-3 flex-1 mt-2">
          {adminMenu
            .filter(({ url }) => {
              if (user?.position === AdminPositions.INVESTOR) {
                if (url === "/courier-company") {
                  return false;
                }
              }
              return true;
            })
            .map(({ Icon, text, url }) => {
              if (
                superAdminRoutesAccess.includes(url) &&
                user &&
                user?.role < CuzooAdminRoles.SUPER_ADMIN
              )
                return null;

              return (
                <NavLink
                  text={text}
                  Icon={Icon}
                  open={open}
                  key={text}
                  to={url}
                  badge={url === "/finance" ? totalRequest : null}
                />
              );
            })}
        </div>

        <div className="py-5 px-3 w-full">
          {userMenu.map(({ Icon, text, url }) => (
            <NavLink text={text} Icon={Icon} open={open} key={text} to={url} />
          ))}

          <div
            className="my-2 py-2 px-4 rounded-md cursor-pointer hover:bg-background"
            title="Logout"
            role="none"
            onClick={() => logout()}
          >
            <div className="flex items-center justify-between">
              <MdLogout size={20} />

              <Text
                content="Logout"
                className={`text-xs ml-2 transition-all flex-1 ${
                  open ? "block" : "hidden"
                }`}
              />
            </div>
          </div>
        </div>
        <div
          className={`w-full p-3 bg-backgroundTint flex items-center cursor-pointer ${
            open ? "justify-end" : "justify-center"
          } border-y border-background`}
          role="none"
          onClick={() => setOpen((prevState) => !prevState)}
        >
          {open ? (
            <MdArrowBackIos size={11} />
          ) : (
            <MdArrowForwardIos size={11} />
          )}
        </div>
      </div>
    </nav>
  );
}

export default Nav;
