import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Layout from "./components/layout/Layout";
import Loading from "./components/Loading";
import NoAuthGuard from "./components/shared/NoAuthGuard";
import NoMatch from "./components/shared/NoMatch";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import SuperAdminRouteGuard from "./components/shared/SuperAdminRouteGuard";
import useAuth from "./hooks/useAuth";
import AdminDetails from "./pages/AdminDetails";
import Admins from "./pages/Admins";
import CompanyRegistration from "./pages/CompanyRegistration";
import CompanyRegistrationDetails from "./pages/CompanyRegistrationDetails";
import CourierCompany from "./pages/CourierCompany";
import CourierCompanyDetails from "./pages/CourierCompanyDetails";
import Dashboard from "./pages/Dashboard";
import Financials from "./pages/Financials";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import OrderDetails from "./pages/OrderDetails";
import Orders from "./pages/Orders";
import RegistrationDetails from "./pages/RegistrationDetails";
import RiderDetails from "./pages/RiderDetails";
import Riders from "./pages/Riders";
import RidersRegistration from "./pages/RidersRegistration";
import Settings from "./pages/Settings";
import Suspended from "./pages/Suspended";
import UserDetails from "./pages/UserDetails";
import Users from "./pages/Users";

function App() {
  const { loaded } = useAuth();

  if (!loaded) {
    return <Loading />;
  }

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:id" element={<UserDetails />} />
          <Route path="/riders" element={<Riders />} />
          <Route path="/riders/:id" element={<RiderDetails />} />
          <Route path="/courier-company" element={<CourierCompany />} />
          <Route
            path="/courier-company/:id"
            element={<CourierCompanyDetails />}
          />
          <Route
            path="/riders-registration/:id"
            element={<RegistrationDetails />}
          />
          <Route path="/riders-registration" element={<RidersRegistration />} />
          <Route
            path="/company-registration/:id"
            element={<CompanyRegistrationDetails />}
          />
          <Route
            path="/company-registration"
            element={<CompanyRegistration />}
          />
          <Route
            path="/admins"
            element={
              <SuperAdminRouteGuard>
                <Admins />
              </SuperAdminRouteGuard>
            }
          />
          <Route
            path="/admins/:id"
            element={
              <SuperAdminRouteGuard>
                <AdminDetails />
              </SuperAdminRouteGuard>
            }
          />
          <Route
            path="/finance"
            element={
              <SuperAdminRouteGuard>
                <Financials />
              </SuperAdminRouteGuard>
            }
          />
          <Route path="/settings" element={<Settings />} />
        </Route>

        <Route
          path="/login"
          element={
            <NoAuthGuard>
              <Login />
            </NoAuthGuard>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <NoAuthGuard>
              <ForgotPassword />
            </NoAuthGuard>
          }
        />
        <Route path="/suspended" element={<Suspended />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <ToastContainer
        theme="dark"
        position="bottom-center"
        hideProgressBar
        closeOnClick={false}
        closeButton={false}
        className="font-body text-xs"
      />
    </div>
  );
}

export default App;
