/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICompany } from "../../interfaces";
import companyCourierService from "../../services/courierCompany.service";

export const getCompanyAction = createAsyncThunk<
  { data: ICompany[]; lv: any; more: boolean },
  { lastVisible: any }
>("courierCompany/getCompanyAction", async ({ lastVisible }) => {
  const response = await companyCourierService.fetchCompanies(lastVisible);
  return response;
});

export const searchCompanyAction = createAsyncThunk<
  { data: ICompany[] },
  { key: string; value: string }
>("courierCompany/searchCompanyAction", async ({ key, value }) => {
  const response = await companyCourierService.searchCompanies(key, value);
  return response;
});
