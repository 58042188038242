/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IAdmin } from "../../interfaces";
import adminService from "../../services/admin.service";

export const getAdminsAction = createAsyncThunk<
  { data: IAdmin[]; lv: any; more: boolean },
  { lastVisible: any }
>("admins/getAdminsAction", async ({ lastVisible }) => {
  const response = await adminService.fetchAdmins(lastVisible);
  return response;
});

export const searchAdminsAction = createAsyncThunk<
  { data: IAdmin[] },
  { key: string; value: string }
>("admins/searchAdminsAction", async ({ key, value }) => {
  const response = await adminService.searchAdmins(key, value);
  return response;
});

export const suspendAdminAction = createAsyncThunk<
  {
    message: string;
    id: string;
    update: { suspended: boolean };
  },
  string
>("admins/suspendAdminAction", async (id, { rejectWithValue }) => {
  try {
    const response = await adminService.suspendAdmin(id);
    return response;
  } catch (error: any) {
    const err: AxiosError<{ message: string }> = error;
    return rejectWithValue(
      err.response?.data || {
        message: "Opps something went wrong, please try again",
      }
    );
  }
});

export const releaseAdminAction = createAsyncThunk<
  {
    message: string;
    id: string;
    update: { suspended: boolean };
  },
  string
>("admins/releaseAdminAction", async (id, { rejectWithValue }) => {
  try {
    const response = await adminService.releaseAdmin(id);
    return response;
  } catch (error: any) {
    const err: AxiosError<{ message: string }> = error;
    return rejectWithValue(
      err.response?.data || {
        message: "Opps something went wrong, please try again",
      }
    );
  }
});
