import Card from "../components/shared/Card";
import CuzooLogo from "../components/shared/CuzooLogo";
import LoginForm from "../components/shared/LoginForm";
import Text from "../components/shared/Text";

function Login() {
  return (
    <div className="h-screen w-screen py-5 overflow-hidden px-3">
      <div className="flex items-center justify-center">
        <CuzooLogo />
      </div>
      <div className="flex mt-20 md:mt-0 md:items-center justify-center h-full">
        <Card className="w-full md:w-1/3 px-5 py-10">
          <header className="text-center">
            <Text
              content="Welcome Back"
              className="uppercase text-xs text-gray-400 italic"
            />
            <Text
              content="Login to your Admin Account"
              className="text-lg mg:text-xl"
            />
          </header>
          <LoginForm />
        </Card>
      </div>
    </div>
  );
}

export default Login;
