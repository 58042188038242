import React from "react";
import Logo from "../../assets/images/icon.png";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  hideText?: boolean;
};

function CuzooLogo({ hideText, ...rest }: Props) {
  return (
    <div className="flex items-center" {...rest}>
      <img
        src={Logo}
        alt="App Logo"
        className="rounded-md shadow-sm shadow-backgroundShade w-10 h-10"
      />
      {hideText ? null : <p className="ml-2 text-2xl font-extrabold">Cuzoo</p>}
    </div>
  );
}

export default CuzooLogo;

CuzooLogo.defaultProps = {
  hideText: false,
};
