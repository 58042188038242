import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";
import { selectAuth } from "../../redux/slices/authSlice";

interface Props {
  children: JSX.Element;
}

function ProtectedRoute({ children }: Props) {
  const { user } = useAppSelector(selectAuth);
  const location = useLocation();
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user.suspended) {
    return <Navigate to="/suspended" replace />;
  }

  return children;
}

export default ProtectedRoute;
