import { DocumentData, Query, Timestamp } from "firebase/firestore";
import { DateValueType } from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import {
  collection,
  db,
  query,
  orderBy,
  startAfter,
  where,
  limit,
  getDoc,
  doc,
  getDocs,
} from "../config/fb";
import { AdminPositions, CollectionNames } from "../enums";
import { IAuth, IOrder, IRating } from "../interfaces";

const ordersService = {
  fetchOrders: async (
    user: IAuth,
    lastVisible: any,
    range: DateValueType,
    country: string,
    state: string
  ): Promise<{ lv: any; data: IOrder[]; more: boolean }> => {
    let q: Query<DocumentData> = query(collection(db, CollectionNames.ORDERS));

    if (
      user.position === AdminPositions.INVESTOR &&
      user.country &&
      user.state
    ) {
      q = query(
        q,
        where("country", "==", user.country),
        where("state", "==", user.state)
      );
    }

    if (user.position !== AdminPositions.INVESTOR) {
      if (country) {
        q = query(q, where("country", "==", country));
      }

      if (state) {
        q = query(q, where("state", "==", state));
      }
    }

    if (range && range.startDate && range.endDate) {
      q = query(
        q,
        where(
          "createdAt",
          ">=",
          Timestamp.fromDate(dayjs(range.startDate).toDate())
        ),
        where(
          "createdAt",
          "<=",
          Timestamp.fromDate(dayjs(range.endDate).toDate())
        )
      );
    }

    q = query(q, orderBy("createdAt", "desc"));

    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    q = query(q, limit(10));

    const querySnapshots = await getDocs(q);

    const data: IOrder[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  searchOrders: async (
    key: string,
    value: string
  ): Promise<{ data: IOrder[] }> => {
    const q: Query<DocumentData> = query(
      collection(db, CollectionNames.ORDERS),
      where(key, "==", value)
    );

    const querySnapshots = await getDocs(q);

    const data: IOrder[] = [];

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
    };
  },

  getSingleOrder: async (
    orderId: string,
    isOrderId?: string | null
  ): Promise<IOrder | null> => {
    if (!orderId) {
      return null;
    }

    if (isOrderId) {
      const q = query(
        collection(db, CollectionNames.ORDERS),
        where("orderId", "==", orderId)
      );
      const orderDocs = await getDocs(q);
      if (orderDocs.empty) {
        return null;
      }

      return {
        id: orderDocs.docs[0].id,
        ...orderDocs.docs[0].data(),
      } as IOrder;
    }

    const snapshot = await getDoc(doc(db, CollectionNames.ORDERS, orderId));

    if (!snapshot.exists()) {
      return null;
    }

    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as IOrder;
  },

  getOrderRating: async (orderId: string): Promise<IRating | null> => {
    if (!orderId) {
      return null;
    }
    const q = query(
      collection(db, CollectionNames.RIDERS_RATINGS),
      where("orderId", "==", orderId)
    );

    const snapshots = await getDocs(q);

    if (snapshots.empty) {
      return null;
    }

    const data = snapshots.docs[0];

    return {
      id: data.id,
      ...data.data(),
    } as IRating;
  },
};

export default ordersService;
