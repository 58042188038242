import React, { PureComponent } from "react";
import Text from "./Text";
import notFound from "../../assets/svg/notFound.svg";
import CuzooLogo from "./CuzooLogo";

export class NoMatch extends PureComponent {
  render() {
    return (
      <div className="h-screen w-screen py-5 overflow-hidden px-3">
        <div className="flex items-center justify-center">
          <CuzooLogo />
        </div>
        <div className="flex flex-col md:mt-0 items-center justify-center h-full">
          <img src={notFound} alt="Cuzoo Logo" className="w-52 h-40  mb-5" />
          <Text content="Oops!!! Page Not Found." className="text-center" />
        </div>
      </div>
    );
  }
}

export default NoMatch;
