import React, { PureComponent } from "react";
import { IconType } from "react-icons";
import { Link, LinkProps } from "react-router-dom";
import Text from "./Text";

type Props = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    text: string;
    // eslint-disable-next-line react/require-default-props
    Icon?: IconType;
  };

class ButtonLink extends PureComponent<Props> {
  render() {
    const { text, className, Icon, ...rest } = this.props;
    return (
      <Link
        {...rest}
        className={`py-2 px-5 rounded-sm bg-primary text-sm text-white hover:shadow-md flex items-center ${
          Icon ? "justify-between" : "justify-center"
        } ${className}`}
      >
        {Icon ? <Icon size={20} /> : null}
        <Text content={text} />
      </Link>
    );
  }
}

export default ButtonLink;
