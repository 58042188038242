import { useMemo } from "react";
import dayjs from "dayjs";
import {
  MdAdminPanelSettings,
  MdDeliveryDining,
  // MdDeliveryDining,
  MdPeopleAlt,
  MdShoppingBag,
} from "react-icons/md";
// import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
// import { Timestamp } from "firebase/firestore";
import { useAppSelector } from "../../hooks/useRedux";
import SuperAdminGuard from "./SuperAdminGuard";
import Text from "./Text";
import { selectAuth } from "../../redux/slices/authSlice";
import { AdminPositions, CollectionNames, CuzooAdminRoles } from "../../enums";
import useCount from "../../hooks/useCount";
import CountCard from "./CountCard";

function TodaySummary() {
  const { user } = useAppSelector(selectAuth);
  // const [value, setValue] = useState<DateValueType>({
  //   startDate: dayjs().toDate(),
  //   endDate: dayjs().toDate(),
  // });

  const options = useMemo(() => {
    const opts: Parameters<typeof useCount>["0"]["options"] = [];
    if (user?.position === AdminPositions.INVESTOR) {
      opts.push(
        {
          key: "country",
          cmp: "==",
          value: user.country,
        },
        {
          key: "state",
          cmp: "==",
          value: user.state,
        }
      );
    }

    // if (value?.startDate && value.endDate) {
    //   opts.push(
    //     {
    //       key: "createdAt",
    //       cmp: ">=",
    //       value: Timestamp.fromDate(value.startDate as Date),
    //     },
    //     {
    //       key: "createdAt",
    //       cmp: "<=",
    //       value: Timestamp.fromDate(value.endDate as Date),
    //     }
    //   );
    // }

    return opts;
  }, [user]);

  const { isLoading: orderLoading, data: orderCount } = useCount({
    collectionName: CollectionNames.ORDERS,
    options,
    enabled: !!user,
    // enabled: !!(user && value?.startDate && value.endDate),
  });

  const { isLoading: userLoading, data: userCount } = useCount({
    collectionName: CollectionNames.USERS,
    options,
    enabled: !!user,
  });

  const { isLoading: riderLoading, data: riderCount } = useCount({
    collectionName: CollectionNames.RIDERS,
    options: [
      ...options,
      {
        cmp: "==",
        key: "approved",
        value: true,
      },
    ],
    enabled: !!user,
  });

  const { isLoading: unapprovedRiderLoading, data: unapprovedRiderCount } =
    useCount({
      collectionName: CollectionNames.RIDERS,
      options: [
        ...options,
        {
          cmp: "==",
          key: "approved",
          value: false,
        },
      ],
      enabled: !!user,
    });

  const { isLoading: adminLoading, data: adminCount } = useCount({
    collectionName: CollectionNames.ADMINS,
    options,
    enabled: !!(user && user.role === CuzooAdminRoles.SUPER_ADMIN),
  });

  return (
    <div className="mr-2 p-2">
      <header className="flex items-center justify-between gap-5">
        <div className="flex-1">
          <Text content="Today's Summary" className="text-sm font-medium" />
          <Text
            content={dayjs().format("ddd, MMM DD YYYY")}
            className="text-xs text-gray-400"
          />
        </div>
        {/* <div>
          <Datepicker
            value={value}
            onChange={(newValue) =>
              setValue({
                startDate: dayjs(newValue?.startDate).toDate(),
                endDate: dayjs(newValue?.endDate).toDate(),
              })
            }
          />
        </div> */}
      </header>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        <div>
          <CountCard
            label="Total Orders"
            Icon={MdShoppingBag}
            loading={orderLoading}
            count={orderCount || 0}
            iconBgColor="bg-cyan-100"
            iconColor="text-cyan-700"
            emptyMessage="No Orders Yet"
          />
        </div>

        <div>
          <CountCard
            label="Total Users"
            Icon={MdPeopleAlt}
            loading={userLoading}
            count={userCount || 0}
            iconBgColor="bg-secondary-50"
            iconColor="text-secondary"
            emptyMessage="No Users Yet"
          />
        </div>

        <div>
          <CountCard
            label="Total Approved Riders"
            Icon={MdDeliveryDining}
            loading={riderLoading}
            count={riderCount || 0}
            iconBgColor="bg-orange-100"
            iconColor="text-orange-700"
            emptyMessage="No Riders Yet"
          />
        </div>

        <div>
          <CountCard
            label="Total Un-Approved Riders"
            Icon={MdDeliveryDining}
            loading={unapprovedRiderLoading}
            count={unapprovedRiderCount || 0}
            iconBgColor="bg-orange-100"
            iconColor="text-orange-700"
            emptyMessage="No Riders Yet"
          />
        </div>

        <SuperAdminGuard>
          <CountCard
            label="Total Admins"
            Icon={MdAdminPanelSettings}
            loading={adminLoading}
            count={adminCount || 0}
            iconBgColor="bg-primary-50"
            iconColor="text-primary"
            emptyMessage="Unauthorized"
          />
        </SuperAdminGuard>
      </div>
    </div>
  );
}

export default TodaySummary;
