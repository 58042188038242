import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import React from "react";
import { IoWalletOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useAppSelector } from "../../hooks/useRedux";
import { selectSettings } from "../../redux/slices/settingsSlice";
import adminService from "../../services/admin.service";
import { handleError } from "../../utils/_helper";

function GeneralSettings() {
  const { general } = useAppSelector(selectSettings);
  const generalSettingsForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchableDistanceFromPickup: general?.searchableDistanceFromPickup || 7,
      suspendAllWalletServices: general?.suspendAllWalletServices || false,
    },
    validationSchema: yup.object({
      searchableDistanceFromPickup: yup
        .number()
        .integer()
        .positive("Must be a positive number")
        .min(1, "Minimum value is one")
        .required("Required."),
      suspendAllWalletServices: yup.boolean(),
    }),
    onSubmit: async (
      { searchableDistanceFromPickup, suspendAllWalletServices },
      { resetForm }
    ) => {
      try {
        const res = await adminService.updateSettings({
          searchableDistanceFromPickup: Number(searchableDistanceFromPickup),
          suspendAllWalletServices,
        });
        resetForm();
        toast.success(res.message);
      } catch (error) {
        toast.error(handleError(error));
      }
    },
  });

  return (
    <div className="mt-2">
      <h2 className="text-sm font-semibold mb-2">General</h2>
      <form
        className="rounded-sm bg-backgroundTint p-3 shadow-sm divide-y divide-foreground/20"
        onSubmit={generalSettingsForm.handleSubmit}
      >
        <div className="py-2">
          <p className="text-xs mb-2 opacity-80">
            Searchable Distance from Pick up location (km).
          </p>
          <div>
            <input
              type="text"
              name="searchableDistanceFromPickup"
              value={generalSettingsForm.values.searchableDistanceFromPickup}
              onChange={generalSettingsForm.handleChange}
              className={`w-full rounded-sm bg-backgroundShade text-xs text-foreground p-2 border-none focus:outline-none focus:ring-1 focus:ring-primary ${
                generalSettingsForm.touched.searchableDistanceFromPickup &&
                generalSettingsForm.errors.searchableDistanceFromPickup
                  ? "ring-1 ring-error"
                  : ""
              }`}
            />
            {generalSettingsForm.touched.searchableDistanceFromPickup &&
            generalSettingsForm.errors.searchableDistanceFromPickup ? (
              <p className="mt-2 text-xs text-error font-semibold">
                {generalSettingsForm.errors.searchableDistanceFromPickup}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex items-center justify-between py-2">
          <div className="flex items-center">
            <div className="p-2 h-fit flex items-center justify-center rounded-md bg-backgroundShade">
              <IoWalletOutline size={30} className="text-foreground" />
            </div>
            <div className="ml-3">
              <p className="text-xs opacity-80">Suspend all wallet services</p>
              <span className="text-xs opacity-60">
                User/Riders/Companies wallet activities
              </span>
            </div>
          </div>

          <div>
            <Switch
              checked={generalSettingsForm.values.suspendAllWalletServices}
              onChange={() =>
                generalSettingsForm.setFieldValue(
                  "suspendAllWalletServices",
                  !generalSettingsForm.values.suspendAllWalletServices
                )
              }
              className={`${
                generalSettingsForm.values.suspendAllWalletServices
                  ? "bg-error"
                  : "bg-backgroundShade"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Suspend all wallet Services</span>
              <span
                className={`${
                  generalSettingsForm.values.suspendAllWalletServices
                    ? "translate-x-6"
                    : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-foreground/80`}
              />
            </Switch>
          </div>
        </div>
        <button
          type="submit"
          disabled={generalSettingsForm.isSubmitting}
          className="rounded-sm bg-primary text-foreground text-xs py-2 px-6 font-semibold shadow-md mt-2 disabled:opacity-70"
        >
          {generalSettingsForm.isSubmitting ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
}

export default GeneralSettings;
