import React from "react";
import { Navigate } from "react-router-dom";
import { CuzooAdminRoles } from "../../enums";
import { useAppSelector } from "../../hooks/useRedux";
import { selectAuth } from "../../redux/slices/authSlice";

interface Props {
  children: JSX.Element;
}

function SuperAdminRouteGuard({ children }: Props) {
  const auth = useAppSelector(selectAuth);
  if (auth.user && auth.user.role < CuzooAdminRoles.SUPER_ADMIN) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default SuperAdminRouteGuard;
