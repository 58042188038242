import { DocumentData, Query } from "firebase/firestore";
import {
  onSnapshot,
  db,
  doc,
  query,
  collection,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getDoc,
  where,
} from "../config/fb";
import { CollectionNames } from "../enums";
import {
  ICompany,
  ICompanyAnalytics,
  ICompanyAreas,
  ITransaction,
  IWallet,
} from "../interfaces";

const companyCourierService = {
  fetchCompanies: async (
    lastVisible: any
  ): Promise<{ lv: any; data: ICompany[]; more: boolean }> => {
    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.COMPANIES),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.COMPANIES),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: ICompany[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((companyDoc) => {
      const document: any = {
        id: companyDoc.id,
        ...companyDoc.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  searchCompanies: async (
    key: string,
    value: string
  ): Promise<{ data: ICompany[] }> => {
    const q: Query<DocumentData> = query(
      collection(db, CollectionNames.COMPANIES),
      where(key, "==", value)
    );

    const querySnapshots = await getDocs(q);

    const data: ICompany[] = [];

    querySnapshots.forEach((companyDoc) => {
      const document: any = {
        id: companyDoc.id,
        ...companyDoc.data(),
      };

      data.push(document);
    });

    return {
      data,
    };
  },
  watchWallet: (
    companyId: string,
    updateWallet: (d: IWallet | null) => void
  ) => {
    if (!companyId) {
      updateWallet(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.COMPANY_WALLET, companyId),
      (walletDoc) => {
        const data = {
          id: walletDoc.id,
          ...walletDoc.data(),
        };
        updateWallet(data as IWallet);
      }
    );
  },

  watchCompanyAreas: (
    companyId: string,
    updateCompanyAreas: (d: ICompanyAreas[] | null) => void
  ) => {
    if (!companyId) {
      updateCompanyAreas(null);
    }
    return onSnapshot(
      query(
        collection(db, CollectionNames.COMPANY_AREAS),
        where("companyId", "==", companyId),
        where("paid", "==", false)
      ),
      (snapshots) => {
        if (snapshots.empty) {
          updateCompanyAreas(null);
        } else {
          const data: any = [];

          snapshots.forEach((areasDoc) =>
            data.push({
              id: areasDoc.id,
              ...areasDoc.data(),
            })
          );
          updateCompanyAreas(data as ICompanyAreas[]);
        }
      },
      (error) => console.log(error)
    );
  },

  getSingleCompany: async (companyId: string): Promise<ICompany | null> => {
    if (!companyId) {
      return null;
    }

    const snapshot = await getDoc(
      doc(db, CollectionNames.COMPANIES, companyId)
    );

    if (!snapshot.exists()) {
      return null;
    }

    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as ICompany;
  },

  fetchAnalytics: (
    companyId: string,
    updateAnalytics: (d: ICompanyAnalytics | null) => void
  ) => {
    if (!companyId) {
      updateAnalytics(null);
      return null;
    }
    return onSnapshot(
      doc(db, CollectionNames.INDIVIDUAL_COMPANY_ANALYTICS, companyId),
      (analyticsDoc) => {
        if (!analyticsDoc.exists()) {
          updateAnalytics(null);
        } else {
          const data = {
            id: analyticsDoc.id,
            ...analyticsDoc.data(),
          };
          updateAnalytics(data as ICompanyAnalytics);
        }
      }
    );
  },

  fetchTransactions: async (
    companyId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: ITransaction[]; more: boolean }> => {
    if (!companyId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.COMPANY_TRANSACTIONS),
        where("companyId", "==", companyId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.COMPANY_TRANSACTIONS),
        where("companyId", "==", companyId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: ITransaction[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },

  fetchRidersTransactions: async (
    companyId: string,
    lastVisible: any
  ): Promise<{ lv: any; data: ITransaction[]; more: boolean }> => {
    if (!companyId) {
      return {
        data: [],
        more: false,
        lv: null,
      };
    }

    let q: Query<DocumentData>;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.RIDERS_TRANSACTIONS),
        where("companyId", "==", companyId),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, CollectionNames.RIDERS_TRANSACTIONS),
        where("companyId", "==", companyId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
    }

    const querySnapshots = await getDocs(q);

    const data: ITransaction[] = [];
    const lv = querySnapshots.docs[querySnapshots.docs.length - 1] || null;
    const more = !!lv;

    querySnapshots.forEach((snapshot) => {
      const document: any = {
        id: snapshot.id,
        ...snapshot.data(),
      };

      data.push(document);
    });

    return {
      data,
      lv,
      more,
    };
  },
};

export default companyCourierService;
