import { CuzooAdminRoles } from "../../enums";
import { useAppSelector } from "../../hooks/useRedux";
import { selectAuth } from "../../redux/slices/authSlice";

interface Props {
  children: JSX.Element;
}

function SuperAdminGuard({ children }: Props) {
  const { user } = useAppSelector(selectAuth);

  if (!user || user.role < CuzooAdminRoles.SUPER_ADMIN) return null;

  return children;
}

export default SuperAdminGuard;
