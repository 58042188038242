/* eslint-disable no-underscore-dangle */
import dayjs from "dayjs";
import React from "react";
import { GoPackage } from "react-icons/go";
import { Link } from "react-router-dom";
import { IOrder } from "../../interfaces";

interface Props {
  order: IOrder;
}

function OrderItem({ order }: Props) {
  return (
    <div className="bg-backgroundShade rounded-sm p-2 grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 items-center justify-between">
      <div className="p-2 h-full w-fit flex items-center justify-center rounded-md bg-tertiary/20">
        <GoPackage size={30} className="text-tertiary" />
      </div>
      <p className="text-xs text-left hidden xl:block w-fit mx-auto">
        {dayjs(order.createdAt.toDate()).format("DD/MM/YY")}
      </p>
      <p className="text-xs w-fit mx-auto">{order.orderId}</p>
      <Link
        to={`/orders/${order.id}`}
        className="bg-primary text-xs w-fit py-2 px-4 rounded-sm hover:shadow-md mx-auto"
      >
        View
      </Link>
    </div>
  );
}

export default OrderItem;
