import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ICompanyAnalytics,
  ICompanyAreas,
  ITransaction,
  IWallet,
} from "../interfaces";
import adminService from "../services/admin.service";
import companyCourierService from "../services/courierCompany.service";
import { handleError } from "../utils/_helper";

const useCompanyDetails = (companyId: string) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [wallet, setWallet] = useState<IWallet | null>(null);

  const [companyAnalytics, setCompanyAnalytics] =
    useState<ICompanyAnalytics | null>(null);

  const [ridersTransactions, setRidersTransactions] = useState<{
    transactions: ITransaction[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    transactions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const [companyTransactions, setCompanyTransactions] = useState<{
    transactions: ITransaction[];
    lastVisible: any;
    more: boolean;
    loaded: boolean;
    loading: boolean;
  }>({
    transactions: [],
    lastVisible: null,
    more: false,
    loaded: false,
    loading: false,
  });

  const [companyAreas, setCompanyAreas] = useState<ICompanyAreas[] | null>(
    null
  );

  useEffect(() => {
    const sub = companyCourierService.watchCompanyAreas(
      companyId,
      setCompanyAreas
    );
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [companyId]);

  useEffect(() => {
    const sub = companyCourierService.watchWallet(companyId, setWallet);
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [companyId]);

  useEffect(() => {
    const sub = companyCourierService.fetchAnalytics(companyId, (data) =>
      setCompanyAnalytics(data)
    );
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [companyId]);

  const getRidersTransactions = useCallback(
    async (lv: any) => {
      setRidersTransactions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await companyCourierService.fetchRidersTransactions(
          companyId,
          lv
        );
        setRidersTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          transactions: [
            ...prevState.transactions.filter(
              (transaction) => !res.data.find((d) => d.id === transaction.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        setRidersTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (!ridersTransactions.loaded && !ridersTransactions.transactions.length) {
      getRidersTransactions(ridersTransactions.lastVisible);
    }
  }, [
    getRidersTransactions,
    companyId,
    ridersTransactions.transactions.length,
    ridersTransactions.lastVisible,
    ridersTransactions.loaded,
  ]);

  const getCompanyTransactions = useCallback(
    async (lv: any) => {
      setCompanyTransactions((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const res = await companyCourierService.fetchTransactions(
          companyId,
          lv
        );
        setCompanyTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          lastVisible: res.lv,
          more: res.more,
          transactions: [
            ...prevState.transactions.filter(
              (transaction) => !res.data.find((d) => d.id === transaction.id)
            ),
            ...res.data,
          ],
        }));
      } catch (error) {
        setCompanyTransactions((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
        }));
        toast.error(handleError(error));
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (
      !companyTransactions.loaded &&
      !companyTransactions.transactions.length
    ) {
      getCompanyTransactions(companyTransactions.lastVisible);
    }
  }, [
    getCompanyTransactions,
    companyId,
    companyTransactions.transactions.length,
    companyTransactions.lastVisible,
    companyTransactions.loaded,
  ]);

  const takeActionAgainstWallet = useCallback(
    async (action: "suspend" | "release") => {
      setLoading(true);
      setLoadingText(
        `Please wait - ${
          action === "release" ? "Releasing" : "Suspending"
        } wallet.`
      );
      try {
        const res = await adminService.actionAgainstCompanyWallet(
          companyId,
          action
        );
        setLoading(false);
        setLoadingText("");
        toast.success(res.message);
      } catch (error) {
        setLoading(false);
        setLoadingText("");
        toast.error(handleError(error));
      }
    },
    [companyId]
  );

  return {
    wallet,
    companyAnalytics,
    ridersTransactions,
    getRidersTransactions,
    companyTransactions,
    getCompanyTransactions,
    loading,
    setLoading,
    loadingText,
    takeActionAgainstWallet,
    companyAreas,
  };
};

export default useCompanyDetails;
