import React from "react";
import Button from "./Button";
import Input from "./Input";
import Text from "./Text";
import useForgotPassword from "../../hooks/useForgotPassword";
import ButtonLink from "./ButtonLink";

function ForgotPasswordForm() {
  const { formikForgotPassword } = useForgotPassword();
  return (
    <form
      className="mt-10 transition-all"
      onSubmit={formikForgotPassword.handleSubmit}
    >
      <div className="mb-5">
        <Text content="Email" className="text-sm mb-1" />
        <Input
          type="email"
          name="email"
          placeholder="Enter your Cuzoo Admin Email Address"
          onChange={formikForgotPassword.handleChange}
          value={formikForgotPassword.values.email}
          className={`${
            formikForgotPassword.errors.email &&
            formikForgotPassword.touched.email
              ? "!border-error"
              : ""
          }`}
        />
        {formikForgotPassword.errors.email &&
        formikForgotPassword.touched.email ? (
          <Text
            content={formikForgotPassword.errors.email}
            className="text-xs mt-1 text-error font-thin"
          />
        ) : null}
      </div>

      <Button
        text={formikForgotPassword.isSubmitting ? "Please wait..." : "Submit"}
        className="w-full disabled:opacity-50 mb-5"
        disabled={formikForgotPassword.isSubmitting}
      />

      <ButtonLink to="/login" text="Login" className="!bg-background" />
    </form>
  );
}

export default ForgotPasswordForm;
