import { WhereFilterOp } from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  query,
  db,
  collection,
  or,
  getCountFromServer,
  where,
} from "../config/fb";
import { CollectionNames } from "../enums";
import { handleError } from "../utils/_helper";

interface Props {
  collectionName: CollectionNames;
  options: {
    key: string;
    cmp: WhereFilterOp;
    value: any;
    ors?: {
      key: string;
      cmp: WhereFilterOp;
      value: any;
    }[];
  }[];
  enabled: boolean;
}

const useCount = (props: Props) => {
  const { data, isLoading, status, error } = useQuery({
    queryKey: [`${props.collectionName}-count`, JSON.stringify(props.options)],
    enabled: props.enabled,
    queryFn: async () => {
      try {
        let q = query(collection(db, props.collectionName));

        if (props.options.length) {
          props.options.forEach((option) => {
            if (option.ors?.length) {
              q = query(
                q,
                or(
                  where(option.key, option.cmp, option.value),
                  ...option.ors.map((op) => where(op.key, op.cmp, op.value))
                )
              );
            } else {
              q = query(q, where(option.key, option.cmp, option.value));
            }
          });
        }

        const countSnap = await getCountFromServer(q);
        return countSnap.data().count;
      } catch (err) {
        console.log(err);
        toast.error(handleError(err));
        return 0;
      }
    },
  });

  return {
    data,
    isLoading,
    status,
    error,
  };
};

export default useCount;
