import { createSlice } from "@reduxjs/toolkit";
import { IOrder } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import { getOrdersAction, searchOrdersAction } from "../actions/orders.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  orders: IOrder[];
  loaded: boolean;
  lastVisible: any;
  more: boolean;
  loading: boolean;
  searchLoading: boolean;
  searchLoaded: boolean;
  range: { startDate: number; endDate: number } | null;
  country: string;
  state: string;
} = {
  orders: [],
  loaded: false,
  lastVisible: null,
  more: true,
  loading: false,
  searchLoading: false,
  searchLoaded: false,
  range: null,
  country: "",
  state: "",
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    reset: (state) => {
      state.orders = [];
      state.loaded = false;
      state.lastVisible = null;
      state.more = true;
      state.loading = false;
      state.searchLoading = false;
      state.searchLoaded = false;
      state.range = null;
      state.country = "";
      state.state = "";
    },
    resetSearchOrders: (state) => {
      state.searchLoading = false;
      state.searchLoaded = false;
    },
    updateOrders: (state, action) => {
      state.orders = [...state.orders, action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction.fulfilled, (state) => {
        state.orders = [];
        state.loaded = false;
        state.loading = false;
        state.lastVisible = null;
        state.searchLoading = false;
        state.searchLoaded = false;
        state.range = null;
        state.country = "";
        state.state = "";
      })
      .addCase(getOrdersAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrdersAction.rejected, (state) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getOrdersAction.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.lastVisible = action.payload.lv;
        state.more = action.payload.more;
        state.range = action.payload.range;
        state.country = action.payload.country;
        state.state = action.payload.state;
        state.orders = [
          ...state.orders.filter(
            (order) => !action.payload.data.find((d) => d.id === order.id)
          ),
          ...action.payload.data,
        ];
      })
      .addCase(searchOrdersAction.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchOrdersAction.rejected, (state) => {
        state.searchLoading = false;
        state.searchLoaded = true;
      })
      .addCase(searchOrdersAction.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchLoaded = true;
        state.orders = [
          ...state.orders.filter(
            (order) => !action.payload.data.find((d) => d.id === order.id)
          ),
          ...action.payload.data,
        ];
      }),
});

export const { reset, resetSearchOrders, updateOrders } = ordersSlice.actions;
export const selectOrders = (state: RootState) => state.orders;

export default ordersSlice.reducer;
