import dayjs from "dayjs";
import { GoPackage } from "react-icons/go";
import { IoBusiness } from "react-icons/io5";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineAttachMoney,
  MdOutlineDashboard,
  MdOutlineDeliveryDining,
  MdPeopleOutline,
  MdOutlineSettings,
} from "react-icons/md";

import { timestamp } from "../config/fb";
import { ErrorNames } from "../enums";

export const adminMenu = [
  {
    Icon: MdOutlineDashboard,
    text: "Dashboard",
    url: "/",
  },
  {
    Icon: GoPackage,
    text: "Orders",
    url: "/orders",
  },
  {
    Icon: MdOutlineAttachMoney,
    text: "Financials",
    url: "/finance",
  },
  {
    Icon: MdPeopleOutline,
    text: "Users",
    url: "/users",
  },
  {
    Icon: MdOutlineDeliveryDining,
    text: "Riders",
    url: "/riders",
  },
  {
    Icon: IoBusiness,
    text: "Courier - Company",
    url: "/courier-company",
  },
  {
    Icon: MdOutlineAdminPanelSettings,
    text: "Admins",
    url: "/admins",
  },
];

export const userMenu = [
  {
    Icon: MdOutlineSettings,
    text: "Settings",
    url: "/settings",
  },
];

export const superAdminRoutesAccess = ["/admins", "/finance"];

export const formatDate = (obj: any) => {
  if (obj.createdAt) {
    if (typeof obj.createdAt !== "string" && obj.createdAt?.toDate()) {
      obj.createdAt = dayjs(obj.createdAt.toDate()).format(
        "DD MMM YYYY @ hh:mm:ssa"
      );
    } else {
      obj.createdAt = dayjs(obj.createdAt).format("DD MMM YYYY @ hh:mm:ssa");
    }
  }

  if (obj.lastSeen) {
    if (typeof obj.lastSeen !== "string" && obj.lastSeen?.toDate()) {
      obj.lastSeen = dayjs(obj.lastSeen.toDate()).format(
        "DD MMM YYYY @ hh:mm:ssa"
      );
    } else {
      obj.lastSeen = dayjs(obj.lastSeen).format("DD MMM YYYY @ hh:mm:ssa");
    }
  }

  if (obj.updatedAt) {
    if (typeof obj.updatedAt !== "string" && obj.updatedAt?.toDate()) {
      obj.updatedAt = dayjs(obj.updatedAt.toDate()).format(
        "DD MMM YYYY @ hh:mm:ssa"
      );
    } else {
      obj.updatedAt = dayjs(obj.updatedAt).format("DD MMM YYYY @ hh:mm:ssa");
    }
  }
};

export const createDefault = () => ({
  createdAt: timestamp(),
  updatedAt: timestamp(),
});

export const updateDefault = () => ({
  updatedAt: timestamp(),
});

export const handleError = (error: any) => {
  if (error?.name === ErrorNames.AXIOS_ERROR) {
    let message = null;
    if (error?.response?.data) {
      if (typeof error.response.data === "string") {
        message = error.response.data;
      } else {
        message = error.response.data.message;
      }
    }
    return message || error?.message;
  }

  if (error?.name === ErrorNames.FIREBASE_ERROR) {
    // handle firebase error
    switch (error.code) {
      // Auth Errors
      case "auth/email-already-exists":
        return "Email address already exist.";
      case "auth/insufficient-permission":
        return "Permission Denied";
      case "auth/invalid-email":
        return "Invalid email address";
      case "auth/invalid-password":
        return "Password must be at least 6 charaters";
      case "auth/user-not-found":
      case "auth/wrong-password":
        return "Email / password not correct.";
      case "auth/too-many-requests":
        return "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later";
      case "auth/network-request-failed":
        return "A network error has occurred, please try again.";

      // Storage Errors
      case "storage/unknown":
        return "An unknown error has occured";
      case "storage/object-not-found":
        return "Image not found";
      case "storage/unauthenticated":
      case "storage/unauthorized":
        return "Unauthorized";
      case "storage/retry-limit-exceeded":
        return "Timeout. Please try again.";

      // Firestore Errors

      default:
        return "Opps something went wrong please try again.";
    }
  }

  return error?.message || "Opps something went wrong please try again.";
};

export const moneyToKobo = (amount: number) => Math.round(amount * 100);
export const moneyToNaira = (amount: number) => amount / 100;

export const totalChargeIncludePaystackFee = (amount: number) => {
  amount = moneyToNaira(amount);
  const flatFee = 100;
  const decimalFee = 1.5 / 100;
  const capped = 2000;
  const applicableFee = decimalFee * amount;

  if (amount < 2500) {
    return moneyToKobo(amount / (1 - decimalFee) + 0.01);
  }
  if (applicableFee > capped) {
    return moneyToKobo(amount + capped);
  }
  return moneyToKobo((amount + flatFee) / (1 - decimalFee) + 0.01);
};

export const paystackChargeFee = (amount: number) => {
  amount = moneyToNaira(amount);
  const capped = 2000;
  const fee = (1.5 / 100) * amount;
  if (amount < 2500) {
    return moneyToKobo(fee);
  }
  if (fee > capped) {
    return moneyToKobo(capped);
  }
  return moneyToKobo(fee + 100);
};

export const paystackTransferCharge = (amount: number) => {
  amount = moneyToNaira(amount);
  if (amount <= 5000) {
    return moneyToKobo(10);
  }
  if (amount <= 50000) {
    return moneyToKobo(25);
  }
  return moneyToKobo(50);
};

export const getReason = (r: string) => {
  switch (r) {
    case "passport":
      return "Passport";
      break;
    case "address":
      return "Rider Address";
      break;
    case "guarantor":
      return "Gaurantor's Information";
      break;
    case "nationalId":
      return "Valid Identity";
      break;
    case "riderCard":
      return "Rider's Card";
      break;
    default:
      return "";
      break;
  }
};
