/* eslint-disable no-underscore-dangle */
import React from "react";
import { Link } from "react-router-dom";
import { IAdmin } from "../../interfaces";

interface Props {
  admin: IAdmin;
}

function AdminItem({ admin }: Props) {
  return (
    <div className="bg-background p-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center justify-between">
      <div className="flex items-center">
        <div className="text-xs font-semibold w-10 h-10 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
          <p>
            {admin.firstName[0].toUpperCase() + admin.lastName[0].toUpperCase()}
          </p>
        </div>

        <p className="text-xs ml-3">
          {admin.firstName} {admin.lastName}
        </p>
      </div>
      <a
        href={`mailto:${admin.email}`}
        className="text-xs hover:text-tertiary text-left hidden xl:block w-fit mx-auto"
      >
        {admin.email}
      </a>
      <a
        href={`tel:${admin.phoneNumber.number}`}
        className="text-xs hover:text-tertiary text-left hidden md:block w-fit mx-auto"
      >
        {admin.phoneNumber.nationalFormat}
      </a>
      <Link
        to={`/admins/${admin.id}`}
        className="bg-primary text-xs w-fit py-2 px-4 rounded-sm hover:shadow-md mx-auto"
      >
        View
      </Link>
    </div>
  );
}

export default AdminItem;
