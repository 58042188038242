import React, { PureComponent } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Chart, Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

export class BarChart extends PureComponent<{ data: any }> {
  render() {
    const { data } = this.props;

    return <Bar data={data} />;
  }
}

export default BarChart;
