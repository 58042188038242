import { useEffect, useMemo, useState } from "react";
import { GiRoad } from "react-icons/gi";
import {
  IoCardOutline,
  IoCashOutline,
  IoCheckmarkDoneOutline,
  IoWalletOutline,
} from "react-icons/io5";
import { MdOutlineCancelPresentation, MdOutlinePending } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IRating, IRiderLocation } from "../interfaces";
import { selectOrders, updateOrders } from "../redux/slices/ordersSlice";
import ordersService from "../services/orders.service";
import riderService from "../services/rider.service";
import { handleError } from "../utils/_helper";
import { useAppDispatch, useAppSelector } from "./useRedux";

const useOrderDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { orders } = useAppSelector(selectOrders);

  const order = useMemo(
    () => orders.find((v) => v.id === params.id || v.orderId === params.id),
    [params.id, orders]
  );

  const [riderLocation, setRiderLocation] = useState<IRiderLocation | null>(
    null
  );

  const [rating, setRating] = useState<IRating | null>(null);

  useEffect(() => {
    const getOrderRating = async () => {
      try {
        const res = await ordersService.getOrderRating(order?.id!);
        setRating(res);
      } catch (error) {
        toast.error(handleError(error));
      }
    };

    if (order?.id && order?.rated && !rating) {
      getOrderRating();
    }
  }, [order?.id, order?.rated, rating]);

  useEffect(() => {
    const getSingleOrder = async () => {
      try {
        const searchState = location.state as { orderId: string | null };
        const res = await ordersService.getSingleOrder(
          params.id!,
          searchState?.orderId
        );
        if (!res) {
          return navigate("/orders", { replace: true });
        }
        return dispatch(updateOrders(res));
      } catch (error) {
        return navigate("/orders", { replace: true });
      }
    };

    if (!order) {
      getSingleOrder();
    }
  }, [dispatch, location.state, navigate, order, params.id]);

  useEffect(() => {
    let sub: any;

    if (order?.riderDetails?.riderId && !order.resolved) {
      sub = riderService.watchRiderLocation(
        order.riderDetails.riderId,
        setRiderLocation
      );
    }

    return () => {
      if (sub) {
        sub();
      }
    };
  }, [order?.resolved, order?.riderDetails?.riderId]);

  const paymentType = useMemo(() => {
    if (order?.paymentMethod === "Card")
      return { Icon: IoCardOutline, text: order.paymentMethod };
    if (order?.paymentMethod === "Cash")
      return { Icon: IoCashOutline, text: order.paymentMethod };
    if (order?.paymentMethod === "Wallet")
      return { Icon: IoWalletOutline, text: order.paymentMethod };
    return null;
  }, [order?.paymentMethod]);

  const status = useMemo(() => {
    if (order?.resolved) {
      if (order.completed)
        return {
          Icon: IoCheckmarkDoneOutline,
          bg: "bg-emerald-500/20",
          iconColor: "text-emerald-500",
          text: "Completed",
          time: order?.deliveredAt,
        };

      if (order.cancelled)
        return {
          Icon: MdOutlineCancelPresentation,
          bg: "bg-rose-500/20",
          iconColor: "text-rose-500",
          text: "Cancelled",
          time: order?.cancelledAt,
        };
    }

    if (order?.pickedUp)
      return {
        Icon: GiRoad,
        bg: "bg-amber-500/20",
        iconColor: "text-amber-500",
        text: "Enroute",
        time: order?.pickedUpAt,
      };

    if (!order?.pickedUp && order?.riderDetails)
      return {
        Icon: GiRoad,
        bg: "bg-amber-500/20",
        iconColor: "text-amber-500",
        text: "Enroute to pick-up",
        time: order?.createdAt,
      };

    if (order?.pending)
      return {
        Icon: MdOutlinePending,
        bg: "bg-yellow-500/20",
        iconColor: "text-yellow-500",
        text: "Waiting for rider.",
        time: order?.pickedUpAt,
      };

    return null;
  }, [
    order?.cancelled,
    order?.cancelledAt,
    order?.completed,
    order?.createdAt,
    order?.deliveredAt,
    order?.pending,
    order?.pickedUp,
    order?.pickedUpAt,
    order?.resolved,
    order?.riderDetails,
  ]);

  return {
    order,
    riderLocation,
    navigate,
    paymentType,
    status,
    rating,
  };
};

export default useOrderDetails;
