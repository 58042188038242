/* eslint-disable no-underscore-dangle */
import React from "react";
import { Link } from "react-router-dom";
import { IUser } from "../../interfaces";

interface Props {
  user: IUser;
}

function UserItem({ user }: Props) {
  return (
    <div className="bg-background p-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 items-center justify-between">
      <div className="flex items-center">
        {user.profilePicture ? (
          <img
            src={user.profilePicture.url}
            alt="User Profile"
            className="w-10 h-10 object-cover rounded-full"
          />
        ) : (
          <div className="text-xs font-semibold w-10 h-10 rounded-full flex items-center justify-center bg-backgroundShade text-foreground/60">
            <p>
              {user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}
            </p>
          </div>
        )}

        <p className="text-xs ml-3">
          {user.firstName} {user.lastName}
        </p>
      </div>
      <a
        href={`mailto:${user.email}`}
        className="text-xs hover:text-tertiary text-left hidden xl:block w-fit mx-auto"
      >
        {user.email}
      </a>
      <a
        href={`tel:${user.phoneNumber.number}`}
        className="text-xs hover:text-tertiary text-left hidden md:block w-fit mx-auto"
      >
        {user.phoneNumber.nationalFormat}
      </a>
      <p className="text-xs hidden lg:block w-fit mx-auto">{user.gender}</p>
      <Link
        to={`/users/${user.id}`}
        className="bg-primary text-xs w-fit py-2 px-4 rounded-sm hover:shadow-md mx-auto"
      >
        View
      </Link>
    </div>
  );
}

export default UserItem;
