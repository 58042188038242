import React from "react";
import { GoPackage } from "react-icons/go";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { ICompanyAreas } from "../../interfaces";
import { moneyToNaira } from "../../utils/_helper";

interface Props {
  areas: ICompanyAreas;
}

function CompanyAreas({ areas }: Props) {
  return (
    <div className="flex items-center justify-between py-2" key={areas.id}>
      <div className="flex items-center">
        <div className="p-2 h-full hidden md:flex items-center justify-center rounded-md bg-backgroundShade">
          <GoPackage size={30} className="text-foreground" />
        </div>
        <div className="flex-1 ml-3 h-full">
          <p className="font-bold text-sm">
            <NumberFormat
              value={moneyToNaira(areas.amount)}
              displayType="text"
              decimalScale={2}
              thousandSeparator
              prefix="₦"
            />
          </p>
        </div>
      </div>

      <div className="flex items-center p-2">
        <Link
          to={`/orders/${areas.orderId}`}
          className="text-xs block bg-backgroundTint rounded-md p-1"
        >
          View Order
        </Link>
      </div>
    </div>
  );
}

export default CompanyAreas;
