import React, { Fragment, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IoArrowBackOutline,
  IoAttachOutline,
  IoBusinessOutline,
  IoCallOutline,
  IoCarSportOutline,
  IoCheckmarkDoneOutline,
  IoChevronForwardSharp,
  IoLocationOutline,
  IoMailOutline,
  IoPersonOutline,
  IoWalletOutline,
} from "react-icons/io5";
import NumberFormat from "react-number-format";
import Icon from "@mdi/react";
import { mdiVanUtility } from "@mdi/js";
import { BsBicycle } from "react-icons/bs";
import { GiRoad, GiFullMotorcycleHelmet, GiPayMoney } from "react-icons/gi";
import { GoPackage } from "react-icons/go";
import {
  MdOutlineCancelPresentation,
  MdOutlineDeliveryDining,
} from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { HiOutlineDotsVertical, HiOutlineReceiptTax } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import Header from "../components/shared/Header";
// import DetailInfoCards from "../components/shared/DetailInfoCards";
import Spinner from "../components/shared/Spinner";
import Button from "../components/shared/Button";
import Transaction from "../components/shared/Transaction";
import BarChart from "../components/shared/BarChart";
import ActionLoading from "../components/ActionLoading";
import {
  selectCourierCompanies,
  updateCourierCompanies,
} from "../redux/slices/courierCompanySlice";
import companyCourierService from "../services/courierCompany.service";
import useCompanyDetails from "../hooks/useCompanyDetails";
import Card from "../components/shared/Card";
import { moneyToNaira } from "../utils/_helper";
import { selectWithdrawalRequests } from "../redux/slices/withdrawalRequestSlice";
import { CollectionNames } from "../enums";
import WithdrawlRequest from "../components/shared/WithdrawlRequest";
import CompanyAreas from "../components/shared/CompanyAreas";

function CourierCompanyDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { courierCompanies } = useAppSelector(selectCourierCompanies);
  const { companies } = useAppSelector(selectWithdrawalRequests);

  const company = useMemo(
    () => courierCompanies.find((v) => v.id === params.id),
    [courierCompanies, params.id]
  );

  useEffect(() => {
    const getSingleCompany = async () => {
      try {
        const res = await companyCourierService.getSingleCompany(params.id!);
        if (!res) {
          return navigate("/courier-company", { replace: true });
        }
        return dispatch(updateCourierCompanies(res));
      } catch (error) {
        return navigate("/courier-company", { replace: true });
      }
    };

    if (!company) {
      getSingleCompany();
    }
  }, [dispatch, navigate, params.id, company]);

  const {
    wallet,
    companyAnalytics: analytics,
    ridersTransactions,
    getRidersTransactions,
    companyTransactions,
    getCompanyTransactions,
    loading,
    setLoading,
    loadingText,
    takeActionAgainstWallet,
    companyAreas,
  } = useCompanyDetails(params.id!);

  const companyWithdrawalRequest = useMemo(() => {
    if (company && companies.length) {
      const cwr = companies.filter((c) => c.companyId === company.id);
      if (cwr.length) return cwr;
      return null;
    }

    return null;
  }, [companies, company]);

  if (!company) {
    return (
      <div className="h-full grid place-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="h-full">
        <Header>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <button
                type="button"
                className="p-2"
                onClick={() => navigate("/courier-company", { replace: true })}
              >
                <IoArrowBackOutline size={20} />
              </button>
              <div className="ml-3">
                <p className="text-lg leading-none">{company.businessName}</p>
                <span className="text-xs opacity-60 font-bold">Profile</span>
                {wallet?.suspended ? (
                  <span className="text-xs text-error block">
                    Ristricted - Wallet is currently suspended
                  </span>
                ) : null}
              </div>
            </div>

            <Menu
              as="div"
              className={`relative inline-block ${
                !wallet ? "hidden" : "block"
              }`}
            >
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-backgroundTint bg-opacity-20 px-4 py-2 text-sm font-medium text-foreground/90 hover:text-foreground">
                  <span className="hidden md:inline-block">Actions</span>
                  <HiOutlineDotsVertical size={20} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-6 mt-2 w-32 origin-top-right divide-y divide-foreground/30 rounded-md bg-backgroundTint shadow-lg focus:outline-none">
                  <div className="py-1 ">
                    <Menu.Item
                      as="button"
                      role="button"
                      className={`text-xs p-2 w-full hover:bg-background ${
                        wallet?.suspended ? "text-success" : "text-error"
                      }`}
                      onClick={() =>
                        takeActionAgainstWallet(
                          wallet?.suspended ? "release" : "suspend"
                        )
                      }
                    >
                      {wallet?.suspended ? "Release Wallet" : "Suspend Wallet"}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </Header>
        {company ? (
          <div className="p-2 grid grid-cols-1 md:grid-cols-3 gap-3">
            <div className="md:order-3">
              {companyWithdrawalRequest?.length ? (
                <div className="w-full mt-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center justify-between p-2 w-full bg-backgroundTint rounded-md">
                          <div className="flex items-center">
                            <GiPayMoney size={20} className="text-foreground" />
                            <p className="ml-3 text-sm font-semibold">
                              Withdrawal Requests
                            </p>
                          </div>
                          <IoChevronForwardSharp
                            size={15}
                            className={`${
                              open ? "rotate-90" : ""
                            } h-5 w-5 text-foreground transition`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="divide-y divide-foreground/20 rounded-md border-b border-x border-foreground/20">
                          {companyWithdrawalRequest?.map((request) => (
                            <WithdrawlRequest
                              request={request}
                              key={request.id}
                              type={CollectionNames.COMPANIES}
                            />
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ) : null}

              {companyAreas?.length ? (
                <div className="w-full mt-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center justify-between p-2 w-full bg-backgroundTint rounded-md">
                          <div className="flex items-center">
                            <GiPayMoney size={20} className="text-foreground" />
                            <p className="ml-3 text-sm font-semibold">
                              Pending Payments
                            </p>
                          </div>
                          <IoChevronForwardSharp
                            size={15}
                            className={`${
                              open ? "rotate-90" : ""
                            } h-5 w-5 text-foreground transition`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="divide-y divide-foreground/20 rounded-md border-b border-x border-foreground/20">
                          {companyAreas?.map((areas) => (
                            <CompanyAreas areas={areas} key={areas.id} />
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ) : null}
            </div>
            <div className="md:col-span-2">
              <div>
                {/* wallet */}
                {wallet ? (
                  <div className="flex items-center mb-4 p-2">
                    <div className="p-2 h-full flex items-center justify-center rounded-md bg-sky-500/20">
                      <IoWalletOutline size={30} className="text-sky-500" />
                    </div>
                    <div className="flex-1 ml-3 h-full">
                      <p className="text-xs font-extralight opacity-60">
                        Wallet Balance
                      </p>
                      <h2
                        className={`text-lg font-bold ${
                          wallet?.suspended ? "text-error" : "text-foreground"
                        }`}
                      >
                        <NumberFormat
                          value={moneyToNaira(wallet?.amount || 0)}
                          displayType="text"
                          decimalScale={2}
                          thousandSeparator
                          prefix="₦"
                        />
                      </h2>
                    </div>
                  </div>
                ) : null}
                <div className="mb-4">
                  <h2 className="px-2 mb-2">Orders</h2>
                  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
                    {/* total Orders */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-tertiary/20">
                        <GoPackage size={30} className="text-tertiary" />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Total Orders
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.total || 0}
                        </h2>
                      </div>
                    </div>

                    {/* completed Orders */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-emerald-500/20">
                        <IoCheckmarkDoneOutline
                          size={30}
                          className="text-emerald-500"
                        />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Completed Orders
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.completed || 0}
                        </h2>
                      </div>
                    </div>

                    {/* failed Orders */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-rose-500/20">
                        <MdOutlineCancelPresentation
                          size={30}
                          className="text-rose-500"
                        />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Cancelled Orders
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.cancelled || 0}
                        </h2>
                      </div>
                    </div>

                    {/* pending Orders */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-amber-500/20">
                        <GiRoad size={30} className="text-amber-500" />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Pending Orders
                        </p>
                        <h2 className="text-lg font-bold">
                          {(analytics?.total || 0) -
                            ((analytics?.cancelled || 0) +
                              (analytics?.completed || 0))}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <h2 className="px-2 mb-2">Vehicles</h2>
                  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
                    {/* total vehicles */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-backgroundShade">
                        <GiFullMotorcycleHelmet
                          size={30}
                          className="text-foreground"
                        />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Total Vehicles/Riders
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.totalVehicles || 0}
                        </h2>
                      </div>
                    </div>

                    {/* Bicycle */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-backgroundShade">
                        <BsBicycle size={30} className="text-foreground" />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Bicycle
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.bicycle || 0}
                        </h2>
                      </div>
                    </div>

                    {/* Bike */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-backgroundShade">
                        <MdOutlineDeliveryDining
                          size={30}
                          className="text-foreground"
                        />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Bike
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.bike || 0}
                        </h2>
                      </div>
                    </div>

                    {/* Car */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-backgroundShade">
                        <IoCarSportOutline
                          size={30}
                          className="text-foreground"
                        />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Car
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.car || 0}
                        </h2>
                      </div>
                    </div>

                    {/* Van */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-backgroundShade">
                        <Icon path={mdiVanUtility} size={1.2} color="#e2e2e2" />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Van
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.van || 0}
                        </h2>
                      </div>
                    </div>
                    {/* Truck */}
                    <div className="flex items-center">
                      <div className="p-2 h-full flex items-center justify-center rounded-md bg-backgroundShade">
                        <TbTruckDelivery
                          size={30}
                          className="text-foreground"
                        />
                      </div>
                      <div className="flex-1 ml-3 h-full">
                        <p className="text-xs font-extralight opacity-60">
                          Truck
                        </p>
                        <h2 className="text-lg font-bold">
                          {analytics?.truck}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p2">
                  <BarChart
                    data={{
                      labels: ["Completed", "Pending", "Cancelled"],
                      datasets: [
                        {
                          label: "Orders",
                          data: [
                            analytics?.completed || 0,
                            analytics
                              ? analytics.total -
                                (analytics.cancelled + analytics.completed)
                              : 0,
                            analytics?.cancelled || 0,
                          ],
                          backgroundColor: [
                            "rgb(16 185 129 / 0.2)",
                            "rgb(245 158 11 / 0.2)",
                            "rgb(244 63 94 / 0.2)",
                          ],
                          borderColor: ["#10b981", "#f59e0b", "#f43f5e"],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
                <div className="p-2">
                  <h2 className="py-2 text-lg font-bold">
                    Riders Transactions
                  </h2>
                  {ridersTransactions.transactions.length ? (
                    <div className="divide-y divide-foreground/30">
                      {ridersTransactions.transactions.map((transaction) => (
                        <Transaction
                          transaction={transaction}
                          key={transaction.id}
                          order
                        />
                      ))}
                    </div>
                  ) : null}
                  {ridersTransactions.loaded &&
                  !ridersTransactions.transactions.length ? (
                    <p className="text-center p-2 text-xs">
                      This rider has no transactions yet.
                    </p>
                  ) : null}
                  {ridersTransactions.loading ? <Spinner /> : null}
                  {ridersTransactions.loaded &&
                  ridersTransactions.more &&
                  !ridersTransactions.loading ? (
                    <Button
                      onClick={() =>
                        getRidersTransactions(ridersTransactions.lastVisible)
                      }
                      text="Load More"
                      className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                    />
                  ) : null}
                </div>
                <div className="p-2">
                  <h2 className="py-2 text-lg font-bold">
                    Company Transactions
                  </h2>
                  {companyTransactions.transactions.length ? (
                    <div className="divide-y divide-foreground/30">
                      {companyTransactions.transactions.map((transaction) => (
                        <Transaction
                          transaction={transaction}
                          key={transaction.id}
                        />
                      ))}
                    </div>
                  ) : null}
                  {companyTransactions.loaded &&
                  !companyTransactions.transactions.length ? (
                    <p className="text-center p-2 text-xs">
                      This rider has no transactions yet.
                    </p>
                  ) : null}
                  {companyTransactions.loading ? <Spinner /> : null}
                  {companyTransactions.loaded &&
                  companyTransactions.more &&
                  !companyTransactions.loading ? (
                    <Button
                      onClick={() =>
                        getCompanyTransactions(companyTransactions.lastVisible)
                      }
                      text="Load More"
                      className="!bg-backgroundTint w-fit m-2 !text-xs opacity-80"
                    />
                  ) : null}
                </div>
              </div>
              <div className=" space-y-2 mb-5">
                <Card className="w-full">
                  <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
                    Personal Details
                  </h2>
                  <div className="text-sm divide-y divide-foreground/30">
                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <IoPersonOutline size={15} />
                        <p className="ml-2">{company.firstName}</p>
                      </div>

                      <p className="text-xs opacity-60">First Name</p>
                    </div>
                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <IoPersonOutline size={15} />
                        <p className="ml-2">{company.lastName}</p>
                      </div>

                      <p className="text-xs opacity-60">Last Name</p>
                    </div>
                  </div>
                </Card>

                <Card className="w-full">
                  <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
                    Company Details
                  </h2>
                  <div className="text-sm divide-y divide-foreground/30">
                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <IoBusinessOutline size={15} />
                        <p className="ml-2">{company.businessName}</p>
                      </div>

                      <p className="text-xs opacity-60">Business Name</p>
                    </div>
                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <IoMailOutline size={15} />
                        <p className="ml-2">{company.email}</p>
                      </div>

                      <p className="text-xs opacity-60">Email</p>
                    </div>

                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <HiOutlineReceiptTax size={15} />
                        <p className="ml-2">{company.tinNumber}</p>
                      </div>

                      <p className="text-xs opacity-60">TIN Number</p>
                    </div>

                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <IoCallOutline size={15} />
                        <p className="ml-2">
                          {company.phoneNumber.nationalFormat}
                        </p>
                      </div>

                      <p className="text-xs opacity-60">Phone Number</p>
                    </div>

                    <div className="p-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <IoLocationOutline size={15} />
                        <p className="ml-2">
                          {company.address.formatted_address}
                        </p>
                      </div>

                      <p className="text-xs opacity-60">Address</p>
                    </div>
                  </div>
                </Card>
              </div>
              <div>
                <div className="p-2">
                  <h2 className="py-2 text-lg font-bold">Documents</h2>
                  <div className="grid gap-2 md:grid-cols-2 overflow-hidden mb-5">
                    <a
                      href={company.gaurantorForm.url}
                      target="_blank"
                      className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                      rel="noreferrer"
                    >
                      <IoAttachOutline size={25} />

                      <p className="pl-2 text-sm">Gaurantor Form</p>
                    </a>

                    <a
                      href={company.kyc.url}
                      target="_blank"
                      className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                      rel="noreferrer"
                    >
                      <IoAttachOutline size={25} />
                      <p className="pl-2 text-sm">KYC Form</p>
                    </a>

                    <a
                      href={company.partnershipForm.url}
                      target="_blank"
                      className="bg-backgroundTint rounded-md shadow-sm flex items-center p-2"
                      rel="noreferrer"
                    >
                      <IoAttachOutline size={25} />
                      <p className="pl-2 text-sm">Partnership Form</p>
                    </a>
                  </div>
                  <div className="grid gap-2 md:grid-cols-2 overflow-hidden">
                    <a
                      href={company.cac.url}
                      target="_blank"
                      className="bg-backgroundTint block rounded-md shadow-sm"
                      rel="noreferrer"
                    >
                      <img
                        src={company.cac.url}
                        alt="CAC Document"
                        className="w-full h-44 object-cover"
                      />

                      <p className="p-2 text-xs">CAC</p>
                    </a>

                    <a
                      href={company.proofOfAddress.url}
                      className="block rounded-md"
                      target="blank"
                    >
                      <img
                        className="w-full h-36 object-cover rounded-sm"
                        src={company.proofOfAddress.url}
                        alt="Proof of Address"
                      />
                      <p className="font-medium text-center p-2 bg-background rounded-b-md">
                        Proof of Address
                      </p>
                    </a>
                  </div>
                  <h2 className="p-3 border-b border-foreground/30 text-sm font-bold">
                    Directors
                  </h2>
                  <div className="p-3 text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-2">
                    {company.directors.map((director) => (
                      <a
                        key={director.path}
                        href={director.url}
                        className="block rounded-md"
                        target="blank"
                      >
                        <img
                          className="w-full h-36 object-cover rounded-sm"
                          src={director.url}
                          alt="Director"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-5 h-full flex items-center justify-center font-sm font-semibold">
            Not Found
          </div>
        )}
      </div>
      <ActionLoading
        isOpen={loading}
        closeModal={setLoading}
        text={loadingText}
      />
    </>
  );
}

export default CourierCompanyDetails;
