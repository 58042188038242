import { createSlice } from "@reduxjs/toolkit";
import { ISettings } from "../../interfaces";
import { logoutAction } from "../actions/auth.actions";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: ISettings & { loaded: boolean } = {
  loaded: false,
  general: null,
  financials: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings: (state, action) => {
      state.loaded = true;
      state.general = action.payload?.general
        ? {
            ...action.payload.general,
          }
        : null;

      state.financials = action.payload?.financials
        ? {
            ...action.payload.financials,
          }
        : null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.general = null;
      state.loaded = false;
    });
  },
});

export const { updateSettings } = settingsSlice.actions;
export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
